

.login-container .login-mask {
  width: 90%;
  max-width: 405px;
  opacity: 0;
}

.login-container .login-outer {
  border-radius: 6px;
  padding: 50px 45px;
}

.login-container .login-mask {
  background-color: transparent;
}

.login-container .login-mask.page-loaded {
  -moz-animation-name: login-container-animation;
  -webkit-animation-name: login-container-animation;
  animation-name: login-container-animation;
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -moz-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -moz-animation-direction: normal;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -moz-animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -moz-animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  opacity: 1;
}