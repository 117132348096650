.main-content.ce-accordion .flex-col .col.xs-12:nth-of-type(1) {
  padding: 5px 20px 5px 20px;
  position: relative;
}

.ce-accordion.close .headline-container:before {
  content: "+";
  color: #1a2025;
  font-weight: 900;
  position: absolute;
  left: 5px;
  font-size: 2.5rem;
  line-height: 1.1;
}

.ce-accordion .headline-container:before {
  content: "-";
  color: white;
  font-weight: 900;
  position: absolute;
  left: 5px;
  font-size: 2.5rem;
  line-height: 1.1;
}

.main-content.ce-accordion.close .flex-col:nth-of-type(1) {
  background: rgb(128 128 128 / 12%) !important;
}

.color-0 .main-content.ce-accordion .flex-col:nth-of-type(1) {
  background: #ea9100;
  color: white;
}

.color-2 .main-content.ce-accordion .flex-col:nth-of-type(1) {
  background: #aebd23;
  color: white;
}

.main-content.ce-accordion.close .flex-col:nth-of-type(1) {
  h2, h3 {
    color: #1a2025 !important;
  }
}

.color-0 .main-content.ce-accordion .flex-col:nth-of-type(1){
  h2, h3 {
    color: white;
  }
}

.color-2 .main-content.ce-accordion .flex-col:nth-of-type(1) {
  h2, h3 {
    color: white;
  }
}

.section-container.accordion .text-container-wrapper {
  margin-top: 5px;
  font-size: 1.8rem;
}

.ce-accordion.close {
  h2, h3 {
    cursor: pointer;
    font-size: 2rem;
  }
}

.ce-accordion h3 {
  cursor: pointer;
  font-size: 2rem;
}

.ce-accordion h2 {
  cursor: pointer;
  font-size: 2rem;
}

.close .text-container-wrapper {
  display: none
}

.ce-accordion .headline-container {
}

.ce-accordion {
  .headline-container {
    padding-bottom: 0;
    .title-line {
      padding-top: 0;
      &::before {
        content: unset;
      }
    }
  }
}