
.stop-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.navigation-container {
    background: #ffffff;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    border-bottom: 1px solid #dde4ea;
}

.navigation-elements nav.main-navigation ul.level-0-container li.level-0 > a:not(.button) {
    color: #1a2025;
}

.navigation-elements nav.main-navigation ul.level-0-container li.level-0.active > a:not(.button),
.navigation-elements nav.main-navigation ul.level-0-container li.level-0.active-rootline > a:not(.button),
.navigation-elements nav.main-navigation ul.level-0-container li.level-0.active span,
.navigation-elements nav.main-navigation ul.level-0-container li.level-0.active-rootline span {
    color: #ea9100;
}

.navigation-elements nav.main-navigation ul.level-2-container li.level-2 > a {
    color: #1a2025;
}

.navigation-elements nav.main-navigation ul.level-2-container li.level-2.active > a,
.navigation-elements nav.main-navigation ul.level-2-container li.level-2.active-rootline > a {
    color: #ea9100;
}

.slider-element-container .slider-inline-element {
    display: none;
}

.slider-element-container .slider-text-wrapper {
    margin: 40px auto 0;
}

.slider-element-container .slider-text-wrapper h2,
.slider-element-container .slider-text-wrapper h3,
.slider-element-container .slider-text-wrapper h4,
.slider-element-container .slider-text-wrapper h5,
.slider-element-container .slider-text-wrapper h6 {
    margin-bottom: 25px;
}

.slider-element-container .slider-text-wrapper p {
    margin: 0;
    padding: 0;
}

.slider-element-container .slider-text-wrapper.active {
    display: block;
}

.slider-element-container .slider-quote-wrapper {
    margin-top: 40px;
}

.slider-element-container .slider-quote-wrapper .slider-quote-content blockquote,
.slider-element-container .slider-quote-wrapper .slider-quote-content p,
.slider-element-container .slider-quote-wrapper .slider-quote-content ul {
    font-style: normal;
    font-weight: 300;
    margin-bottom: 30px;
    max-width: 85%;
}

.slider-element-container .slider-quote-wrapper .slider-quote-content .quote-author {
    position: relative;
    padding-top: 20px;
    margin-bottom: 40px;
}

.slider-element-container .slider-quote-wrapper .slider-quote-content .quote-author::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 51px;
    height: 4px;
    background-color: #ea9100;
}

.slider-element-container .slider-quote-wrapper.active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.slider-element-container .slider-element-wrapper {
    position: relative;
}

.slider-element-container .slider-element-wrapper .tns-inner {
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slider-element-container .slider-element-wrapper .slider-elements-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    border-top: 2px solid rgba(161, 174, 183, 0.3);
    border-bottom: 2px solid rgba(161, 174, 183, 0.3);
    padding: 30px 0;
}

.slider-element-container .slider-element-wrapper .slider-element {
    text-align: center;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    opacity: 0.55;
}

.slider-element-container .slider-element-wrapper .slider-element.image-slider-element {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
}

.slider-element-container .slider-element-wrapper .slider-element.image-slider-element img {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    filter: grayscale(100%);
}

.slider-element-container .slider-element-wrapper .slider-element.text-slider-element > span {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    display: block;
    font-size: 3rem;
    line-height: 3.6rem;
    font-weight: 700;
}

.slider-element-container .slider-element-wrapper .slider-element::after {
    content: '';
    display: block;
    width: 0;
    opacity: 0;
    position: absolute;
    bottom: -32px;
    left: 0;
    height: 6px;
    background-color: #ea9100;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.slider-element-container .slider-element-wrapper .slider-element.active {
    opacity: 1;
}

.slider-element-container .slider-element-wrapper .slider-element.active::after {
    width: 100%;
    opacity: 1;
}

.slider-element-container .slider-element-wrapper .slider-element.active img {
    filter: none;
}

.slider-element-container .slider-element-wrapper ul li {
    position: absolute;
    z-index: 99;
    padding: 5px 15px;
    top: calc(50% - 8px);
    background: #ffffff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.slider-element-container .slider-element-wrapper ul li::before {
    content: '';
    display: block;
    width: 23px;
    min-width: 23px;
    height: 18px;
    background-size: 23px 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7'%3E %3Cpath fill='%23ea9100' fill-rule='nonzero' d='M1.48.52l-.96.96L6 6.96l5.48-5.48-.96-.96L6 5.04z'/%3E %3C/svg%3E ");
}

.slider-element-container .slider-element-wrapper ul li.slider-prev {
    left: -30px;
    -webkit-transform: translate(0, -50%) rotate(90deg);
    -moz-transform: translate(0, -50%) rotate(90deg);
    -o-transform: translate(0, -50%) rotate(90deg);
    -ms-transform: translate(0, -50%) rotate(90deg);
    transform: translate(0, -50%) rotate(90deg);
}

.slider-element-container .slider-element-wrapper ul li.slider-next {
    right: -30px;
    -webkit-transform: translate(0, -50%) rotate(-90deg);
    -moz-transform: translate(0, -50%) rotate(-90deg);
    -o-transform: translate(0, -50%) rotate(-90deg);
    -ms-transform: translate(0, -50%) rotate(-90deg);
    transform: translate(0, -50%) rotate(-90deg);
}

.slider-element-container .slider-element-wrapper ul li:focus {
    outline: none;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="textarea"],
input[type="password"] {
    border: 1px solid #dde4ea;
    padding: 10px 15px;
    width: 100%;
    background-color: #f6f8fb;
    font-weight: 600;
    color: #eb6a3e;
    font-family: Arial, Times, serif;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;
    color: #1a2025;
}

label {
    font-size: 1.6rem;
    margin: 5px 0 15px 0;
}

select {
    font-weight: 600;
    color: #eb6a3e;
    font-family: Arial, Times, serif;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;
    color: #1a2025;
    border: 1px solid #dde4ea;
    padding: 10px 15px;
    background-color: #f6f8fb;
    width: 100%;
}

textarea {
    font-weight: 600;
    color: #eb6a3e;
    font-family: Arial, Times, serif;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;
    color: #1a2025;
    border: 1px solid #dde4ea;
    border-radius: 0;
    margin: 0;
    max-width: 100%;
    min-height: 150px;
    padding: 10px 15px;
    width: 100%;
    resize: vertical;
    background-color: #f6f8fb;
    overflow: auto;
}

input[type='radio'] + label {
    position: relative;
    padding: 0 40px 0 0;
}

input[type='radio'] + label::before {
    content: '';
    position: relative;
    display: inline-block;
    margin-right: 10px;
    border-radius: 35px;
    top: 5px;
    width: 20px;
    height: 20px;
    background: #f6f8fb;
    border: solid 1px #dde4ea;
}

input[type='radio']:checked + label::after {
    content: '';
    position: absolute;
    border-radius: 35px;
    top: 11px;
    left: 6px;
    height: 10px;
    width: 10px;
    background-color: #ea9100;
}

input[type='checkbox'] + label {
    position: relative;
    padding: 0 40px 0 0;
}

input[type='checkbox'] + label::before {
    content: '';
    position: relative;
    display: inline-block;
    margin-right: 10px;
    top: 5px;
    width: 20px;
    height: 20px;
    background: #f6f8fb;
    border: solid 1px #dde4ea;
}

input[type='checkbox']:checked + label::after {
    content: '';
    position: absolute;
    top: 11px;
    left: 6px;
    height: 10px;
    width: 10px;
    background-color: #ea9100;
}

.formelement-checkbox + label, .formelement-radio + label {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
}
input.formelement-checkbox + label::before, input.formelement-radio + label::before {
    top: 0;
}
input.formelement-checkbox:checked + label::after, input.formelement-radio:checked + label::after {
    top: 6px;
}

.newsletter-container button.btn-primary[type="submit"],
input.submit-button[type="submit"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0 none;
    border-radius: 250px;
    height: 32px;
    width: 32px;
    padding: 0;
    background-color: #ea9100;
    background-size: 18px 18px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ffffff' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

.newsletter-container button.btn-primary[type="submit"][disabled],
input.submit-button[type="submit"][disabled] {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    cursor: not-allowed;
}

input.submit[type="submit"].color-0 {
    background-color: #ea9100;
    border-color: #ea9100;
}

input.submit[type="submit"].color-1 {
    background-color: #eb6a3e;
    border-color: #eb6a3e;
}

input.submit[type="submit"].color-2 {
    background-color: #aebd23;
    border-color: #aebd23;
}

input[type='radio'],
input[type='checkbox'] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

.form-row {
    margin-bottom: 10px;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.form-row .form-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.form-row .error-alert {
    color: #d53e27;
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 10px;
}

.form-row h4 {
    margin: 15px 0 -5px;
}

.form-row.product-interest .error-notice {
    display: none;
}

.form-data-text {
    margin: 5px 0 0 0;
    font-size: 1.4rem;
    line-height: 2.2rem;
}

.form-data-text .link.form-data-link {
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-decoration: underline;
    font-weight: 400;
    color: #1a2025;
}

.form-data-text .required-fields-information {
    display: block;
    padding-top: 10px;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

textarea,
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

select::-ms-expand {
    display: none;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='11px' height='8px' viewBox='0 0 11 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Product-Tiles/Comparison' transform='translate(-805.000000, -26.000000)' fill='%23008552'%3e%3cg id='CTA-Copy'%3e%3cg transform='translate(540.000000, 0.000000)'%3e%3cpolygon id='chevron_right---material' transform='translate(270.090000, 30.000000) rotate(90.000000) translate(-270.090000, -30.000000) ' points='268.18 25 273.18 30 268.18 35 267 33.82 270.84 30 267 26.18'%3e%3c/polygon%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e") !important;
    background-position: right 20px center;
    background-repeat: no-repeat;
}

input:focus,
select:focus,
textarea:focus,
input:focus-within,
select:focus-within,
textarea:focus-within {
    outline: 1px dashed rgba(26, 32, 37, 0.25);
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #bdbdbd;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #bdbdbd;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #bdbdbd;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #bdbdbd;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background: transparent !important;
}

.stage-element-container {
    margin-top: -25px;
}

.stage-element-container.layout-1.background-0,
.stage-element-container.layout-2.background-0,
.stage-element-container.layout-3.background-0 {
    background-color: #f6f8fb;
}

.stage-element-container.layout-1.background-1,
.stage-element-container.layout-2.background-1,
.stage-element-container.layout-3.background-1 {
    background-color: #ea9100;
}

.stage-element-container.layout-1.background-2,
.stage-element-container.layout-2.background-2,
.stage-element-container.layout-3.background-2 {
    background-color: #aebd23;
}

.stage-element-container.layout-1.background-3,
.stage-element-container.layout-2.background-3,
.stage-element-container.layout-3.background-3 {
    background-color: #eb6a3e;
}

.stage-element-container.layout-1.background-4,
.stage-element-container.layout-2.background-4,
.stage-element-container.layout-3.background-4 {
    background-color: #1a2025;
}

.stage-element-container.layout-1.background-5,
.stage-element-container.layout-2.background-5,
.stage-element-container.layout-3.background-5 {
    background-color: #f6f8fb;
}

.stage-element-container.layout-1.background-0 .stage-content-block {
    background-color: #f6f8fb;
}

.stage-element-container.layout-1.background-1 .stage-content-block {
    background-color: #ea9100;
}

.stage-element-container.layout-1.background-2 .stage-content-block {
    background-color: #aebd23;
}

.stage-element-container.layout-1.background-3 .stage-content-block {
    background-color: #eb6a3e;
}

.stage-element-container.layout-1.background-4 .stage-content-block {
    background-color: #1a2025;
}

.stage-element-container.layout-1.background-5 .stage-content-block {
    background-color: #f6f8fb;
}

.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block h1,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block h1,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block h1,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block h1,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block h1,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block h1,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block h2,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block h2,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block h2,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block h2,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block h2,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block h2,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block h3,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block h3,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block h3,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block h3,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block h3,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block h3,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block h4,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block h4,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block h4,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block h4,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block h4,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block h4,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block h5,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block h5,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block h5,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block h5,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block h5,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block h5,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block h6,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block h6,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block h6,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block h6,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block h6,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block h6,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block p,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block p,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block p,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block p,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block p,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block p,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block b,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block b,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block b,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block b,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block b,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block b,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block strong,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block strong,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block strong,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block strong,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block strong,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block strong,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block i,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block i,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block i,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block i,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block i,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block i,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block em,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block em,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block em,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block em,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block em,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block em,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block blockquote,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block blockquote,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block blockquote,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block blockquote,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block blockquote,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block blockquote,
.stage-element-container.layout-1.background-0 .stage-content-wrapper .stage-content-block span,
.stage-element-container.layout-2.background-0 .stage-content-wrapper .stage-content-block span,
.stage-element-container.layout-3.background-0 .stage-content-wrapper .stage-content-block span,
.stage-element-container.layout-1.background-5 .stage-content-wrapper .stage-content-block span,
.stage-element-container.layout-2.background-5 .stage-content-wrapper .stage-content-block span,
.stage-element-container.layout-3.background-5 .stage-content-wrapper .stage-content-block span {
    color: #1a2025;
}

.stage-element-container {
    position: relative;
    overflow: hidden;
}

.stage-element-container.layout-2 .stage-content-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    z-index: 4;
}

.stage-element-container.layout-3 .stage-content-wrapper {
    position: relative;
    z-index: 4;
}

.stage-element-container.layout-3 .stage-content-wrapper .stage-content-block {
    padding: 45px 20px 35px;
}

.stage-element-container.layout-1 .stage-image-block,
.stage-element-container.layout-2 .stage-image-block,
.stage-element-container.layout-3 .stage-image-block {
    height: 100%;
}

.stage-element-container.layout-1 .stage-content-block h1,
.stage-element-container.layout-2 .stage-content-block h1,
.stage-element-container.layout-3 .stage-content-block h1,
.stage-element-container.layout-1 .stage-content-block h2,
.stage-element-container.layout-2 .stage-content-block h2,
.stage-element-container.layout-3 .stage-content-block h2,
.stage-element-container.layout-1 .stage-content-block h3,
.stage-element-container.layout-2 .stage-content-block h3,
.stage-element-container.layout-3 .stage-content-block h3 {
    color: #ffffff;
    line-height: 1.33;
    margin: 0;
    width: 100%;
}

.stage-element-container.layout-1 .stage-content-block p,
.stage-element-container.layout-2 .stage-content-block p,
.stage-element-container.layout-3 .stage-content-block p {
    color: #ffffff;
    margin-top: 25px;
    padding: 0;
    width: 100%;
}

.stage-element-container.layout-1 .stage-content-block a,
.stage-element-container.layout-2 .stage-content-block a,
.stage-element-container.layout-3 .stage-content-block a {
    margin-top: 25px;
}

.stage-element-container.layout-1 .stage-content-block,
.stage-element-container.layout-2 .stage-content-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px;
}

.stage-element-container.layout-1::after,
.stage-element-container.layout-2::after {
    content: "";
    background-color: #ffffff;
    display: block;
    width: 100%;
    position: absolute;
    z-index: 3;
    -moz-transform: skew(0deg, 173deg);
    -ms-transform: skew(0deg, 173deg);
    -o-transform: skew(0deg, 173deg);
    -webkit-transform: skew(0deg, 173deg);
    transform: skew(0deg, 173deg);
    bottom: -162px;
}

.stage-element-container .main-content {
    height: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .stage-element-container.layout-1 .stage-image-block figure img {
        height: auto;
    }
}

.teaser h1,
.teaser h2,
.teaser h3 {
    padding-bottom: 30px;
}

.teaser.layout-1 {
    align-items: center;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.2);
}

.teaser.layout-1 a {
    margin-top: auto;
}

.teaser.layout-1 ul,
.teaser.layout-1 p,
.teaser.layout-1 blockquote {
    margin-bottom: 15px;
}

.teaser.layout-1 ul {
    list-style: none;
}

.teaser.layout-1 ul li {
    list-style: none;
    padding: 0 0 0 35px;
    margin: 0 0 20px;
}

.teaser.layout-1 ul li::before {
    content: '';
    display: inline-block;
    height: 13px;
    width: 10px;
    padding-left: 15px;
    margin: 5px 5px 0px -30px;
    background-size: 15px 12px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E %3Cpath fill='%23ea9100' d='M15.7259295,2.53319841 L6.67083181,15.4670441 C6.64686676,15.5014609 6.60321817,15.5092878 6.57170336,15.4873531 L6.55274928,15.468445 L4.24996333,12.3987972 C3.83569206,11.8465676 3.05218659,11.734729 2.49995486,12.1489986 C1.94772314,12.5632683 1.83588409,13.3467708 2.25015535,13.8990004 L4.55352934,16.9694314 C4.71922885,17.1899518 4.91815255,17.3823998 5.14370293,17.5393379 C6.3083395,18.3499395 7.90989924,18.0627522 8.7206841,16.8981059 L17.7738842,3.96697832 C18.1698131,3.40145341 18.0323266,2.62204233 17.4667996,2.22611494 C16.9012725,1.83018755 16.1218584,1.9676735 15.7259295,2.53319841 Z' transform='translate(-2 -2)'/%3E %3C/svg%3E ");
}

.teaser.layout-2 {
    position: relative;
    overflow: hidden;
    min-height: 420px;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px 40px;
    background-color: #ea9100;
    -webkit-box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.teaser.layout-2 h1,
.teaser.layout-2 h2,
.teaser.layout-2 h3 {
    color: #ffffff;
}

.teaser.layout-2 .button-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.teaser.layout-2 .button-row .link {
    z-index: 1;
    margin-bottom: 10px;
}

.teaser.layout-2.color-0 {
    background-color: #ffffff;
}

.teaser.layout-2.color-0 h1,
.teaser.layout-2.color-0 h2,
.teaser.layout-2.color-0 h3 {
    color: #1a2025;
}

.teaser.layout-2.color-1 {
    background-color: #ea9100;
}

.teaser.layout-2.color-2 {
    background-color: #a1aeb7!important;
}

.teaser.layout-2.color-3 {
    background-color: #757575;
}

.teaser.layout-2.color-4 {
    background-color: #eb6a3e;
}

.teaser.layout-2.color-5 {
    background-color: #aebd23;
}

.teaser.layout-2::before {
    content: "";
    position: absolute;
    bottom: -190px;
    left: -50px;
    transition: translate;
    -moz-transform: skew(61deg, 0) translate(0, 0);
    -ms-transform: skew(61deg, 0) translate(0, 0);
    -o-transform: skew(61deg, 0) translate(0, 0);
    -webkit-transform: skew(61deg, 0) translate(0, 0);
    transform: skew(61deg, 0) translate(0, 0);
    width: 460px;
    height: 450px;
    background: rgba(26, 32, 37, 0.15);
    opacity: 0.2;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.teaser.layout-2::after {
    content: "";
    position: absolute;
    bottom: -230px;
    right: -120px;
    transition: translate;
    -moz-transform: skew(-61deg, 0) translate(0, 0);
    -ms-transform: skew(-61deg, 0) translate(0, 0);
    -o-transform: skew(-61deg, 0) translate(0, 0);
    -webkit-transform: skew(-61deg, 0) translate(0, 0);
    transform: skew(-61deg, 0) translate(0, 0);
    width: 460px;
    height: 380px;
    background: rgba(26, 32, 37, 0.15);
    opacity: 0.2;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.teaser.layout-3 {
    position: relative;
    overflow: hidden;
    min-height: 420px;
    align-items: flex-start;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.teaser.layout-3 h1,
.teaser.layout-3 h2,
.teaser.layout-3 h3 {
    color: #ffffff;
    font-weight: 700;
}

.teaser.layout-3 p {
    color: #ffffff;
    font-weight: 500;
}

.teaser.layout-3.color-0 {
    background-color: #ffffff;
}

.teaser.layout-3.color-0 h1,
.teaser.layout-3.color-0 h2,
.teaser.layout-3.color-0 h3 {
    color: #1a2025;
}

.teaser.layout-3.color-0 p {
    color: #1a2025;
}

.teaser.layout-3.color-1 {
    background-color: #ea9100;
}

.teaser.layout-3.color-2 {
    background-color: #a1aeb7;
}

.teaser.layout-3.color-3 {
    background-color: #757575;
}

.teaser.layout-3.color-4 {
    background-color: #eb6a3e;
}

.teaser.layout-3.color-5 {
    background-color: #aebd23;
}

.teaser.layout-3 .teaser-image-block {
    height: 100%;
}

.teaser.layout-3 .teaser-image-block figure {
    width: 100%;
    height: 100%;
}

.teaser.layout-3 .teaser-image-block figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.teaser.layout-3 .teaser-content {
    padding: 30px 40px;
    height: 100%;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.teaser.layout-3 .teaser-content .button-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.teaser.layout-3 .teaser-content .button-row .link {
    z-index: 1;
    margin-bottom: 10px;
}

.teaser.layout-4 {
    align-items: flex-start;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.teaser.layout-4 a {
    margin-top: auto;
}

.teaser.layout-4 ul,
.teaser.layout-4 p,
.teaser.layout-4 blockquote {
    margin-bottom: 5px;
}

.teaser.layout-4 .teaser-image-block {
    margin-bottom: 30px;
}

.teaser.layout-1,
.teaser.layout-2,
.teaser.layout-3,
.teaser.layout-4 {
    margin: 0 0 25px;
}

.teaser.layout-5 {
    margin-bottom: 45px;
}

.teaser.layout-5 h2,
.teaser.layout-5 h3,
.teaser.layout-5 h4,
.teaser.layout-5 h5,
.teaser.layout-5 h6 {
    margin-bottom: 15px;
    padding: 0;
}

.teaser.layout-5 .image {
    margin-bottom: 25px;
}

.teaser.layout-5 .category {
    display: block;
    color: #ea9100;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.teaser.layout-5 p {
    padding: 0px 0 10px 0;
}

.teaser.layout-5 .extra-line {
    display: block;
    font-weight: 700;
    color: #1a2025;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 15px;
}

.tab-element-container .tab-head-container ul,
.tab-card-option-container .tab-head-container ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.tab-element-container .tab-head-container ul li > a,
.tab-card-option-container .tab-head-container ul li > a {
    position: relative;
    opacity: 0.6;
    text-decoration: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.tab-element-container .tab-head-container ul li > a img,
.tab-card-option-container .tab-head-container ul li > a img,
.tab-element-container .tab-head-container ul li > a svg,
.tab-card-option-container .tab-head-container ul li > a svg {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.tab-element-container .tab-head-container ul li > a span,
.tab-card-option-container .tab-head-container ul li > a span {
    font-weight: 700;
    color: #1a2025;
    text-decoration: none;
    font-size: 1.8rem;
    line-height: 2.4rem;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.tab-element-container .tab-detail-container,
.tab-card-option-container .tab-detail-container {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    display: none;
}
.tab-element-container .sub-tab-container {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    display: none;
}

.tab-detail-container.active.sub-tab-container {
    display: block;
}

.tab-element-container .tab-detail-container.active,
.tab-card-option-container .tab-detail-container.active {
    display: block;
}

.tab-element-container.faq-list .tab-detail-container a.link {
    line-height: 2.8rem;
}

.tab-card-option-container .tab-detail-container {
    border-top: 2px solid rgba(26, 32, 37, 0.1);
    background: #f6f8fb;
}

.tab-card-option-container .tab-detail-container .card-stream-container {
    width: 100%;
    position: relative;
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element] .card-element img,
.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element] .card-element svg {
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    opacity: 0;
    pointer-events: none;
    z-index: -1;
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element].active {
    opacity: 1;
    pointer-events: inherit;
    z-index: 2;
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element].active-animation {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    -moz-animation-name: card-element-active-animation;
    -webkit-animation-name: card-element-active-animation;
    animation-name: card-element-active-animation;
    -moz-animation-duration: 0.55s;
    -webkit-animation-duration: 0.55s;
    animation-duration: 0.55s;
    -moz-animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -moz-animation-direction: normal;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -moz-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-delay: 0.5s;
    z-index: 2;
    pointer-events: inherit;
}

@-webkit-keyframes card-element-active-animation {
    from {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        -webkit-transform: translate(calc(-50% + 60px), 0);
        -moz-transform: translate(calc(-50% + 60px), 0);
        -o-transform: translate(calc(-50% + 60px), 0);
        -ms-transform: translate(calc(-50% + 60px), 0);
        transform: translate(calc(-50% + 60px), 0);
    }
    to {
        opacity: 1;
        z-index: 2;
        pointer-events: inherit;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@-moz-keyframes card-element-active-animation {
    from {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        -webkit-transform: translate(calc(-50% + 60px), 0);
        -moz-transform: translate(calc(-50% + 60px), 0);
        -o-transform: translate(calc(-50% + 60px), 0);
        -ms-transform: translate(calc(-50% + 60px), 0);
        transform: translate(calc(-50% + 60px), 0);
    }
    to {
        opacity: 1;
        z-index: 2;
        pointer-events: inherit;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@-o-keyframes card-element-active-animation {
    from {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        -webkit-transform: translate(calc(-50% + 60px), 0);
        -moz-transform: translate(calc(-50% + 60px), 0);
        -o-transform: translate(calc(-50% + 60px), 0);
        -ms-transform: translate(calc(-50% + 60px), 0);
        transform: translate(calc(-50% + 60px), 0);
    }
    to {
        opacity: 1;
        z-index: 2;
        pointer-events: inherit;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

@keyframes card-element-active-animation {
    from {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        -webkit-transform: translate(calc(-50% + 60px), 0);
        -moz-transform: translate(calc(-50% + 60px), 0);
        -o-transform: translate(calc(-50% + 60px), 0);
        -ms-transform: translate(calc(-50% + 60px), 0);
        transform: translate(calc(-50% + 60px), 0);
    }
    to {
        opacity: 1;
        z-index: 2;
        pointer-events: inherit;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element].remove-animation {
    opacity: 1;
    pointer-events: inherit;
    z-index: 2;
    -moz-animation-name: card-element-remove-animation;
    -webkit-animation-name: card-element-remove-animation;
    animation-name: card-element-remove-animation;
    -moz-animation-duration: 0.55s;
    -webkit-animation-duration: 0.55s;
    animation-duration: 0.55s;
    -moz-animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -moz-animation-direction: normal;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -moz-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    z-index: -1;
    pointer-events: none;
}

@-webkit-keyframes card-element-remove-animation {
    from {
        opacity: 1;
        z-index: 2;
        pointer-events: inherit;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    to {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        -webkit-transform: translate(calc(-50% - 60px), 0);
        -moz-transform: translate(calc(-50% - 60px), 0);
        -o-transform: translate(calc(-50% - 60px), 0);
        -ms-transform: translate(calc(-50% - 60px), 0);
        transform: translate(calc(-50% - 60px), 0);
    }
}

@-moz-keyframes card-element-remove-animation {
    from {
        opacity: 1;
        z-index: 2;
        pointer-events: inherit;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    to {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        -webkit-transform: translate(calc(-50% - 60px), 0);
        -moz-transform: translate(calc(-50% - 60px), 0);
        -o-transform: translate(calc(-50% - 60px), 0);
        -ms-transform: translate(calc(-50% - 60px), 0);
        transform: translate(calc(-50% - 60px), 0);
    }
}

@-o-keyframes card-element-remove-animation {
    from {
        opacity: 1;
        z-index: 2;
        pointer-events: inherit;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    to {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        -webkit-transform: translate(calc(-50% - 60px), 0);
        -moz-transform: translate(calc(-50% - 60px), 0);
        -o-transform: translate(calc(-50% - 60px), 0);
        -ms-transform: translate(calc(-50% - 60px), 0);
        transform: translate(calc(-50% - 60px), 0);
    }
}

@keyframes card-element-remove-animation {
    from {
        opacity: 1;
        z-index: 2;
        pointer-events: inherit;
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    to {
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        -webkit-transform: translate(calc(-50% - 60px), 0);
        -moz-transform: translate(calc(-50% - 60px), 0);
        -o-transform: translate(calc(-50% - 60px), 0);
        -ms-transform: translate(calc(-50% - 60px), 0);
        transform: translate(calc(-50% - 60px), 0);
    }
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element] .slider-controls-container li {
    position: absolute;
    top: 50%;
    display: block;
    cursor: pointer;
    width: 17px;
    height: 17px;
    margin: 0;
    background-size: 17px 17px;
    -webkit-transform: translate(0, -50%) rotate(0);
    -moz-transform: translate(0, -50%) rotate(0);
    -o-transform: translate(0, -50%) rotate(0);
    -ms-transform: translate(0, -50%) rotate(0);
    transform: translate(0, -50%) rotate(0);
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element] .slider-controls-container li:focus {
    outline: none;
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element] .slider-controls-container li::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 17px;
    height: 17px;
    background-size: 17px 17px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%231a2025' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element] .slider-controls-container li.slider-prev {
    left: -50px;
    -webkit-transform: translate(0, -50%) rotate(-180deg);
    -moz-transform: translate(0, -50%) rotate(-180deg);
    -o-transform: translate(0, -50%) rotate(-180deg);
    -ms-transform: translate(0, -50%) rotate(-180deg);
    transform: translate(0, -50%) rotate(-180deg);
}

.tab-card-option-container .tab-detail-container .card-stream-container [data-card-element] .slider-controls-container li.slider-next {
    right: -50px;
}

.tab-card-option-container .tab-detail-container .card-stream-controls-container ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    border-bottom: 2px solid rgba(26, 32, 37, 0.1);
}

.tab-card-option-container .tab-detail-container .card-stream-controls-container ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a {
    position: relative;
    text-decoration: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a img,
.tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a svg {
    margin-right: 15px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a span {
    font-weight: 700;
    color: #1a2025;
    text-decoration: none;
    font-size: 1.8rem;
    line-height: 2.4rem;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a.active {
    opacity: 1;
}

.tab-card-option-container .tab-detail-container .card-stream-text-container ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.tab-card-option-container .tab-detail-container .card-stream-text-container ul li {
    width: 100%;
}

.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    -webkit-transform: translate(0, 80px);
    -moz-transform: translate(0, 80px);
    -o-transform: translate(0, 80px);
    -ms-transform: translate(0, 80px);
    transform: translate(0, 80px);
    -webkit-transition: 0.45s 0.25s ease;
    -moz-transition: 0.45s 0.25s ease;
    -o-transition: 0.45s 0.25s ease;
    transition: 0.45s 0.25s ease;
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element.active {
    opacity: 1;
    height: auto;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element h1,
.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element h2,
.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element h3,
.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element h4,
.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element h5,
.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element h6,
.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element [class^="headline-"] {
    margin-bottom: 25px;
    display: block;
}

.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element p {
    padding: 0;
    display: block;
}

.tab-card-option-container .tab-detail-container .card-stream-text-container ul li .card-stream-text-element a {
    margin-top: 30px;
    display: block;
}

.icon-text-container.layout-1 p {
    line-height: 3rem;
}

.icon-text-container.layout-1 h2,
.icon-text-container.layout-1 h3,
.icon-text-container.layout-1 h4,
.icon-text-container.layout-1 h5,
.icon-text-container.layout-1 h6 {
    margin-bottom: 20px;
    font-size: 2rem;
    line-height: 2.6rem;
}

.icon-text-container.layout-1 > ul {
    margin-bottom: 20px;
}

.icon-text-container.layout-1 > ul li a.link {
    color: #1a2025;
    text-decoration: none;
}

.icon-text-container.layout-2 {
    width: 100%;
    margin: 0 0 80px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.icon-text-container.layout-2 p {
    line-height: 3rem;
}

.icon-text-container.layout-2 .text-content h2,
.icon-text-container.layout-2 .text-content h3,
.icon-text-container.layout-2 .text-content h4,
.icon-text-container.layout-2 .text-content h5,
.icon-text-container.layout-2 .text-content h6 {
    font-size: 2rem;
    line-height: 2.6rem;
    margin-bottom: 30px;
}

.icon-text-container.layout-1 .title-line,
.icon-text-container.layout-2 .title-line {
    margin-bottom: 60px;
    font-size: 3.6rem;
    line-height: 4.2rem;
}

.icon-text-container.layout-1 .icon-container,
.icon-text-container.layout-2 .icon-container {
    margin-bottom: 20px;
}

.text-container.layout-1 .link-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.text-container.layout-1 .link-block a {
    margin: 0 20px 20px 0;
}

.text-container.layout-1 .link-block .link {
    margin-top: auto;
    font-weight: 700;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.text-container.layout-1.text-center .title-line {
    position: relative;
}

.text-container.layout-1.text-center .title-line::before {
    transform: translate(-50%, 0);
    left: 50%;
}

.text-container.layout-1 h2,
.text-container.layout-1 h3,
.text-container.layout-1 h4,
.text-container.layout-1 h5,
.text-container.layout-1 h6 {
    font-size: 3.6rem;
    margin-bottom: 30px;
}

.text-container.layout-1 ul {
    list-style: none;
}

.text-container.layout-1 ul li {
    list-style: none;
    padding: 0 0 0 5px;
    margin: 0 0 0 5px;
}

.text-container.layout-1 ul li::before {
    content: '';
    display: inline-block;
    height: 5px;
    width: 5px;
    margin: 0 0 0 -5px;
    border-radius: 35px;
    background-color: #eb6a3e;
    -webkit-transform: translate(-10px, -3px);
    -moz-transform: translate(-10px, -3px);
    -o-transform: translate(-10px, -3px);
    -ms-transform: translate(-10px, -3px);
    transform: translate(-10px, -3px);
}

.text-container.layout-1 ul li:last-child {
    margin: 0;
}

.text-image.layout-1.text-image-wrapper,
.text-image.layout-2.text-image-wrapper,
.text-image.layout-3.text-image-wrapper {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 15px 0;
}

.text-image.layout-1.text-image-wrapper .text-content,
.text-image.layout-2.text-image-wrapper .text-content,
.text-image.layout-3.text-image-wrapper .text-content {
    width: 100%;
}

.text-image.layout-1.text-image-wrapper .text-content ul,
.text-image.layout-2.text-image-wrapper .text-content ul,
.text-image.layout-3.text-image-wrapper .text-content ul {
    list-style: none;
}

.text-image.layout-1.text-image-wrapper .text-content ul li,
.text-image.layout-2.text-image-wrapper .text-content ul li,
.text-image.layout-3.text-image-wrapper .text-content ul li {
    list-style: none;
    padding: 0 0 0 15px;
    margin: 0 0 20px;
}

.text-image.layout-1.text-image-wrapper .text-content ul li::before,
.text-image.layout-2.text-image-wrapper .text-content ul li::before,
.text-image.layout-3.text-image-wrapper .text-content ul li::before {
    content: '';
    display: inline-block;
    height: 5px;
    width: 5px;
    margin: 0 0 0 -5px;
    border-radius: 35px;
    background-color: #ea9100;
    -webkit-transform: translate(-10px, -3px);
    -moz-transform: translate(-10px, -3px);
    -o-transform: translate(-10px, -3px);
    -ms-transform: translate(-10px, -3px);
    transform: translate(-10px, -3px);
}

.text-image.layout-1.text-image-wrapper .text-content ul li:last-child,
.text-image.layout-2.text-image-wrapper .text-content ul li:last-child,
.text-image.layout-3.text-image-wrapper .text-content ul li:last-child {
    margin: 0;
}

.text-image.layout-1.text-orientation-top,
.text-image.layout-2.text-orientation-top,
.text-image.layout-3.text-orientation-top {
    justify-content: flex-start;
}

.text-image.layout-1.text-orientation-center,
.text-image.layout-2.text-orientation-center,
.text-image.layout-3.text-orientation-center {
    justify-content: center;
}

.text-image.layout-1.text-orientation-bottom,
.text-image.layout-2.text-orientation-bottom,
.text-image.layout-3.text-orientation-bottom {
    justify-content: flex-end;
}

.text-image.layout-1.color-1 h2,
.text-image.layout-2.color-1 h2,
.text-image.layout-3.color-1 h2,
.text-image.layout-1.color-1 h3,
.text-image.layout-2.color-1 h3,
.text-image.layout-3.color-1 h3,
.text-image.layout-1.color-1 h4,
.text-image.layout-2.color-1 h4,
.text-image.layout-3.color-1 h4,
.text-image.layout-1.color-1 h5,
.text-image.layout-2.color-1 h5,
.text-image.layout-3.color-1 h5,
.text-image.layout-1.color-1 h6,
.text-image.layout-2.color-1 h6,
.text-image.layout-3.color-1 h6 {
    color: #eb6a3e;
}

.text-image.layout-1.color-1 ul li::before,
.text-image.layout-2.color-1 ul li::before,
.text-image.layout-3.color-1 ul li::before {
    background-color: #eb6a3e;
}

.text-image.layout-1.color-2 h2,
.text-image.layout-2.color-2 h2,
.text-image.layout-3.color-2 h2,
.text-image.layout-1.color-2 h3,
.text-image.layout-2.color-2 h3,
.text-image.layout-3.color-2 h3,
.text-image.layout-1.color-2 h4,
.text-image.layout-2.color-2 h4,
.text-image.layout-3.color-2 h4,
.text-image.layout-1.color-2 h5,
.text-image.layout-2.color-2 h5,
.text-image.layout-3.color-2 h5,
.text-image.layout-1.color-2 h6,
.text-image.layout-2.color-2 h6,
.text-image.layout-3.color-2 h6 {
    color: #ea9100;
}

.text-image.layout-1.color-2 ul li::before,
.text-image.layout-2.color-2 ul li::before,
.text-image.layout-3.color-2 ul li::before {
    background-color: #ea9100;
}

.text-image.layout-1.color-3 h2,
.text-image.layout-2.color-3 h2,
.text-image.layout-3.color-3 h2,
.text-image.layout-1.color-3 h3,
.text-image.layout-2.color-3 h3,
.text-image.layout-3.color-3 h3,
.text-image.layout-1.color-3 h4,
.text-image.layout-2.color-3 h4,
.text-image.layout-3.color-3 h4,
.text-image.layout-1.color-3 h5,
.text-image.layout-2.color-3 h5,
.text-image.layout-3.color-3 h5,
.text-image.layout-1.color-3 h6,
.text-image.layout-2.color-3 h6,
.text-image.layout-3.color-3 h6 {
    color: #aebd23;
}

.text-image.layout-1.color-3 h2 ul li::before,
.text-image.layout-2.color-3 h2 ul li::before,
.text-image.layout-3.color-3 h2 ul li::before,
.text-image.layout-1.color-3 h3 ul li::before,
.text-image.layout-2.color-3 h3 ul li::before,
.text-image.layout-3.color-3 h3 ul li::before,
.text-image.layout-1.color-3 h4 ul li::before,
.text-image.layout-2.color-3 h4 ul li::before,
.text-image.layout-3.color-3 h4 ul li::before,
.text-image.layout-1.color-3 h5 ul li::before,
.text-image.layout-2.color-3 h5 ul li::before,
.text-image.layout-3.color-3 h5 ul li::before,
.text-image.layout-1.color-3 h6 ul li::before,
.text-image.layout-2.color-3 h6 ul li::before,
.text-image.layout-3.color-3 h6 ul li::before {
    background-color: #aebd23;
}

.text-image.layout-1.text-image-wrapper.color-1 .text-content ul li::before,
.text-image.layout-2.text-image-wrapper.color-1 .text-content ul li::before,
.text-image.layout-3.text-image-wrapper.color-1 .text-content ul li::before {
    background-color: #eb6a3e;
}

.text-image.layout-1.text-image-wrapper.color-2 .text-content ul li::before,
.text-image.layout-2.text-image-wrapper.color-2 .text-content ul li::before,
.text-image.layout-3.text-image-wrapper.color-2 .text-content ul li::before {
    background-color: #ea9100;
}

.text-image.layout-1.text-image-wrapper.color-3 .text-content ul li::before,
.text-image.layout-2.text-image-wrapper.color-3 .text-content ul li::before,
.text-image.layout-3.text-image-wrapper.color-3 .text-content ul li::before {
    background-color: #aebd23;
}

.text-image.layout-1 .category,
.text-image.layout-2 .category,
.text-image.layout-3 .category {
    display: block;
    color: #ea9100;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.text-image.layout-1 .extra-line,
.text-image.layout-2 .extra-line,
.text-image.layout-3 .extra-line {
    display: block;
    font-weight: 700;
    color: #1a2025;
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin-bottom: 15px;
}

.text-image.layout-3 h2,
.text-image.layout-3 h3,
.text-image.layout-3 h4,
.text-image.layout-3 h5,
.text-image.layout-3 h6 {
    margin-bottom: 15px;
}

.text-image .text-content .button-row {
    margin-top: 25px;
}

.section-anchor-navigation h1,
.section-anchor-navigation h2,
.section-anchor-navigation h3,
.section-anchor-navigation h4,
.section-anchor-navigation h5,
.section-anchor-navigation h6 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.6rem;
    margin-bottom: 20px;
}

.section-anchor-navigation ul li {
    margin-bottom: 20px;
}

.section-anchor-navigation ul li:last-child {
    margin: 0;
}

.section-anchor-navigation .anchor-label {
    display: block;
    margin: 25px 0 20px;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #ea9100;
    text-transform: uppercase;
    font-weight: 700;
}

.section-anchor-navigation > a {
    margin-top: 20px;
    display: block;
}

.faq-detail .section-anchor-navigation a.link.active {
    font-weight: 600;
}

.section-content-element .content-container h1,
.section-content-element .content-container h2,
.section-content-element .content-container h3,
.section-content-element .content-container h4,
.section-content-element .content-container h5,
.section-content-element .content-container h6 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 700;
    margin-bottom: 24px;
}

.tab-element-container .tab-head-container ul {
    border-bottom: 2px solid rgba(26, 32, 37, 0.1);
}

.tab-element-container .sub-tab-container .tab-head-container ul {
    border-bottom: none;
}

.tab-element-container .tab-head-container ul li > a {
    opacity: 1;
}
@import "login";

[data-scrollmagic-pin-spacer] {
    box-sizing: border-box !important;
}

section.color-2 .content-scroll-container .text-container-wrapper .text-container-element .num {
    background: #aebd23;
}

section.color-2 .content-scroll-container .text-container-wrapper .text-container-element p > a {
    color: #aebd23;
}

section.color-1 .content-scroll-container .text-container-wrapper .text-container-element .num {
    background: #eb6a3e;
}

section.color-1 .content-scroll-container .text-container-wrapper .text-container-element p > a {
    color: #eb6a3e;
}

.content-scroll-container .text-container-wrapper .text-container-element {
    position: relative;
    padding: 65px 0 65px 65px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.content-scroll-container .text-container-wrapper .text-container-element h2,
.content-scroll-container .text-container-wrapper .text-container-element h3,
.content-scroll-container .text-container-wrapper .text-container-element h4,
.content-scroll-container .text-container-wrapper .text-container-element h5,
.content-scroll-container .text-container-wrapper .text-container-element h6 {
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: 700;
}

.content-scroll-container .text-container-wrapper .text-container-element p {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    font-weight: 400;
    padding: 0;
}

.content-scroll-container .text-container-wrapper .text-container-element p > a {
    color: #ea9100;
}

.content-scroll-container .text-container-wrapper .text-container-element .num {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #ea9100;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 5px 5px 20px -8px rgba(26, 32, 37, 0.48);
    -moz-box-shadow: 5px 5px 20px -8px rgba(26, 32, 37, 0.48);
    box-shadow: 5px 5px 20px -8px rgba(26, 32, 37, 0.48);
}

.content-scroll-container .text-container-wrapper .text-container-element .num::before {
    content: attr(data-num);
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 700;
    color: #ffffff;
    position: absolute;
    display: block;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.content-scroll-container .text-container-wrapper .text-container-element::before {
    content: '';
    display: block;
    position: absolute;
    left: 25px;
    top: 75px;
    width: 2px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.browser-popup-helper {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.browser-popup-helper main {
    opacity: 0;
}

.browser-popup-container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    width: 100%;
    height: 100vh;
    padding: 25px;
    background-color: #1a2025;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.browser-popup-container .inner-container {
    width: 100%;
    max-width: 450px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.browser-popup-container .text-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.browser-popup-container .text-block > div {
    margin-bottom: 15px;
    font-size: 2.2rem;
    line-height: 2.6rem;
    color: #eb6a3e;
    font-weight: 700;
    width: 100%;
}

.browser-popup-container .text-block > p {
    width: 100%;
    padding: 0;
    margin: 0;
    color: #ffffff;
}

.browser-popup-container .browser-list-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.browser-popup-container .browser-list-wrapper p {
    font-weight: 700;
    padding: 0;
    margin: 0;
    color: #ffffff;
}

.browser-popup-container .browser-list-wrapper .browser-list {
    margin-left: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.browser-popup-container .browser-list-wrapper .browser-list .browser-item {
    width: 42px;
    margin-right: 10px;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.browser-popup-container .browser-list-wrapper .browser-list .browser-item img {
    max-width: 100%;
}

.browser-popup-container .browser-list-wrapper .browser-list .browser-item:hover {
    -webkit-transform: translate(0, -2px);
    -moz-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    transform: translate(0, -2px);
}

.browser-popup-container span.link.type-5.color-1,
.browser-popup-container span.link,
.browser-popup-container .link,
.browser-popup-container span {
    margin-top: 35px;
    font-size: 1.6rem;
    color: #ffffff;
    font-weight: 700;
}

:root {
    --base__color-1: #1a2025;
    --base__color-2: #ffffff;
    --base__color-3: #eb6a3e;
    --base-icon-color: #1a2025;
}

.color-definition-0 {
    --base__color-1: #1a2025;
    --base__color-2: #ffffff;
    --base__color-3: #eb6a3e;
    --base-icon-color: #eb6a3e;
}

.color-definition-1 {
    --base__color-1: #ea9100;
    --base__color-2: #ffffff;
    --base__color-3: #ea9100;
    --base-icon-color: #ea9100;
}

.color-definition-2 {
    --base__color-1: #aebd23;
    --base__color-2: #ffffff;
    --base__color-3: #aebd23;
    --base-icon-color: #ffffff;
}

.color-definition-3 {
    --base__color-1: #f6f8fb;
    --base__color-2: #ffffff;
    --base__color-3: #f6f8fb;
    --base-icon-color: #f6f8fb;
}

body {
    overflow-x: hidden;
}

.text-container-wrapper ul:not(.contact-list),
.tx-datamintsgivvenews ul:not(.contact-list)
{
    list-style: none;
    margin-bottom: 25px;
}

.text-container-wrapper ul:not(.contact-list) li,
.tx-datamintsgivvenews ul:not(.contact-list) li
{
    list-style: none;
    position: relative;
    padding-bottom: 5px;
    padding-left: 20px;
}

.text-container-wrapper ul:not(.contact-list) li:last-child,
.tx-datamintsgivvenews ul:not(.contact-list) li:last-child
{
    padding-bottom: 0;
}

.text-container-wrapper ul:not(.contact-list) li:before,
.tx-datamintsgivvenews ul:not(.contact-list) li:before
{
    content: '';
    height: 6px;
    width: 6px;
    min-width: 6px;
    display: block;
    margin: 10px 10px 0 0;
    border-radius: 65px;
    position: absolute;
    left: 0;
    background-color: #ea9100;
}

.text-container-wrapper ul:not(.contact-list) li ul,
.tx-datamintsgivvenews ul:not(.contact-list) li ul
{
    padding-top: 5px;
    width: 100%;
    padding-left: 20px;
}

.text-container-wrapper ul:not(.contact-list) li a,
.tx-datamintsgivvenews ul:not(.contact-list) li a
{
    color: #ea9100;
}

.text-container-wrapper ol,
.tx-datamintsgivvenews ol
{
    list-style: none;
    margin-bottom: 25px;
}

.text-container-wrapper ol li,
.tx-datamintsgivvenews ol li
{
    list-style: none;
    position: relative;
    padding-bottom: 5px;
    padding-left: 35px;
    counter-increment: step-counter;
    line-height: 2.4rem;
}

.text-container-wrapper ol li:last-child,
.tx-datamintsgivvenews ol li:last-child
{
    padding-bottom: 0;
}

.text-container-wrapper ol li::before,
.tx-datamintsgivvenews ol li::before
{
    position: absolute;
    left: 0;
    font-size: 18px;
    font-weight: 600;
    content: counter(step-counter);
    background-color: #ea9100;
    color: #ffffff;
    border-radius: 3px;
    width: 25px;
    height: 27px;
    display: inline-block;
    text-align: center;
}

.text-container-wrapper ol li a,
.tx-datamintsgivvenews ol li a
{
    color: #ea9100;
}

.button-row {
    padding: 25px 0 0;
}

.button-row.align-right {
    width: 100%;
    justify-content: flex-end;
}

.styleguide-page-container {
    margin-top: -129px;
    padding: 150px 0;
}

.styleguide-page-container .section-container.background-0 *,
.styleguide-page-container .section-container.background-1 *,
.styleguide-page-container .section-container.background-2 *,
.styleguide-page-container .section-container.background-4 *,
.styleguide-page-container .section-container.background-5 * {
    color: #ffffff;
}

.styleguide-color-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
}

.styleguide-color-row .styleguide-color-element {
    width: 40px;
    height: 40px;
    border-radius: 120px;
    margin-right: 15px;
}

.styleguide-color-row .styleguide-color-element:last-child {
    margin: 0;
}

.styleguide-color-row .styleguide-color-element.color-0 {
    background: #1a2025;
}

.styleguide-color-row .styleguide-color-element.color-1 {
    background: #ffffff;
    border: 1px solid rgba(26, 32, 37, 0.2);
}

.styleguide-color-row .styleguide-color-element.color-2 {
    background: #eb6a3e;
}

.styleguide-color-row .styleguide-color-element.color-3 {
    background: #ea9100;
}

.styleguide-color-row .styleguide-color-element.color-4 {
    background: #aebd23;
}

.styleguide-color-row .styleguide-color-element.color-5 {
    background: #f6f8fb;
    border: 1px solid rgba(26, 32, 37, 0.2);
}

.styleguide-color-row .styleguide-color-element.color-6 {
    background: #757575;
}

.styleguide-button-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    padding: 30px 0;
}

.styleguide-button-row > a {
    margin-right: 12px;
}

.styleguide-grid-view-container .styleguide-grid-box {
    background: rgba(235, 106, 62, 0.3);
    width: 100%;
    height: 40px;
    border-radius: 4px;
    margin-bottom: 10px;
}

section.section-container.background-1,
section.section-container.background-2,
section.section-container.background-3,
section.section-container.background-4,
section.section-container.background-5 {
    position: relative;
    overflow: hidden;
}

section.section-container.background-1::before,
section.section-container.background-2::before,
section.section-container.background-3::before,
section.section-container.background-4::before,
section.section-container.background-5::before,
section.section-container.background-1::after,
section.section-container.background-2::after,
section.section-container.background-3::after,
section.section-container.background-4::after,
section.section-container.background-5::after {
    width: 0;
    height: 0;
    background: none;
    z-index: 1;
    content: "";
    position: absolute;
    border-style: solid;
    border-color: transparent transparent transparent rgba(26, 32, 37, 0.07);
}

section.section-container.background-1::before,
section.section-container.background-2::before,
section.section-container.background-3::before,
section.section-container.background-4::before,
section.section-container.background-5::before {
    bottom: -920px;
    left: -2418px;
    border-width: 2400px 0 0 2400px;
    transform: rotate(-35deg);
}

section.section-container.background-1::after,
section.section-container.background-2::after,
section.section-container.background-3::after,
section.section-container.background-4::after,
section.section-container.background-5::after {
    bottom: -761px;
    right: -1880px;
    transform: rotate(290deg);
    border-width: 2400px 0 0 2400px;
}

section.section-container.background-1 .main-content,
section.section-container.background-2 .main-content,
section.section-container.background-3 .main-content,
section.section-container.background-4 .main-content,
section.section-container.background-5 .main-content,
section.section-container.background-1 .site-content,
section.section-container.background-2 .site-content,
section.section-container.background-3 .site-content,
section.section-container.background-4 .site-content,
section.section-container.background-5 .site-content {
    position: relative;
    z-index: 2;
}

section.section-container.background-4::after,
section.section-container.background-4::before {
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.04);
}

section.section-container.background-1,
section.section-container.background-2,
section.section-container.background-3,
section.section-container.background-5 {
    --base-icon-color: #ffffff;
}

section.section-container.background-1 .headline-container h1,
section.section-container.background-2 .headline-container h1,
section.section-container.background-3 .headline-container h1,
section.section-container.background-5 .headline-container h1,
section.section-container.background-1 .headline-container h2,
section.section-container.background-2 .headline-container h2,
section.section-container.background-3 .headline-container h2,
section.section-container.background-5 .headline-container h2,
section.section-container.background-1 .headline-container h3,
section.section-container.background-2 .headline-container h3,
section.section-container.background-3 .headline-container h3,
section.section-container.background-5 .headline-container h3,
section.section-container.background-1 .headline-container h4,
section.section-container.background-2 .headline-container h4,
section.section-container.background-3 .headline-container h4,
section.section-container.background-5 .headline-container h4,
section.section-container.background-1 .headline-container h5,
section.section-container.background-2 .headline-container h5,
section.section-container.background-3 .headline-container h5,
section.section-container.background-5 .headline-container h5,
section.section-container.background-1 .headline-container h6,
section.section-container.background-2 .headline-container h6,
section.section-container.background-3 .headline-container h6,
section.section-container.background-5 .headline-container h6,
section.section-container.background-1 .headline-container p,
section.section-container.background-2 .headline-container p,
section.section-container.background-3 .headline-container p,
section.section-container.background-5 .headline-container p,
section.section-container.background-1 .headline-container b,
section.section-container.background-2 .headline-container b,
section.section-container.background-3 .headline-container b,
section.section-container.background-5 .headline-container b,
section.section-container.background-1 .headline-container strong,
section.section-container.background-2 .headline-container strong,
section.section-container.background-3 .headline-container strong,
section.section-container.background-5 .headline-container strong,
section.section-container.background-1 .headline-container i,
section.section-container.background-2 .headline-container i,
section.section-container.background-3 .headline-container i,
section.section-container.background-5 .headline-container i,
section.section-container.background-1 .headline-container em,
section.section-container.background-2 .headline-container em,
section.section-container.background-3 .headline-container em,
section.section-container.background-5 .headline-container em,
section.section-container.background-1 .headline-container blockquote,
section.section-container.background-2 .headline-container blockquote,
section.section-container.background-3 .headline-container blockquote,
section.section-container.background-5 .headline-container blockquote,
section.section-container.background-1 .headline-container span,
section.section-container.background-2 .headline-container span,
section.section-container.background-3 .headline-container span,
section.section-container.background-5 .headline-container span {
    color: #ffffff;
}

section.section-container.background-1 .headline-container h1.title-line::before,
section.section-container.background-2 .headline-container h1.title-line::before,
section.section-container.background-3 .headline-container h1.title-line::before,
section.section-container.background-5 .headline-container h1.title-line::before,
section.section-container.background-1 .headline-container h2.title-line::before,
section.section-container.background-2 .headline-container h2.title-line::before,
section.section-container.background-3 .headline-container h2.title-line::before,
section.section-container.background-5 .headline-container h2.title-line::before,
section.section-container.background-1 .headline-container h3.title-line::before,
section.section-container.background-2 .headline-container h3.title-line::before,
section.section-container.background-3 .headline-container h3.title-line::before,
section.section-container.background-5 .headline-container h3.title-line::before,
section.section-container.background-1 .headline-container h4.title-line::before,
section.section-container.background-2 .headline-container h4.title-line::before,
section.section-container.background-3 .headline-container h4.title-line::before,
section.section-container.background-5 .headline-container h4.title-line::before,
section.section-container.background-1 .headline-container h5.title-line::before,
section.section-container.background-2 .headline-container h5.title-line::before,
section.section-container.background-3 .headline-container h5.title-line::before,
section.section-container.background-5 .headline-container h5.title-line::before,
section.section-container.background-1 .headline-container h6.title-line::before,
section.section-container.background-2 .headline-container h6.title-line::before,
section.section-container.background-3 .headline-container h6.title-line::before,
section.section-container.background-5 .headline-container h6.title-line::before,
section.section-container.background-1 .headline-container p.title-line::before,
section.section-container.background-2 .headline-container p.title-line::before,
section.section-container.background-3 .headline-container p.title-line::before,
section.section-container.background-5 .headline-container p.title-line::before,
section.section-container.background-1 .headline-container b.title-line::before,
section.section-container.background-2 .headline-container b.title-line::before,
section.section-container.background-3 .headline-container b.title-line::before,
section.section-container.background-5 .headline-container b.title-line::before,
section.section-container.background-1 .headline-container strong.title-line::before,
section.section-container.background-2 .headline-container strong.title-line::before,
section.section-container.background-3 .headline-container strong.title-line::before,
section.section-container.background-5 .headline-container strong.title-line::before,
section.section-container.background-1 .headline-container i.title-line::before,
section.section-container.background-2 .headline-container i.title-line::before,
section.section-container.background-3 .headline-container i.title-line::before,
section.section-container.background-5 .headline-container i.title-line::before,
section.section-container.background-1 .headline-container em.title-line::before,
section.section-container.background-2 .headline-container em.title-line::before,
section.section-container.background-3 .headline-container em.title-line::before,
section.section-container.background-5 .headline-container em.title-line::before,
section.section-container.background-1 .headline-container blockquote.title-line::before,
section.section-container.background-2 .headline-container blockquote.title-line::before,
section.section-container.background-3 .headline-container blockquote.title-line::before,
section.section-container.background-5 .headline-container blockquote.title-line::before,
section.section-container.background-1 .headline-container span.title-line::before,
section.section-container.background-2 .headline-container span.title-line::before,
section.section-container.background-3 .headline-container span.title-line::before,
section.section-container.background-5 .headline-container span.title-line::before {
    background-color: #ffffff;
}

section.section-container.background-1 .icon-text-container h1,
section.section-container.background-2 .icon-text-container h1,
section.section-container.background-3 .icon-text-container h1,
section.section-container.background-5 .icon-text-container h1,
section.section-container.background-1 .icon-text-container h2,
section.section-container.background-2 .icon-text-container h2,
section.section-container.background-3 .icon-text-container h2,
section.section-container.background-5 .icon-text-container h2,
section.section-container.background-1 .icon-text-container h3,
section.section-container.background-2 .icon-text-container h3,
section.section-container.background-3 .icon-text-container h3,
section.section-container.background-5 .icon-text-container h3,
section.section-container.background-1 .icon-text-container h4,
section.section-container.background-2 .icon-text-container h4,
section.section-container.background-3 .icon-text-container h4,
section.section-container.background-5 .icon-text-container h4,
section.section-container.background-1 .icon-text-container h5,
section.section-container.background-2 .icon-text-container h5,
section.section-container.background-3 .icon-text-container h5,
section.section-container.background-5 .icon-text-container h5,
section.section-container.background-1 .icon-text-container h6,
section.section-container.background-2 .icon-text-container h6,
section.section-container.background-3 .icon-text-container h6,
section.section-container.background-5 .icon-text-container h6,
section.section-container.background-1 .icon-text-container p,
section.section-container.background-2 .icon-text-container p,
section.section-container.background-3 .icon-text-container p,
section.section-container.background-5 .icon-text-container p,
section.section-container.background-1 .icon-text-container b,
section.section-container.background-2 .icon-text-container b,
section.section-container.background-3 .icon-text-container b,
section.section-container.background-5 .icon-text-container b,
section.section-container.background-1 .icon-text-container strong,
section.section-container.background-2 .icon-text-container strong,
section.section-container.background-3 .icon-text-container strong,
section.section-container.background-5 .icon-text-container strong,
section.section-container.background-1 .icon-text-container i,
section.section-container.background-2 .icon-text-container i,
section.section-container.background-3 .icon-text-container i,
section.section-container.background-5 .icon-text-container i,
section.section-container.background-1 .icon-text-container em,
section.section-container.background-2 .icon-text-container em,
section.section-container.background-3 .icon-text-container em,
section.section-container.background-5 .icon-text-container em,
section.section-container.background-1 .icon-text-container blockquote,
section.section-container.background-2 .icon-text-container blockquote,
section.section-container.background-3 .icon-text-container blockquote,
section.section-container.background-5 .icon-text-container blockquote,
section.section-container.background-1 .icon-text-container span,
section.section-container.background-2 .icon-text-container span,
section.section-container.background-3 .icon-text-container span,
section.section-container.background-5 .icon-text-container span {
    color: #ffffff;
}

section.section-container.background-1 .text-container-wrapper h1,
section.section-container.background-2 .text-container-wrapper h1,
section.section-container.background-3 .text-container-wrapper h1,
section.section-container.background-5 .text-container-wrapper h1,
section.section-container.background-1 .text-image-wrapper h1,
section.section-container.background-2 .text-image-wrapper h1,
section.section-container.background-3 .text-image-wrapper h1,
section.section-container.background-5 .text-image-wrapper h1,
section.section-container.background-1 .text-container h1,
section.section-container.background-2 .text-container h1,
section.section-container.background-3 .text-container h1,
section.section-container.background-5 .text-container h1,
section.section-container.background-1 .text-container-wrapper h2,
section.section-container.background-2 .text-container-wrapper h2,
section.section-container.background-3 .text-container-wrapper h2,
section.section-container.background-5 .text-container-wrapper h2,
section.section-container.background-1 .text-image-wrapper h2,
section.section-container.background-2 .text-image-wrapper h2,
section.section-container.background-3 .text-image-wrapper h2,
section.section-container.background-5 .text-image-wrapper h2,
section.section-container.background-1 .text-container h2,
section.section-container.background-2 .text-container h2,
section.section-container.background-3 .text-container h2,
section.section-container.background-5 .text-container h2,
section.section-container.background-1 .text-container-wrapper h3,
section.section-container.background-2 .text-container-wrapper h3,
section.section-container.background-3 .text-container-wrapper h3,
section.section-container.background-5 .text-container-wrapper h3,
section.section-container.background-1 .text-image-wrapper h3,
section.section-container.background-2 .text-image-wrapper h3,
section.section-container.background-3 .text-image-wrapper h3,
section.section-container.background-5 .text-image-wrapper h3,
section.section-container.background-1 .text-container h3,
section.section-container.background-2 .text-container h3,
section.section-container.background-3 .text-container h3,
section.section-container.background-5 .text-container h3,
section.section-container.background-1 .text-container-wrapper h4,
section.section-container.background-2 .text-container-wrapper h4,
section.section-container.background-3 .text-container-wrapper h4,
section.section-container.background-5 .text-container-wrapper h4,
section.section-container.background-1 .text-image-wrapper h4,
section.section-container.background-2 .text-image-wrapper h4,
section.section-container.background-3 .text-image-wrapper h4,
section.section-container.background-5 .text-image-wrapper h4,
section.section-container.background-1 .text-container h4,
section.section-container.background-2 .text-container h4,
section.section-container.background-3 .text-container h4,
section.section-container.background-5 .text-container h4,
section.section-container.background-1 .text-container-wrapper h5,
section.section-container.background-2 .text-container-wrapper h5,
section.section-container.background-3 .text-container-wrapper h5,
section.section-container.background-5 .text-container-wrapper h5,
section.section-container.background-1 .text-image-wrapper h5,
section.section-container.background-2 .text-image-wrapper h5,
section.section-container.background-3 .text-image-wrapper h5,
section.section-container.background-5 .text-image-wrapper h5,
section.section-container.background-1 .text-container h5,
section.section-container.background-2 .text-container h5,
section.section-container.background-3 .text-container h5,
section.section-container.background-5 .text-container h5,
section.section-container.background-1 .text-container-wrapper h6,
section.section-container.background-2 .text-container-wrapper h6,
section.section-container.background-3 .text-container-wrapper h6,
section.section-container.background-5 .text-container-wrapper h6,
section.section-container.background-1 .text-image-wrapper h6,
section.section-container.background-2 .text-image-wrapper h6,
section.section-container.background-3 .text-image-wrapper h6,
section.section-container.background-5 .text-image-wrapper h6,
section.section-container.background-1 .text-container h6,
section.section-container.background-2 .text-container h6,
section.section-container.background-3 .text-container h6,
section.section-container.background-5 .text-container h6,
section.section-container.background-1 .text-container-wrapper p,
section.section-container.background-2 .text-container-wrapper p,
section.section-container.background-3 .text-container-wrapper p,
section.section-container.background-5 .text-container-wrapper p,
section.section-container.background-1 .text-image-wrapper p,
section.section-container.background-2 .text-image-wrapper p,
section.section-container.background-3 .text-image-wrapper p,
section.section-container.background-5 .text-image-wrapper p,
section.section-container.background-1 .text-container p,
section.section-container.background-2 .text-container p,
section.section-container.background-3 .text-container p,
section.section-container.background-5 .text-container p,
section.section-container.background-1 .text-container-wrapper b,
section.section-container.background-2 .text-container-wrapper b,
section.section-container.background-3 .text-container-wrapper b,
section.section-container.background-5 .text-container-wrapper b,
section.section-container.background-1 .text-image-wrapper b,
section.section-container.background-2 .text-image-wrapper b,
section.section-container.background-3 .text-image-wrapper b,
section.section-container.background-5 .text-image-wrapper b,
section.section-container.background-1 .text-container b,
section.section-container.background-2 .text-container b,
section.section-container.background-3 .text-container b,
section.section-container.background-5 .text-container b,
section.section-container.background-1 .text-container-wrapper strong,
section.section-container.background-2 .text-container-wrapper strong,
section.section-container.background-3 .text-container-wrapper strong,
section.section-container.background-5 .text-container-wrapper strong,
section.section-container.background-1 .text-image-wrapper strong,
section.section-container.background-2 .text-image-wrapper strong,
section.section-container.background-3 .text-image-wrapper strong,
section.section-container.background-5 .text-image-wrapper strong,
section.section-container.background-1 .text-container strong,
section.section-container.background-2 .text-container strong,
section.section-container.background-3 .text-container strong,
section.section-container.background-5 .text-container strong,
section.section-container.background-1 .text-container-wrapper i,
section.section-container.background-2 .text-container-wrapper i,
section.section-container.background-3 .text-container-wrapper i,
section.section-container.background-5 .text-container-wrapper i,
section.section-container.background-1 .text-image-wrapper i,
section.section-container.background-2 .text-image-wrapper i,
section.section-container.background-3 .text-image-wrapper i,
section.section-container.background-5 .text-image-wrapper i,
section.section-container.background-1 .text-container i,
section.section-container.background-2 .text-container i,
section.section-container.background-3 .text-container i,
section.section-container.background-5 .text-container i,
section.section-container.background-1 .text-container-wrapper em,
section.section-container.background-2 .text-container-wrapper em,
section.section-container.background-3 .text-container-wrapper em,
section.section-container.background-5 .text-container-wrapper em,
section.section-container.background-1 .text-image-wrapper em,
section.section-container.background-2 .text-image-wrapper em,
section.section-container.background-3 .text-image-wrapper em,
section.section-container.background-5 .text-image-wrapper em,
section.section-container.background-1 .text-container em,
section.section-container.background-2 .text-container em,
section.section-container.background-3 .text-container em,
section.section-container.background-5 .text-container em,
section.section-container.background-1 .text-container-wrapper blockquote,
section.section-container.background-2 .text-container-wrapper blockquote,
section.section-container.background-3 .text-container-wrapper blockquote,
section.section-container.background-5 .text-container-wrapper blockquote,
section.section-container.background-1 .text-image-wrapper blockquote,
section.section-container.background-2 .text-image-wrapper blockquote,
section.section-container.background-3 .text-image-wrapper blockquote,
section.section-container.background-5 .text-image-wrapper blockquote,
section.section-container.background-1 .text-container blockquote,
section.section-container.background-2 .text-container blockquote,
section.section-container.background-3 .text-container blockquote,
section.section-container.background-5 .text-container blockquote,
section.section-container.background-1 .text-container-wrapper span,
section.section-container.background-2 .text-container-wrapper span,
section.section-container.background-3 .text-container-wrapper span,
section.section-container.background-5 .text-container-wrapper span,
section.section-container.background-1 .text-image-wrapper span,
section.section-container.background-2 .text-image-wrapper span,
section.section-container.background-3 .text-image-wrapper span,
section.section-container.background-5 .text-image-wrapper span,
section.section-container.background-1 .text-container span,
section.section-container.background-2 .text-container span,
section.section-container.background-3 .text-container span,
section.section-container.background-5 .text-container span {
    color: #ffffff;
}

section.section-container.background-1 .text-container-wrapper p > a,
section.section-container.background-2 .text-container-wrapper p > a,
section.section-container.background-3 .text-container-wrapper p > a,
section.section-container.background-5 .text-container-wrapper p > a,
section.section-container.background-1 .text-image-wrapper p > a,
section.section-container.background-2 .text-image-wrapper p > a,
section.section-container.background-3 .text-image-wrapper p > a,
section.section-container.background-5 .text-image-wrapper p > a,
section.section-container.background-1 .text-container p > a,
section.section-container.background-2 .text-container p > a,
section.section-container.background-3 .text-container p > a,
section.section-container.background-5 .text-container p > a {
    color: #ffffff;
    text-decoration: underline;
}

section.section-container.background-1 .text-container-wrapper ul li,
section.section-container.background-2 .text-container-wrapper ul li,
section.section-container.background-3 .text-container-wrapper ul li,
section.section-container.background-5 .text-container-wrapper ul li,
section.section-container.background-1 .text-image-wrapper ul li,
section.section-container.background-2 .text-image-wrapper ul li,
section.section-container.background-3 .text-image-wrapper ul li,
section.section-container.background-5 .text-image-wrapper ul li,
section.section-container.background-1 .text-container ul li,
section.section-container.background-2 .text-container ul li,
section.section-container.background-3 .text-container ul li,
section.section-container.background-5 .text-container ul li {
    color: #ffffff;
}

section.section-container.background-1 .text-container-wrapper ul li p,
section.section-container.background-2 .text-container-wrapper ul li p,
section.section-container.background-3 .text-container-wrapper ul li p,
section.section-container.background-5 .text-container-wrapper ul li p,
section.section-container.background-1 .text-image-wrapper ul li p,
section.section-container.background-2 .text-image-wrapper ul li p,
section.section-container.background-3 .text-image-wrapper ul li p,
section.section-container.background-5 .text-image-wrapper ul li p,
section.section-container.background-1 .text-container ul li p,
section.section-container.background-2 .text-container ul li p,
section.section-container.background-3 .text-container ul li p,
section.section-container.background-5 .text-container ul li p,
section.section-container.background-1 .text-container-wrapper ul li b,
section.section-container.background-2 .text-container-wrapper ul li b,
section.section-container.background-3 .text-container-wrapper ul li b,
section.section-container.background-5 .text-container-wrapper ul li b,
section.section-container.background-1 .text-image-wrapper ul li b,
section.section-container.background-2 .text-image-wrapper ul li b,
section.section-container.background-3 .text-image-wrapper ul li b,
section.section-container.background-5 .text-image-wrapper ul li b,
section.section-container.background-1 .text-container ul li b,
section.section-container.background-2 .text-container ul li b,
section.section-container.background-3 .text-container ul li b,
section.section-container.background-5 .text-container ul li b,
section.section-container.background-1 .text-container-wrapper ul li strong,
section.section-container.background-2 .text-container-wrapper ul li strong,
section.section-container.background-3 .text-container-wrapper ul li strong,
section.section-container.background-5 .text-container-wrapper ul li strong,
section.section-container.background-1 .text-image-wrapper ul li strong,
section.section-container.background-2 .text-image-wrapper ul li strong,
section.section-container.background-3 .text-image-wrapper ul li strong,
section.section-container.background-5 .text-image-wrapper ul li strong,
section.section-container.background-1 .text-container ul li strong,
section.section-container.background-2 .text-container ul li strong,
section.section-container.background-3 .text-container ul li strong,
section.section-container.background-5 .text-container ul li strong,
section.section-container.background-1 .text-container-wrapper ul li i,
section.section-container.background-2 .text-container-wrapper ul li i,
section.section-container.background-3 .text-container-wrapper ul li i,
section.section-container.background-5 .text-container-wrapper ul li i,
section.section-container.background-1 .text-image-wrapper ul li i,
section.section-container.background-2 .text-image-wrapper ul li i,
section.section-container.background-3 .text-image-wrapper ul li i,
section.section-container.background-5 .text-image-wrapper ul li i,
section.section-container.background-1 .text-container ul li i,
section.section-container.background-2 .text-container ul li i,
section.section-container.background-3 .text-container ul li i,
section.section-container.background-5 .text-container ul li i,
section.section-container.background-1 .text-container-wrapper ul li em,
section.section-container.background-2 .text-container-wrapper ul li em,
section.section-container.background-3 .text-container-wrapper ul li em,
section.section-container.background-5 .text-container-wrapper ul li em,
section.section-container.background-1 .text-image-wrapper ul li em,
section.section-container.background-2 .text-image-wrapper ul li em,
section.section-container.background-3 .text-image-wrapper ul li em,
section.section-container.background-5 .text-image-wrapper ul li em,
section.section-container.background-1 .text-container ul li em,
section.section-container.background-2 .text-container ul li em,
section.section-container.background-3 .text-container ul li em,
section.section-container.background-5 .text-container ul li em,
section.section-container.background-1 .text-container-wrapper ul li span,
section.section-container.background-2 .text-container-wrapper ul li span,
section.section-container.background-3 .text-container-wrapper ul li span,
section.section-container.background-5 .text-container-wrapper ul li span,
section.section-container.background-1 .text-image-wrapper ul li span,
section.section-container.background-2 .text-image-wrapper ul li span,
section.section-container.background-3 .text-image-wrapper ul li span,
section.section-container.background-5 .text-image-wrapper ul li span,
section.section-container.background-1 .text-container ul li span,
section.section-container.background-2 .text-container ul li span,
section.section-container.background-3 .text-container ul li span,
section.section-container.background-5 .text-container ul li span {
    color: #ffffff;
}

section.section-container.background-1 .text-container-wrapper ul li a,
section.section-container.background-2 .text-container-wrapper ul li a,
section.section-container.background-3 .text-container-wrapper ul li a,
section.section-container.background-5 .text-container-wrapper ul li a,
section.section-container.background-1 .text-image-wrapper ul li a,
section.section-container.background-2 .text-image-wrapper ul li a,
section.section-container.background-3 .text-image-wrapper ul li a,
section.section-container.background-5 .text-image-wrapper ul li a,
section.section-container.background-1 .text-container ul li a,
section.section-container.background-2 .text-container ul li a,
section.section-container.background-3 .text-container ul li a,
section.section-container.background-5 .text-container ul li a {
    color: #ffffff;
}

section.section-container.background-1 .text-container-wrapper ul li::before,
section.section-container.background-2 .text-container-wrapper ul li::before,
section.section-container.background-3 .text-container-wrapper ul li::before,
section.section-container.background-5 .text-container-wrapper ul li::before,
section.section-container.background-1 .text-image-wrapper ul li::before,
section.section-container.background-2 .text-image-wrapper ul li::before,
section.section-container.background-3 .text-image-wrapper ul li::before,
section.section-container.background-5 .text-image-wrapper ul li::before,
section.section-container.background-1 .text-container ul li::before,
section.section-container.background-2 .text-container ul li::before,
section.section-container.background-3 .text-container ul li::before,
section.section-container.background-5 .text-container ul li::before {
    background-color: #ffffff;
}

section.section-container.background-1 .text-container-wrapper ol li,
section.section-container.background-2 .text-container-wrapper ol li,
section.section-container.background-3 .text-container-wrapper ol li,
section.section-container.background-5 .text-container-wrapper ol li,
section.section-container.background-1 .text-image-wrapper ol li,
section.section-container.background-2 .text-image-wrapper ol li,
section.section-container.background-3 .text-image-wrapper ol li,
section.section-container.background-5 .text-image-wrapper ol li,
section.section-container.background-1 .text-container ol li,
section.section-container.background-2 .text-container ol li,
section.section-container.background-3 .text-container ol li,
section.section-container.background-5 .text-container ol li {
    color: #ffffff;
}

section.section-container.background-1 .text-container-wrapper ol li::before,
section.section-container.background-2 .text-container-wrapper ol li::before,
section.section-container.background-3 .text-container-wrapper ol li::before,
section.section-container.background-5 .text-container-wrapper ol li::before,
section.section-container.background-1 .text-image-wrapper ol li::before,
section.section-container.background-2 .text-image-wrapper ol li::before,
section.section-container.background-3 .text-image-wrapper ol li::before,
section.section-container.background-5 .text-image-wrapper ol li::before,
section.section-container.background-1 .text-container ol li::before,
section.section-container.background-2 .text-container ol li::before,
section.section-container.background-3 .text-container ol li::before,
section.section-container.background-5 .text-container ol li::before {
    background-color: #ffffff;
    color: #1a2025;
}

section.section-container.background-1 .text-container-wrapper ol li a,
section.section-container.background-2 .text-container-wrapper ol li a,
section.section-container.background-3 .text-container-wrapper ol li a,
section.section-container.background-5 .text-container-wrapper ol li a,
section.section-container.background-1 .text-image-wrapper ol li a,
section.section-container.background-2 .text-image-wrapper ol li a,
section.section-container.background-3 .text-image-wrapper ol li a,
section.section-container.background-5 .text-image-wrapper ol li a,
section.section-container.background-1 .text-container ol li a,
section.section-container.background-2 .text-container ol li a,
section.section-container.background-3 .text-container ol li a,
section.section-container.background-5 .text-container ol li a {
    color: #ffffff;
}

section.section-container.background-1 a.link.type-2.color-1,
section.section-container.background-2 a.link.type-2.color-1,
section.section-container.background-3 a.link.type-2.color-1,
section.section-container.background-5 a.link.type-2.color-1,
section.section-container.background-1 span.link.type-2.color-1,
section.section-container.background-2 span.link.type-2.color-1,
section.section-container.background-3 span.link.type-2.color-1,
section.section-container.background-5 span.link.type-2.color-1,
section.section-container.background-1 a.link.type-1.color-1,
section.section-container.background-2 a.link.type-1.color-1,
section.section-container.background-3 a.link.type-1.color-1,
section.section-container.background-5 a.link.type-1.color-1,
section.section-container.background-1 span.link.type-1.color-1,
section.section-container.background-2 span.link.type-1.color-1,
section.section-container.background-3 span.link.type-1.color-1,
section.section-container.background-5 span.link.type-1.color-1,
section.section-container.background-1 a.link.type-3.color-1,
section.section-container.background-2 a.link.type-3.color-1,
section.section-container.background-3 a.link.type-3.color-1,
section.section-container.background-5 a.link.type-3.color-1,
section.section-container.background-1 span.link.type-3.color-1,
section.section-container.background-2 span.link.type-3.color-1,
section.section-container.background-3 span.link.type-3.color-1,
section.section-container.background-5 span.link.type-3.color-1,
section.section-container.background-1 a.link.type-2.color-2,
section.section-container.background-2 a.link.type-2.color-2,
section.section-container.background-3 a.link.type-2.color-2,
section.section-container.background-5 a.link.type-2.color-2,
section.section-container.background-1 span.link.type-2.color-2,
section.section-container.background-2 span.link.type-2.color-2,
section.section-container.background-3 span.link.type-2.color-2,
section.section-container.background-5 span.link.type-2.color-2,
section.section-container.background-1 a.link.type-1.color-2,
section.section-container.background-2 a.link.type-1.color-2,
section.section-container.background-3 a.link.type-1.color-2,
section.section-container.background-5 a.link.type-1.color-2,
section.section-container.background-1 span.link.type-1.color-2,
section.section-container.background-2 span.link.type-1.color-2,
section.section-container.background-3 span.link.type-1.color-2,
section.section-container.background-5 span.link.type-1.color-2,
section.section-container.background-1 a.link.type-3.color-2,
section.section-container.background-2 a.link.type-3.color-2,
section.section-container.background-3 a.link.type-3.color-2,
section.section-container.background-5 a.link.type-3.color-2,
section.section-container.background-1 span.link.type-3.color-2,
section.section-container.background-2 span.link.type-3.color-2,
section.section-container.background-3 span.link.type-3.color-2,
section.section-container.background-5 span.link.type-3.color-2,
section.section-container.background-1 a.link.type-2.color-3,
section.section-container.background-2 a.link.type-2.color-3,
section.section-container.background-3 a.link.type-2.color-3,
section.section-container.background-5 a.link.type-2.color-3,
section.section-container.background-1 span.link.type-2.color-3,
section.section-container.background-2 span.link.type-2.color-3,
section.section-container.background-3 span.link.type-2.color-3,
section.section-container.background-5 span.link.type-2.color-3,
section.section-container.background-1 a.link.type-1.color-3,
section.section-container.background-2 a.link.type-1.color-3,
section.section-container.background-3 a.link.type-1.color-3,
section.section-container.background-5 a.link.type-1.color-3,
section.section-container.background-1 span.link.type-1.color-3,
section.section-container.background-2 span.link.type-1.color-3,
section.section-container.background-3 span.link.type-1.color-3,
section.section-container.background-5 span.link.type-1.color-3,
section.section-container.background-1 a.link.type-3.color-3,
section.section-container.background-2 a.link.type-3.color-3,
section.section-container.background-3 a.link.type-3.color-3,
section.section-container.background-5 a.link.type-3.color-3,
section.section-container.background-1 span.link.type-3.color-3,
section.section-container.background-2 span.link.type-3.color-3,
section.section-container.background-3 span.link.type-3.color-3,
section.section-container.background-5 span.link.type-3.color-3,
section.section-container.background-1 a.link.type-2.color-4,
section.section-container.background-2 a.link.type-2.color-4,
section.section-container.background-3 a.link.type-2.color-4,
section.section-container.background-5 a.link.type-2.color-4,
section.section-container.background-1 span.link.type-2.color-4,
section.section-container.background-2 span.link.type-2.color-4,
section.section-container.background-3 span.link.type-2.color-4,
section.section-container.background-5 span.link.type-2.color-4,
section.section-container.background-1 a.link.type-1.color-4,
section.section-container.background-2 a.link.type-1.color-4,
section.section-container.background-3 a.link.type-1.color-4,
section.section-container.background-5 a.link.type-1.color-4,
section.section-container.background-1 span.link.type-1.color-4,
section.section-container.background-2 span.link.type-1.color-4,
section.section-container.background-3 span.link.type-1.color-4,
section.section-container.background-5 span.link.type-1.color-4,
section.section-container.background-1 a.link.type-3.color-4,
section.section-container.background-2 a.link.type-3.color-4,
section.section-container.background-3 a.link.type-3.color-4,
section.section-container.background-5 a.link.type-3.color-4,
section.section-container.background-1 span.link.type-3.color-4,
section.section-container.background-2 span.link.type-3.color-4,
section.section-container.background-3 span.link.type-3.color-4,
section.section-container.background-5 span.link.type-3.color-4,
section.section-container.background-1 a.link.type-2.color-5,
section.section-container.background-2 a.link.type-2.color-5,
section.section-container.background-3 a.link.type-2.color-5,
section.section-container.background-5 a.link.type-2.color-5,
section.section-container.background-1 span.link.type-2.color-5,
section.section-container.background-2 span.link.type-2.color-5,
section.section-container.background-3 span.link.type-2.color-5,
section.section-container.background-5 span.link.type-2.color-5,
section.section-container.background-1 a.link.type-1.color-5,
section.section-container.background-2 a.link.type-1.color-5,
section.section-container.background-3 a.link.type-1.color-5,
section.section-container.background-5 a.link.type-1.color-5,
section.section-container.background-1 span.link.type-1.color-5,
section.section-container.background-2 span.link.type-1.color-5,
section.section-container.background-3 span.link.type-1.color-5,
section.section-container.background-5 span.link.type-1.color-5,
section.section-container.background-1 a.link.type-3.color-5,
section.section-container.background-2 a.link.type-3.color-5,
section.section-container.background-3 a.link.type-3.color-5,
section.section-container.background-5 a.link.type-3.color-5,
section.section-container.background-1 span.link.type-3.color-5,
section.section-container.background-2 span.link.type-3.color-5,
section.section-container.background-3 span.link.type-3.color-5,
section.section-container.background-5 span.link.type-3.color-5,
section.section-container.background-1 a.link.type-2.color-6,
section.section-container.background-2 a.link.type-2.color-6,
section.section-container.background-3 a.link.type-2.color-6,
section.section-container.background-5 a.link.type-2.color-6,
section.section-container.background-1 span.link.type-2.color-6,
section.section-container.background-2 span.link.type-2.color-6,
section.section-container.background-3 span.link.type-2.color-6,
section.section-container.background-5 span.link.type-2.color-6,
section.section-container.background-1 a.link.type-1.color-6,
section.section-container.background-2 a.link.type-1.color-6,
section.section-container.background-3 a.link.type-1.color-6,
section.section-container.background-5 a.link.type-1.color-6,
section.section-container.background-1 span.link.type-1.color-6,
section.section-container.background-2 span.link.type-1.color-6,
section.section-container.background-3 span.link.type-1.color-6,
section.section-container.background-5 span.link.type-1.color-6,
section.section-container.background-1 a.link.type-3.color-6,
section.section-container.background-2 a.link.type-3.color-6,
section.section-container.background-3 a.link.type-3.color-6,
section.section-container.background-5 a.link.type-3.color-6,
section.section-container.background-1 span.link.type-3.color-6,
section.section-container.background-2 span.link.type-3.color-6,
section.section-container.background-3 span.link.type-3.color-6,
section.section-container.background-5 span.link.type-3.color-6,
section.section-container.background-1 a.link.type-2.color-7,
section.section-container.background-2 a.link.type-2.color-7,
section.section-container.background-3 a.link.type-2.color-7,
section.section-container.background-5 a.link.type-2.color-7,
section.section-container.background-1 span.link.type-2.color-7,
section.section-container.background-2 span.link.type-2.color-7,
section.section-container.background-3 span.link.type-2.color-7,
section.section-container.background-5 span.link.type-2.color-7,
section.section-container.background-1 a.link.type-1.color-7,
section.section-container.background-2 a.link.type-1.color-7,
section.section-container.background-3 a.link.type-1.color-7,
section.section-container.background-5 a.link.type-1.color-7,
section.section-container.background-1 span.link.type-1.color-7,
section.section-container.background-2 span.link.type-1.color-7,
section.section-container.background-3 span.link.type-1.color-7,
section.section-container.background-5 span.link.type-1.color-7,
section.section-container.background-1 a.link.type-3.color-7,
section.section-container.background-2 a.link.type-3.color-7,
section.section-container.background-3 a.link.type-3.color-7,
section.section-container.background-5 a.link.type-3.color-7,
section.section-container.background-1 span.link.type-3.color-7,
section.section-container.background-2 span.link.type-3.color-7,
section.section-container.background-3 span.link.type-3.color-7,
section.section-container.background-5 span.link.type-3.color-7,
section.section-container.background-1 a.link.type-2.color-8,
section.section-container.background-2 a.link.type-2.color-8,
section.section-container.background-3 a.link.type-2.color-8,
section.section-container.background-5 a.link.type-2.color-8,
section.section-container.background-1 span.link.type-2.color-8,
section.section-container.background-2 span.link.type-2.color-8,
section.section-container.background-3 span.link.type-2.color-8,
section.section-container.background-5 span.link.type-2.color-8,
section.section-container.background-1 a.link.type-1.color-8,
section.section-container.background-2 a.link.type-1.color-8,
section.section-container.background-3 a.link.type-1.color-8,
section.section-container.background-5 a.link.type-1.color-8,
section.section-container.background-1 span.link.type-1.color-8,
section.section-container.background-2 span.link.type-1.color-8,
section.section-container.background-3 span.link.type-1.color-8,
section.section-container.background-5 span.link.type-1.color-8,
section.section-container.background-1 a.link.type-3.color-8,
section.section-container.background-2 a.link.type-3.color-8,
section.section-container.background-3 a.link.type-3.color-8,
section.section-container.background-5 a.link.type-3.color-8,
section.section-container.background-1 span.link.type-3.color-8,
section.section-container.background-2 span.link.type-3.color-8,
section.section-container.background-3 span.link.type-3.color-8,
section.section-container.background-5 span.link.type-3.color-8,
section.section-container.background-1 a.link.type-2.color-9,
section.section-container.background-2 a.link.type-2.color-9,
section.section-container.background-3 a.link.type-2.color-9,
section.section-container.background-5 a.link.type-2.color-9,
section.section-container.background-1 span.link.type-2.color-9,
section.section-container.background-2 span.link.type-2.color-9,
section.section-container.background-3 span.link.type-2.color-9,
section.section-container.background-5 span.link.type-2.color-9,
section.section-container.background-1 a.link.type-1.color-9,
section.section-container.background-2 a.link.type-1.color-9,
section.section-container.background-3 a.link.type-1.color-9,
section.section-container.background-5 a.link.type-1.color-9,
section.section-container.background-1 span.link.type-1.color-9,
section.section-container.background-2 span.link.type-1.color-9,
section.section-container.background-3 span.link.type-1.color-9,
section.section-container.background-5 span.link.type-1.color-9,
section.section-container.background-1 a.link.type-3.color-9,
section.section-container.background-2 a.link.type-3.color-9,
section.section-container.background-3 a.link.type-3.color-9,
section.section-container.background-5 a.link.type-3.color-9,
section.section-container.background-1 span.link.type-3.color-9,
section.section-container.background-2 span.link.type-3.color-9,
section.section-container.background-3 span.link.type-3.color-9,
section.section-container.background-5 span.link.type-3.color-9,
section.section-container.background-1 a.link.type-2.color-10,
section.section-container.background-2 a.link.type-2.color-10,
section.section-container.background-3 a.link.type-2.color-10,
section.section-container.background-5 a.link.type-2.color-10,
section.section-container.background-1 span.link.type-2.color-10,
section.section-container.background-2 span.link.type-2.color-10,
section.section-container.background-3 span.link.type-2.color-10,
section.section-container.background-5 span.link.type-2.color-10,
section.section-container.background-1 a.link.type-1.color-10,
section.section-container.background-2 a.link.type-1.color-10,
section.section-container.background-3 a.link.type-1.color-10,
section.section-container.background-5 a.link.type-1.color-10,
section.section-container.background-1 span.link.type-1.color-10,
section.section-container.background-2 span.link.type-1.color-10,
section.section-container.background-3 span.link.type-1.color-10,
section.section-container.background-5 span.link.type-1.color-10,
section.section-container.background-1 a.link.type-3.color-10,
section.section-container.background-2 a.link.type-3.color-10,
section.section-container.background-3 a.link.type-3.color-10,
section.section-container.background-5 a.link.type-3.color-10,
section.section-container.background-1 span.link.type-3.color-10,
section.section-container.background-2 span.link.type-3.color-10,
section.section-container.background-3 span.link.type-3.color-10,
section.section-container.background-5 span.link.type-3.color-10 {
    color: #ffffff;
}

section.section-container.background-1 a.link.type-2.color-1::after,
section.section-container.background-2 a.link.type-2.color-1::after,
section.section-container.background-3 a.link.type-2.color-1::after,
section.section-container.background-5 a.link.type-2.color-1::after,
section.section-container.background-1 span.link.type-2.color-1::after,
section.section-container.background-2 span.link.type-2.color-1::after,
section.section-container.background-3 span.link.type-2.color-1::after,
section.section-container.background-5 span.link.type-2.color-1::after,
section.section-container.background-1 a.link.type-1.color-1::after,
section.section-container.background-2 a.link.type-1.color-1::after,
section.section-container.background-3 a.link.type-1.color-1::after,
section.section-container.background-5 a.link.type-1.color-1::after,
section.section-container.background-1 span.link.type-1.color-1::after,
section.section-container.background-2 span.link.type-1.color-1::after,
section.section-container.background-3 span.link.type-1.color-1::after,
section.section-container.background-5 span.link.type-1.color-1::after,
section.section-container.background-1 a.link.type-3.color-1::after,
section.section-container.background-2 a.link.type-3.color-1::after,
section.section-container.background-3 a.link.type-3.color-1::after,
section.section-container.background-5 a.link.type-3.color-1::after,
section.section-container.background-1 span.link.type-3.color-1::after,
section.section-container.background-2 span.link.type-3.color-1::after,
section.section-container.background-3 span.link.type-3.color-1::after,
section.section-container.background-5 span.link.type-3.color-1::after,
section.section-container.background-1 a.link.type-2.color-2::after,
section.section-container.background-2 a.link.type-2.color-2::after,
section.section-container.background-3 a.link.type-2.color-2::after,
section.section-container.background-5 a.link.type-2.color-2::after,
section.section-container.background-1 span.link.type-2.color-2::after,
section.section-container.background-2 span.link.type-2.color-2::after,
section.section-container.background-3 span.link.type-2.color-2::after,
section.section-container.background-5 span.link.type-2.color-2::after,
section.section-container.background-1 a.link.type-1.color-2::after,
section.section-container.background-2 a.link.type-1.color-2::after,
section.section-container.background-3 a.link.type-1.color-2::after,
section.section-container.background-5 a.link.type-1.color-2::after,
section.section-container.background-1 span.link.type-1.color-2::after,
section.section-container.background-2 span.link.type-1.color-2::after,
section.section-container.background-3 span.link.type-1.color-2::after,
section.section-container.background-5 span.link.type-1.color-2::after,
section.section-container.background-1 a.link.type-3.color-2::after,
section.section-container.background-2 a.link.type-3.color-2::after,
section.section-container.background-3 a.link.type-3.color-2::after,
section.section-container.background-5 a.link.type-3.color-2::after,
section.section-container.background-1 span.link.type-3.color-2::after,
section.section-container.background-2 span.link.type-3.color-2::after,
section.section-container.background-3 span.link.type-3.color-2::after,
section.section-container.background-5 span.link.type-3.color-2::after,
section.section-container.background-1 a.link.type-2.color-3::after,
section.section-container.background-2 a.link.type-2.color-3::after,
section.section-container.background-3 a.link.type-2.color-3::after,
section.section-container.background-5 a.link.type-2.color-3::after,
section.section-container.background-1 span.link.type-2.color-3::after,
section.section-container.background-2 span.link.type-2.color-3::after,
section.section-container.background-3 span.link.type-2.color-3::after,
section.section-container.background-5 span.link.type-2.color-3::after,
section.section-container.background-1 a.link.type-1.color-3::after,
section.section-container.background-2 a.link.type-1.color-3::after,
section.section-container.background-3 a.link.type-1.color-3::after,
section.section-container.background-5 a.link.type-1.color-3::after,
section.section-container.background-1 span.link.type-1.color-3::after,
section.section-container.background-2 span.link.type-1.color-3::after,
section.section-container.background-3 span.link.type-1.color-3::after,
section.section-container.background-5 span.link.type-1.color-3::after,
section.section-container.background-1 a.link.type-3.color-3::after,
section.section-container.background-2 a.link.type-3.color-3::after,
section.section-container.background-3 a.link.type-3.color-3::after,
section.section-container.background-5 a.link.type-3.color-3::after,
section.section-container.background-1 span.link.type-3.color-3::after,
section.section-container.background-2 span.link.type-3.color-3::after,
section.section-container.background-3 span.link.type-3.color-3::after,
section.section-container.background-5 span.link.type-3.color-3::after,
section.section-container.background-1 a.link.type-2.color-4::after,
section.section-container.background-2 a.link.type-2.color-4::after,
section.section-container.background-3 a.link.type-2.color-4::after,
section.section-container.background-5 a.link.type-2.color-4::after,
section.section-container.background-1 span.link.type-2.color-4::after,
section.section-container.background-2 span.link.type-2.color-4::after,
section.section-container.background-3 span.link.type-2.color-4::after,
section.section-container.background-5 span.link.type-2.color-4::after,
section.section-container.background-1 a.link.type-1.color-4::after,
section.section-container.background-2 a.link.type-1.color-4::after,
section.section-container.background-3 a.link.type-1.color-4::after,
section.section-container.background-5 a.link.type-1.color-4::after,
section.section-container.background-1 span.link.type-1.color-4::after,
section.section-container.background-2 span.link.type-1.color-4::after,
section.section-container.background-3 span.link.type-1.color-4::after,
section.section-container.background-5 span.link.type-1.color-4::after,
section.section-container.background-1 a.link.type-3.color-4::after,
section.section-container.background-2 a.link.type-3.color-4::after,
section.section-container.background-3 a.link.type-3.color-4::after,
section.section-container.background-5 a.link.type-3.color-4::after,
section.section-container.background-1 span.link.type-3.color-4::after,
section.section-container.background-2 span.link.type-3.color-4::after,
section.section-container.background-3 span.link.type-3.color-4::after,
section.section-container.background-5 span.link.type-3.color-4::after,
section.section-container.background-1 a.link.type-2.color-5::after,
section.section-container.background-2 a.link.type-2.color-5::after,
section.section-container.background-3 a.link.type-2.color-5::after,
section.section-container.background-5 a.link.type-2.color-5::after,
section.section-container.background-1 span.link.type-2.color-5::after,
section.section-container.background-2 span.link.type-2.color-5::after,
section.section-container.background-3 span.link.type-2.color-5::after,
section.section-container.background-5 span.link.type-2.color-5::after,
section.section-container.background-1 a.link.type-1.color-5::after,
section.section-container.background-2 a.link.type-1.color-5::after,
section.section-container.background-3 a.link.type-1.color-5::after,
section.section-container.background-5 a.link.type-1.color-5::after,
section.section-container.background-1 span.link.type-1.color-5::after,
section.section-container.background-2 span.link.type-1.color-5::after,
section.section-container.background-3 span.link.type-1.color-5::after,
section.section-container.background-5 span.link.type-1.color-5::after,
section.section-container.background-1 a.link.type-3.color-5::after,
section.section-container.background-2 a.link.type-3.color-5::after,
section.section-container.background-3 a.link.type-3.color-5::after,
section.section-container.background-5 a.link.type-3.color-5::after,
section.section-container.background-1 span.link.type-3.color-5::after,
section.section-container.background-2 span.link.type-3.color-5::after,
section.section-container.background-3 span.link.type-3.color-5::after,
section.section-container.background-5 span.link.type-3.color-5::after,
section.section-container.background-1 a.link.type-2.color-6::after,
section.section-container.background-2 a.link.type-2.color-6::after,
section.section-container.background-3 a.link.type-2.color-6::after,
section.section-container.background-5 a.link.type-2.color-6::after,
section.section-container.background-1 span.link.type-2.color-6::after,
section.section-container.background-2 span.link.type-2.color-6::after,
section.section-container.background-3 span.link.type-2.color-6::after,
section.section-container.background-5 span.link.type-2.color-6::after,
section.section-container.background-1 a.link.type-1.color-6::after,
section.section-container.background-2 a.link.type-1.color-6::after,
section.section-container.background-3 a.link.type-1.color-6::after,
section.section-container.background-5 a.link.type-1.color-6::after,
section.section-container.background-1 span.link.type-1.color-6::after,
section.section-container.background-2 span.link.type-1.color-6::after,
section.section-container.background-3 span.link.type-1.color-6::after,
section.section-container.background-5 span.link.type-1.color-6::after,
section.section-container.background-1 a.link.type-3.color-6::after,
section.section-container.background-2 a.link.type-3.color-6::after,
section.section-container.background-3 a.link.type-3.color-6::after,
section.section-container.background-5 a.link.type-3.color-6::after,
section.section-container.background-1 span.link.type-3.color-6::after,
section.section-container.background-2 span.link.type-3.color-6::after,
section.section-container.background-3 span.link.type-3.color-6::after,
section.section-container.background-5 span.link.type-3.color-6::after,
section.section-container.background-1 a.link.type-2.color-7::after,
section.section-container.background-2 a.link.type-2.color-7::after,
section.section-container.background-3 a.link.type-2.color-7::after,
section.section-container.background-5 a.link.type-2.color-7::after,
section.section-container.background-1 span.link.type-2.color-7::after,
section.section-container.background-2 span.link.type-2.color-7::after,
section.section-container.background-3 span.link.type-2.color-7::after,
section.section-container.background-5 span.link.type-2.color-7::after,
section.section-container.background-1 a.link.type-1.color-7::after,
section.section-container.background-2 a.link.type-1.color-7::after,
section.section-container.background-3 a.link.type-1.color-7::after,
section.section-container.background-5 a.link.type-1.color-7::after,
section.section-container.background-1 span.link.type-1.color-7::after,
section.section-container.background-2 span.link.type-1.color-7::after,
section.section-container.background-3 span.link.type-1.color-7::after,
section.section-container.background-5 span.link.type-1.color-7::after,
section.section-container.background-1 a.link.type-3.color-7::after,
section.section-container.background-2 a.link.type-3.color-7::after,
section.section-container.background-3 a.link.type-3.color-7::after,
section.section-container.background-5 a.link.type-3.color-7::after,
section.section-container.background-1 span.link.type-3.color-7::after,
section.section-container.background-2 span.link.type-3.color-7::after,
section.section-container.background-3 span.link.type-3.color-7::after,
section.section-container.background-5 span.link.type-3.color-7::after,
section.section-container.background-1 a.link.type-2.color-8::after,
section.section-container.background-2 a.link.type-2.color-8::after,
section.section-container.background-3 a.link.type-2.color-8::after,
section.section-container.background-5 a.link.type-2.color-8::after,
section.section-container.background-1 span.link.type-2.color-8::after,
section.section-container.background-2 span.link.type-2.color-8::after,
section.section-container.background-3 span.link.type-2.color-8::after,
section.section-container.background-5 span.link.type-2.color-8::after,
section.section-container.background-1 a.link.type-1.color-8::after,
section.section-container.background-2 a.link.type-1.color-8::after,
section.section-container.background-3 a.link.type-1.color-8::after,
section.section-container.background-5 a.link.type-1.color-8::after,
section.section-container.background-1 span.link.type-1.color-8::after,
section.section-container.background-2 span.link.type-1.color-8::after,
section.section-container.background-3 span.link.type-1.color-8::after,
section.section-container.background-5 span.link.type-1.color-8::after,
section.section-container.background-1 a.link.type-3.color-8::after,
section.section-container.background-2 a.link.type-3.color-8::after,
section.section-container.background-3 a.link.type-3.color-8::after,
section.section-container.background-5 a.link.type-3.color-8::after,
section.section-container.background-1 span.link.type-3.color-8::after,
section.section-container.background-2 span.link.type-3.color-8::after,
section.section-container.background-3 span.link.type-3.color-8::after,
section.section-container.background-5 span.link.type-3.color-8::after,
section.section-container.background-1 a.link.type-2.color-9::after,
section.section-container.background-2 a.link.type-2.color-9::after,
section.section-container.background-3 a.link.type-2.color-9::after,
section.section-container.background-5 a.link.type-2.color-9::after,
section.section-container.background-1 span.link.type-2.color-9::after,
section.section-container.background-2 span.link.type-2.color-9::after,
section.section-container.background-3 span.link.type-2.color-9::after,
section.section-container.background-5 span.link.type-2.color-9::after,
section.section-container.background-1 a.link.type-1.color-9::after,
section.section-container.background-2 a.link.type-1.color-9::after,
section.section-container.background-3 a.link.type-1.color-9::after,
section.section-container.background-5 a.link.type-1.color-9::after,
section.section-container.background-1 span.link.type-1.color-9::after,
section.section-container.background-2 span.link.type-1.color-9::after,
section.section-container.background-3 span.link.type-1.color-9::after,
section.section-container.background-5 span.link.type-1.color-9::after,
section.section-container.background-1 a.link.type-3.color-9::after,
section.section-container.background-2 a.link.type-3.color-9::after,
section.section-container.background-3 a.link.type-3.color-9::after,
section.section-container.background-5 a.link.type-3.color-9::after,
section.section-container.background-1 span.link.type-3.color-9::after,
section.section-container.background-2 span.link.type-3.color-9::after,
section.section-container.background-3 span.link.type-3.color-9::after,
section.section-container.background-5 span.link.type-3.color-9::after,
section.section-container.background-1 a.link.type-2.color-10::after,
section.section-container.background-2 a.link.type-2.color-10::after,
section.section-container.background-3 a.link.type-2.color-10::after,
section.section-container.background-5 a.link.type-2.color-10::after,
section.section-container.background-1 span.link.type-2.color-10::after,
section.section-container.background-2 span.link.type-2.color-10::after,
section.section-container.background-3 span.link.type-2.color-10::after,
section.section-container.background-5 span.link.type-2.color-10::after,
section.section-container.background-1 a.link.type-1.color-10::after,
section.section-container.background-2 a.link.type-1.color-10::after,
section.section-container.background-3 a.link.type-1.color-10::after,
section.section-container.background-5 a.link.type-1.color-10::after,
section.section-container.background-1 span.link.type-1.color-10::after,
section.section-container.background-2 span.link.type-1.color-10::after,
section.section-container.background-3 span.link.type-1.color-10::after,
section.section-container.background-5 span.link.type-1.color-10::after,
section.section-container.background-1 a.link.type-3.color-10::after,
section.section-container.background-2 a.link.type-3.color-10::after,
section.section-container.background-3 a.link.type-3.color-10::after,
section.section-container.background-5 a.link.type-3.color-10::after,
section.section-container.background-1 span.link.type-3.color-10::after,
section.section-container.background-2 span.link.type-3.color-10::after,
section.section-container.background-3 span.link.type-3.color-10::after,
section.section-container.background-5 span.link.type-3.color-10::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ffffff' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-1 a.link.type-4.color-1,
section.section-container.background-2 a.link.type-4.color-1,
section.section-container.background-3 a.link.type-4.color-1,
section.section-container.background-5 a.link.type-4.color-1,
section.section-container.background-1 span.link.type-4.color-1,
section.section-container.background-2 span.link.type-4.color-1,
section.section-container.background-3 span.link.type-4.color-1,
section.section-container.background-5 span.link.type-4.color-1,
section.section-container.background-1 a.link.type-4.color-2,
section.section-container.background-2 a.link.type-4.color-2,
section.section-container.background-3 a.link.type-4.color-2,
section.section-container.background-5 a.link.type-4.color-2,
section.section-container.background-1 span.link.type-4.color-2,
section.section-container.background-2 span.link.type-4.color-2,
section.section-container.background-3 span.link.type-4.color-2,
section.section-container.background-5 span.link.type-4.color-2,
section.section-container.background-1 a.link.type-4.color-3,
section.section-container.background-2 a.link.type-4.color-3,
section.section-container.background-3 a.link.type-4.color-3,
section.section-container.background-5 a.link.type-4.color-3,
section.section-container.background-1 span.link.type-4.color-3,
section.section-container.background-2 span.link.type-4.color-3,
section.section-container.background-3 span.link.type-4.color-3,
section.section-container.background-5 span.link.type-4.color-3,
section.section-container.background-1 a.link.type-4.color-4,
section.section-container.background-2 a.link.type-4.color-4,
section.section-container.background-3 a.link.type-4.color-4,
section.section-container.background-5 a.link.type-4.color-4,
section.section-container.background-1 span.link.type-4.color-4,
section.section-container.background-2 span.link.type-4.color-4,
section.section-container.background-3 span.link.type-4.color-4,
section.section-container.background-5 span.link.type-4.color-4,
section.section-container.background-1 a.link.type-4.color-5,
section.section-container.background-2 a.link.type-4.color-5,
section.section-container.background-3 a.link.type-4.color-5,
section.section-container.background-5 a.link.type-4.color-5,
section.section-container.background-1 span.link.type-4.color-5,
section.section-container.background-2 span.link.type-4.color-5,
section.section-container.background-3 span.link.type-4.color-5,
section.section-container.background-5 span.link.type-4.color-5,
section.section-container.background-1 a.link.type-4.color-6,
section.section-container.background-2 a.link.type-4.color-6,
section.section-container.background-3 a.link.type-4.color-6,
section.section-container.background-5 a.link.type-4.color-6,
section.section-container.background-1 span.link.type-4.color-6,
section.section-container.background-2 span.link.type-4.color-6,
section.section-container.background-3 span.link.type-4.color-6,
section.section-container.background-5 span.link.type-4.color-6,
section.section-container.background-1 a.link.type-4.color-7,
section.section-container.background-2 a.link.type-4.color-7,
section.section-container.background-3 a.link.type-4.color-7,
section.section-container.background-5 a.link.type-4.color-7,
section.section-container.background-1 span.link.type-4.color-7,
section.section-container.background-2 span.link.type-4.color-7,
section.section-container.background-3 span.link.type-4.color-7,
section.section-container.background-5 span.link.type-4.color-7,
section.section-container.background-1 a.link.type-4.color-8,
section.section-container.background-2 a.link.type-4.color-8,
section.section-container.background-3 a.link.type-4.color-8,
section.section-container.background-5 a.link.type-4.color-8,
section.section-container.background-1 span.link.type-4.color-8,
section.section-container.background-2 span.link.type-4.color-8,
section.section-container.background-3 span.link.type-4.color-8,
section.section-container.background-5 span.link.type-4.color-8,
section.section-container.background-1 a.link.type-4.color-9,
section.section-container.background-2 a.link.type-4.color-9,
section.section-container.background-3 a.link.type-4.color-9,
section.section-container.background-5 a.link.type-4.color-9,
section.section-container.background-1 span.link.type-4.color-9,
section.section-container.background-2 span.link.type-4.color-9,
section.section-container.background-3 span.link.type-4.color-9,
section.section-container.background-5 span.link.type-4.color-9,
section.section-container.background-1 a.link.type-4.color-10,
section.section-container.background-2 a.link.type-4.color-10,
section.section-container.background-3 a.link.type-4.color-10,
section.section-container.background-5 a.link.type-4.color-10,
section.section-container.background-1 span.link.type-4.color-10,
section.section-container.background-2 span.link.type-4.color-10,
section.section-container.background-3 span.link.type-4.color-10,
section.section-container.background-5 span.link.type-4.color-10 {
    color: #ffffff;
}

section.section-container.background-1 a.link.type-4.color-1::before,
section.section-container.background-2 a.link.type-4.color-1::before,
section.section-container.background-3 a.link.type-4.color-1::before,
section.section-container.background-5 a.link.type-4.color-1::before,
section.section-container.background-1 span.link.type-4.color-1::before,
section.section-container.background-2 span.link.type-4.color-1::before,
section.section-container.background-3 span.link.type-4.color-1::before,
section.section-container.background-5 span.link.type-4.color-1::before,
section.section-container.background-1 a.link.type-4.color-2::before,
section.section-container.background-2 a.link.type-4.color-2::before,
section.section-container.background-3 a.link.type-4.color-2::before,
section.section-container.background-5 a.link.type-4.color-2::before,
section.section-container.background-1 span.link.type-4.color-2::before,
section.section-container.background-2 span.link.type-4.color-2::before,
section.section-container.background-3 span.link.type-4.color-2::before,
section.section-container.background-5 span.link.type-4.color-2::before,
section.section-container.background-1 a.link.type-4.color-3::before,
section.section-container.background-2 a.link.type-4.color-3::before,
section.section-container.background-3 a.link.type-4.color-3::before,
section.section-container.background-5 a.link.type-4.color-3::before,
section.section-container.background-1 span.link.type-4.color-3::before,
section.section-container.background-2 span.link.type-4.color-3::before,
section.section-container.background-3 span.link.type-4.color-3::before,
section.section-container.background-5 span.link.type-4.color-3::before,
section.section-container.background-1 a.link.type-4.color-4::before,
section.section-container.background-2 a.link.type-4.color-4::before,
section.section-container.background-3 a.link.type-4.color-4::before,
section.section-container.background-5 a.link.type-4.color-4::before,
section.section-container.background-1 span.link.type-4.color-4::before,
section.section-container.background-2 span.link.type-4.color-4::before,
section.section-container.background-3 span.link.type-4.color-4::before,
section.section-container.background-5 span.link.type-4.color-4::before,
section.section-container.background-1 a.link.type-4.color-5::before,
section.section-container.background-2 a.link.type-4.color-5::before,
section.section-container.background-3 a.link.type-4.color-5::before,
section.section-container.background-5 a.link.type-4.color-5::before,
section.section-container.background-1 span.link.type-4.color-5::before,
section.section-container.background-2 span.link.type-4.color-5::before,
section.section-container.background-3 span.link.type-4.color-5::before,
section.section-container.background-5 span.link.type-4.color-5::before,
section.section-container.background-1 a.link.type-4.color-6::before,
section.section-container.background-2 a.link.type-4.color-6::before,
section.section-container.background-3 a.link.type-4.color-6::before,
section.section-container.background-5 a.link.type-4.color-6::before,
section.section-container.background-1 span.link.type-4.color-6::before,
section.section-container.background-2 span.link.type-4.color-6::before,
section.section-container.background-3 span.link.type-4.color-6::before,
section.section-container.background-5 span.link.type-4.color-6::before,
section.section-container.background-1 a.link.type-4.color-7::before,
section.section-container.background-2 a.link.type-4.color-7::before,
section.section-container.background-3 a.link.type-4.color-7::before,
section.section-container.background-5 a.link.type-4.color-7::before,
section.section-container.background-1 span.link.type-4.color-7::before,
section.section-container.background-2 span.link.type-4.color-7::before,
section.section-container.background-3 span.link.type-4.color-7::before,
section.section-container.background-5 span.link.type-4.color-7::before,
section.section-container.background-1 a.link.type-4.color-8::before,
section.section-container.background-2 a.link.type-4.color-8::before,
section.section-container.background-3 a.link.type-4.color-8::before,
section.section-container.background-5 a.link.type-4.color-8::before,
section.section-container.background-1 span.link.type-4.color-8::before,
section.section-container.background-2 span.link.type-4.color-8::before,
section.section-container.background-3 span.link.type-4.color-8::before,
section.section-container.background-5 span.link.type-4.color-8::before,
section.section-container.background-1 a.link.type-4.color-9::before,
section.section-container.background-2 a.link.type-4.color-9::before,
section.section-container.background-3 a.link.type-4.color-9::before,
section.section-container.background-5 a.link.type-4.color-9::before,
section.section-container.background-1 span.link.type-4.color-9::before,
section.section-container.background-2 span.link.type-4.color-9::before,
section.section-container.background-3 span.link.type-4.color-9::before,
section.section-container.background-5 span.link.type-4.color-9::before,
section.section-container.background-1 a.link.type-4.color-10::before,
section.section-container.background-2 a.link.type-4.color-10::before,
section.section-container.background-3 a.link.type-4.color-10::before,
section.section-container.background-5 a.link.type-4.color-10::before,
section.section-container.background-1 span.link.type-4.color-10::before,
section.section-container.background-2 span.link.type-4.color-10::before,
section.section-container.background-3 span.link.type-4.color-10::before,
section.section-container.background-5 span.link.type-4.color-10::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ffffff' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-1 a.link.icon.icon-download,
section.section-container.background-2 a.link.icon.icon-download,
section.section-container.background-3 a.link.icon.icon-download,
section.section-container.background-5 a.link.icon.icon-download,
section.section-container.background-1 span.link.icon.icon-download,
section.section-container.background-2 span.link.icon.icon-download,
section.section-container.background-3 span.link.icon.icon-download,
section.section-container.background-5 span.link.icon.icon-download {
    color: #ffffff;
}

section.section-container.background-1 a.link.icon.icon-download::before,
section.section-container.background-2 a.link.icon.icon-download::before,
section.section-container.background-3 a.link.icon.icon-download::before,
section.section-container.background-5 a.link.icon.icon-download::before,
section.section-container.background-1 span.link.icon.icon-download::before,
section.section-container.background-2 span.link.icon.icon-download::before,
section.section-container.background-3 span.link.icon.icon-download::before,
section.section-container.background-5 span.link.icon.icon-download::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='19' viewBox='0 0 20 19'%3E %3Cg fill='none' fill-rule='evenodd' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E %3Cpath d='M19 14v4H1v-4M10.418 1v12.065'/%3E %3Cpath d='M6 10l4 4 4-4'/%3E %3C/g%3E %3C/svg%3E ");
}

section.section-container.background-1 button.type-1.color-1,
section.section-container.background-2 button.type-1.color-1,
section.section-container.background-3 button.type-1.color-1,
section.section-container.background-5 button.type-1.color-1,
section.section-container.background-1 input[type=submit].type-1.color-1,
section.section-container.background-2 input[type=submit].type-1.color-1,
section.section-container.background-3 input[type=submit].type-1.color-1,
section.section-container.background-5 input[type=submit].type-1.color-1,
section.section-container.background-1 a.button.type-1.color-1,
section.section-container.background-2 a.button.type-1.color-1,
section.section-container.background-3 a.button.type-1.color-1,
section.section-container.background-5 a.button.type-1.color-1,
section.section-container.background-1 span.button.type-1.color-1,
section.section-container.background-2 span.button.type-1.color-1,
section.section-container.background-3 span.button.type-1.color-1,
section.section-container.background-5 span.button.type-1.color-1,
section.section-container.background-1 button.type-1.color-2,
section.section-container.background-2 button.type-1.color-2,
section.section-container.background-3 button.type-1.color-2,
section.section-container.background-5 button.type-1.color-2,
section.section-container.background-1 input[type=submit].type-1.color-2,
section.section-container.background-2 input[type=submit].type-1.color-2,
section.section-container.background-3 input[type=submit].type-1.color-2,
section.section-container.background-5 input[type=submit].type-1.color-2,
section.section-container.background-1 a.button.type-1.color-2,
section.section-container.background-2 a.button.type-1.color-2,
section.section-container.background-3 a.button.type-1.color-2,
section.section-container.background-5 a.button.type-1.color-2,
section.section-container.background-1 span.button.type-1.color-2,
section.section-container.background-2 span.button.type-1.color-2,
section.section-container.background-3 span.button.type-1.color-2,
section.section-container.background-5 span.button.type-1.color-2,
section.section-container.background-1 button.type-1.color-3,
section.section-container.background-2 button.type-1.color-3,
section.section-container.background-3 button.type-1.color-3,
section.section-container.background-5 button.type-1.color-3,
section.section-container.background-1 input[type=submit].type-1.color-3,
section.section-container.background-2 input[type=submit].type-1.color-3,
section.section-container.background-3 input[type=submit].type-1.color-3,
section.section-container.background-5 input[type=submit].type-1.color-3,
section.section-container.background-1 a.button.type-1.color-3,
section.section-container.background-2 a.button.type-1.color-3,
section.section-container.background-3 a.button.type-1.color-3,
section.section-container.background-5 a.button.type-1.color-3,
section.section-container.background-1 span.button.type-1.color-3,
section.section-container.background-2 span.button.type-1.color-3,
section.section-container.background-3 span.button.type-1.color-3,
section.section-container.background-5 span.button.type-1.color-3,
section.section-container.background-1 button.type-1.color-4,
section.section-container.background-2 button.type-1.color-4,
section.section-container.background-3 button.type-1.color-4,
section.section-container.background-5 button.type-1.color-4,
section.section-container.background-1 input[type=submit].type-1.color-4,
section.section-container.background-2 input[type=submit].type-1.color-4,
section.section-container.background-3 input[type=submit].type-1.color-4,
section.section-container.background-5 input[type=submit].type-1.color-4,
section.section-container.background-1 a.button.type-1.color-4,
section.section-container.background-2 a.button.type-1.color-4,
section.section-container.background-3 a.button.type-1.color-4,
section.section-container.background-5 a.button.type-1.color-4,
section.section-container.background-1 span.button.type-1.color-4,
section.section-container.background-2 span.button.type-1.color-4,
section.section-container.background-3 span.button.type-1.color-4,
section.section-container.background-5 span.button.type-1.color-4,
section.section-container.background-1 button.type-1.color-5,
section.section-container.background-2 button.type-1.color-5,
section.section-container.background-3 button.type-1.color-5,
section.section-container.background-5 button.type-1.color-5,
section.section-container.background-1 input[type=submit].type-1.color-5,
section.section-container.background-2 input[type=submit].type-1.color-5,
section.section-container.background-3 input[type=submit].type-1.color-5,
section.section-container.background-5 input[type=submit].type-1.color-5,
section.section-container.background-1 a.button.type-1.color-5,
section.section-container.background-2 a.button.type-1.color-5,
section.section-container.background-3 a.button.type-1.color-5,
section.section-container.background-5 a.button.type-1.color-5,
section.section-container.background-1 span.button.type-1.color-5,
section.section-container.background-2 span.button.type-1.color-5,
section.section-container.background-3 span.button.type-1.color-5,
section.section-container.background-5 span.button.type-1.color-5,
section.section-container.background-1 button.type-1.color-6,
section.section-container.background-2 button.type-1.color-6,
section.section-container.background-3 button.type-1.color-6,
section.section-container.background-5 button.type-1.color-6,
section.section-container.background-1 input[type=submit].type-1.color-6,
section.section-container.background-2 input[type=submit].type-1.color-6,
section.section-container.background-3 input[type=submit].type-1.color-6,
section.section-container.background-5 input[type=submit].type-1.color-6,
section.section-container.background-1 a.button.type-1.color-6,
section.section-container.background-2 a.button.type-1.color-6,
section.section-container.background-3 a.button.type-1.color-6,
section.section-container.background-5 a.button.type-1.color-6,
section.section-container.background-1 span.button.type-1.color-6,
section.section-container.background-2 span.button.type-1.color-6,
section.section-container.background-3 span.button.type-1.color-6,
section.section-container.background-5 span.button.type-1.color-6,
section.section-container.background-1 button.type-1.color-7,
section.section-container.background-2 button.type-1.color-7,
section.section-container.background-3 button.type-1.color-7,
section.section-container.background-5 button.type-1.color-7,
section.section-container.background-1 input[type=submit].type-1.color-7,
section.section-container.background-2 input[type=submit].type-1.color-7,
section.section-container.background-3 input[type=submit].type-1.color-7,
section.section-container.background-5 input[type=submit].type-1.color-7,
section.section-container.background-1 a.button.type-1.color-7,
section.section-container.background-2 a.button.type-1.color-7,
section.section-container.background-3 a.button.type-1.color-7,
section.section-container.background-5 a.button.type-1.color-7,
section.section-container.background-1 span.button.type-1.color-7,
section.section-container.background-2 span.button.type-1.color-7,
section.section-container.background-3 span.button.type-1.color-7,
section.section-container.background-5 span.button.type-1.color-7,
section.section-container.background-1 button.type-1.color-8,
section.section-container.background-2 button.type-1.color-8,
section.section-container.background-3 button.type-1.color-8,
section.section-container.background-5 button.type-1.color-8,
section.section-container.background-1 input[type=submit].type-1.color-8,
section.section-container.background-2 input[type=submit].type-1.color-8,
section.section-container.background-3 input[type=submit].type-1.color-8,
section.section-container.background-5 input[type=submit].type-1.color-8,
section.section-container.background-1 a.button.type-1.color-8,
section.section-container.background-2 a.button.type-1.color-8,
section.section-container.background-3 a.button.type-1.color-8,
section.section-container.background-5 a.button.type-1.color-8,
section.section-container.background-1 span.button.type-1.color-8,
section.section-container.background-2 span.button.type-1.color-8,
section.section-container.background-3 span.button.type-1.color-8,
section.section-container.background-5 span.button.type-1.color-8,
section.section-container.background-1 button.type-1.color-9,
section.section-container.background-2 button.type-1.color-9,
section.section-container.background-3 button.type-1.color-9,
section.section-container.background-5 button.type-1.color-9,
section.section-container.background-1 input[type=submit].type-1.color-9,
section.section-container.background-2 input[type=submit].type-1.color-9,
section.section-container.background-3 input[type=submit].type-1.color-9,
section.section-container.background-5 input[type=submit].type-1.color-9,
section.section-container.background-1 a.button.type-1.color-9,
section.section-container.background-2 a.button.type-1.color-9,
section.section-container.background-3 a.button.type-1.color-9,
section.section-container.background-5 a.button.type-1.color-9,
section.section-container.background-1 span.button.type-1.color-9,
section.section-container.background-2 span.button.type-1.color-9,
section.section-container.background-3 span.button.type-1.color-9,
section.section-container.background-5 span.button.type-1.color-9,
section.section-container.background-1 button.type-1.color-10,
section.section-container.background-2 button.type-1.color-10,
section.section-container.background-3 button.type-1.color-10,
section.section-container.background-5 button.type-1.color-10,
section.section-container.background-1 input[type=submit].type-1.color-10,
section.section-container.background-2 input[type=submit].type-1.color-10,
section.section-container.background-3 input[type=submit].type-1.color-10,
section.section-container.background-5 input[type=submit].type-1.color-10,
section.section-container.background-1 a.button.type-1.color-10,
section.section-container.background-2 a.button.type-1.color-10,
section.section-container.background-3 a.button.type-1.color-10,
section.section-container.background-5 a.button.type-1.color-10,
section.section-container.background-1 span.button.type-1.color-10,
section.section-container.background-2 span.button.type-1.color-10,
section.section-container.background-3 span.button.type-1.color-10,
section.section-container.background-5 span.button.type-1.color-10 {
    background: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
}

section.section-container.background-1 button.type-1::after,
section.section-container.background-2 button.type-1::after,
section.section-container.background-3 button.type-1::after,
section.section-container.background-5 button.type-1::after,
section.section-container.background-1 input[type=submit].type-1::after,
section.section-container.background-2 input[type=submit].type-1::after,
section.section-container.background-3 input[type=submit].type-1::after,
section.section-container.background-5 input[type=submit].type-1::after,
section.section-container.background-1 a.button.type-1::after,
section.section-container.background-2 a.button.type-1::after,
section.section-container.background-3 a.button.type-1::after,
section.section-container.background-5 a.button.type-1::after,
section.section-container.background-1 span.button.type-1::after,
section.section-container.background-2 span.button.type-1::after,
section.section-container.background-3 span.button.type-1::after,
section.section-container.background-5 span.button.type-1::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ffffff' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-1 button.type-2.color-1,
section.section-container.background-2 button.type-2.color-1,
section.section-container.background-3 button.type-2.color-1,
section.section-container.background-5 button.type-2.color-1,
section.section-container.background-1 input[type=submit].type-2.color-1,
section.section-container.background-2 input[type=submit].type-2.color-1,
section.section-container.background-3 input[type=submit].type-2.color-1,
section.section-container.background-5 input[type=submit].type-2.color-1,
section.section-container.background-1 a.button.type-2.color-1,
section.section-container.background-2 a.button.type-2.color-1,
section.section-container.background-3 a.button.type-2.color-1,
section.section-container.background-5 a.button.type-2.color-1,
section.section-container.background-1 span.button.type-2.color-1,
section.section-container.background-2 span.button.type-2.color-1,
section.section-container.background-3 span.button.type-2.color-1,
section.section-container.background-5 span.button.type-2.color-1,
section.section-container.background-1 button.type-3.color-1,
section.section-container.background-2 button.type-3.color-1,
section.section-container.background-3 button.type-3.color-1,
section.section-container.background-5 button.type-3.color-1,
section.section-container.background-1 input[type=submit].type-3.color-1,
section.section-container.background-2 input[type=submit].type-3.color-1,
section.section-container.background-3 input[type=submit].type-3.color-1,
section.section-container.background-5 input[type=submit].type-3.color-1,
section.section-container.background-1 a.button.type-3.color-1,
section.section-container.background-2 a.button.type-3.color-1,
section.section-container.background-3 a.button.type-3.color-1,
section.section-container.background-5 a.button.type-3.color-1,
section.section-container.background-1 span.button.type-3.color-1,
section.section-container.background-2 span.button.type-3.color-1,
section.section-container.background-3 span.button.type-3.color-1,
section.section-container.background-5 span.button.type-3.color-1,
section.section-container.background-1 button.type-4.color-1,
section.section-container.background-2 button.type-4.color-1,
section.section-container.background-3 button.type-4.color-1,
section.section-container.background-5 button.type-4.color-1,
section.section-container.background-1 input[type=submit].type-4.color-1,
section.section-container.background-2 input[type=submit].type-4.color-1,
section.section-container.background-3 input[type=submit].type-4.color-1,
section.section-container.background-5 input[type=submit].type-4.color-1,
section.section-container.background-1 a.button.type-4.color-1,
section.section-container.background-2 a.button.type-4.color-1,
section.section-container.background-3 a.button.type-4.color-1,
section.section-container.background-5 a.button.type-4.color-1,
section.section-container.background-1 span.button.type-4.color-1,
section.section-container.background-2 span.button.type-4.color-1,
section.section-container.background-3 span.button.type-4.color-1,
section.section-container.background-5 span.button.type-4.color-1,
section.section-container.background-1 button.type-5.color-1,
section.section-container.background-2 button.type-5.color-1,
section.section-container.background-3 button.type-5.color-1,
section.section-container.background-5 button.type-5.color-1,
section.section-container.background-1 input[type=submit].type-5.color-1,
section.section-container.background-2 input[type=submit].type-5.color-1,
section.section-container.background-3 input[type=submit].type-5.color-1,
section.section-container.background-5 input[type=submit].type-5.color-1,
section.section-container.background-1 a.button.type-5.color-1,
section.section-container.background-2 a.button.type-5.color-1,
section.section-container.background-3 a.button.type-5.color-1,
section.section-container.background-5 a.button.type-5.color-1,
section.section-container.background-1 span.button.type-5.color-1,
section.section-container.background-2 span.button.type-5.color-1,
section.section-container.background-3 span.button.type-5.color-1,
section.section-container.background-5 span.button.type-5.color-1,
section.section-container.background-1 button.type-2.color-2,
section.section-container.background-2 button.type-2.color-2,
section.section-container.background-3 button.type-2.color-2,
section.section-container.background-5 button.type-2.color-2,
section.section-container.background-1 input[type=submit].type-2.color-2,
section.section-container.background-2 input[type=submit].type-2.color-2,
section.section-container.background-3 input[type=submit].type-2.color-2,
section.section-container.background-5 input[type=submit].type-2.color-2,
section.section-container.background-1 a.button.type-2.color-2,
section.section-container.background-2 a.button.type-2.color-2,
section.section-container.background-3 a.button.type-2.color-2,
section.section-container.background-5 a.button.type-2.color-2,
section.section-container.background-1 span.button.type-2.color-2,
section.section-container.background-2 span.button.type-2.color-2,
section.section-container.background-3 span.button.type-2.color-2,
section.section-container.background-5 span.button.type-2.color-2,
section.section-container.background-1 button.type-3.color-2,
section.section-container.background-2 button.type-3.color-2,
section.section-container.background-3 button.type-3.color-2,
section.section-container.background-5 button.type-3.color-2,
section.section-container.background-1 input[type=submit].type-3.color-2,
section.section-container.background-2 input[type=submit].type-3.color-2,
section.section-container.background-3 input[type=submit].type-3.color-2,
section.section-container.background-5 input[type=submit].type-3.color-2,
section.section-container.background-1 a.button.type-3.color-2,
section.section-container.background-2 a.button.type-3.color-2,
section.section-container.background-3 a.button.type-3.color-2,
section.section-container.background-5 a.button.type-3.color-2,
section.section-container.background-1 span.button.type-3.color-2,
section.section-container.background-2 span.button.type-3.color-2,
section.section-container.background-3 span.button.type-3.color-2,
section.section-container.background-5 span.button.type-3.color-2,
section.section-container.background-1 button.type-4.color-2,
section.section-container.background-2 button.type-4.color-2,
section.section-container.background-3 button.type-4.color-2,
section.section-container.background-5 button.type-4.color-2,
section.section-container.background-1 input[type=submit].type-4.color-2,
section.section-container.background-2 input[type=submit].type-4.color-2,
section.section-container.background-3 input[type=submit].type-4.color-2,
section.section-container.background-5 input[type=submit].type-4.color-2,
section.section-container.background-1 a.button.type-4.color-2,
section.section-container.background-2 a.button.type-4.color-2,
section.section-container.background-3 a.button.type-4.color-2,
section.section-container.background-5 a.button.type-4.color-2,
section.section-container.background-1 span.button.type-4.color-2,
section.section-container.background-2 span.button.type-4.color-2,
section.section-container.background-3 span.button.type-4.color-2,
section.section-container.background-5 span.button.type-4.color-2,
section.section-container.background-1 button.type-5.color-2,
section.section-container.background-2 button.type-5.color-2,
section.section-container.background-3 button.type-5.color-2,
section.section-container.background-5 button.type-5.color-2,
section.section-container.background-1 input[type=submit].type-5.color-2,
section.section-container.background-2 input[type=submit].type-5.color-2,
section.section-container.background-3 input[type=submit].type-5.color-2,
section.section-container.background-5 input[type=submit].type-5.color-2,
section.section-container.background-1 a.button.type-5.color-2,
section.section-container.background-2 a.button.type-5.color-2,
section.section-container.background-3 a.button.type-5.color-2,
section.section-container.background-5 a.button.type-5.color-2,
section.section-container.background-1 span.button.type-5.color-2,
section.section-container.background-2 span.button.type-5.color-2,
section.section-container.background-3 span.button.type-5.color-2,
section.section-container.background-5 span.button.type-5.color-2,
section.section-container.background-1 button.type-2.color-3,
section.section-container.background-2 button.type-2.color-3,
section.section-container.background-3 button.type-2.color-3,
section.section-container.background-5 button.type-2.color-3,
section.section-container.background-1 input[type=submit].type-2.color-3,
section.section-container.background-2 input[type=submit].type-2.color-3,
section.section-container.background-3 input[type=submit].type-2.color-3,
section.section-container.background-5 input[type=submit].type-2.color-3,
section.section-container.background-1 a.button.type-2.color-3,
section.section-container.background-2 a.button.type-2.color-3,
section.section-container.background-3 a.button.type-2.color-3,
section.section-container.background-5 a.button.type-2.color-3,
section.section-container.background-1 span.button.type-2.color-3,
section.section-container.background-2 span.button.type-2.color-3,
section.section-container.background-3 span.button.type-2.color-3,
section.section-container.background-5 span.button.type-2.color-3,
section.section-container.background-1 button.type-3.color-3,
section.section-container.background-2 button.type-3.color-3,
section.section-container.background-3 button.type-3.color-3,
section.section-container.background-5 button.type-3.color-3,
section.section-container.background-1 input[type=submit].type-3.color-3,
section.section-container.background-2 input[type=submit].type-3.color-3,
section.section-container.background-3 input[type=submit].type-3.color-3,
section.section-container.background-5 input[type=submit].type-3.color-3,
section.section-container.background-1 a.button.type-3.color-3,
section.section-container.background-2 a.button.type-3.color-3,
section.section-container.background-3 a.button.type-3.color-3,
section.section-container.background-5 a.button.type-3.color-3,
section.section-container.background-1 span.button.type-3.color-3,
section.section-container.background-2 span.button.type-3.color-3,
section.section-container.background-3 span.button.type-3.color-3,
section.section-container.background-5 span.button.type-3.color-3,
section.section-container.background-1 button.type-4.color-3,
section.section-container.background-2 button.type-4.color-3,
section.section-container.background-3 button.type-4.color-3,
section.section-container.background-5 button.type-4.color-3,
section.section-container.background-1 input[type=submit].type-4.color-3,
section.section-container.background-2 input[type=submit].type-4.color-3,
section.section-container.background-3 input[type=submit].type-4.color-3,
section.section-container.background-5 input[type=submit].type-4.color-3,
section.section-container.background-1 a.button.type-4.color-3,
section.section-container.background-2 a.button.type-4.color-3,
section.section-container.background-3 a.button.type-4.color-3,
section.section-container.background-5 a.button.type-4.color-3,
section.section-container.background-1 span.button.type-4.color-3,
section.section-container.background-2 span.button.type-4.color-3,
section.section-container.background-3 span.button.type-4.color-3,
section.section-container.background-5 span.button.type-4.color-3,
section.section-container.background-1 button.type-5.color-3,
section.section-container.background-2 button.type-5.color-3,
section.section-container.background-3 button.type-5.color-3,
section.section-container.background-5 button.type-5.color-3,
section.section-container.background-1 input[type=submit].type-5.color-3,
section.section-container.background-2 input[type=submit].type-5.color-3,
section.section-container.background-3 input[type=submit].type-5.color-3,
section.section-container.background-5 input[type=submit].type-5.color-3,
section.section-container.background-1 a.button.type-5.color-3,
section.section-container.background-2 a.button.type-5.color-3,
section.section-container.background-3 a.button.type-5.color-3,
section.section-container.background-5 a.button.type-5.color-3,
section.section-container.background-1 span.button.type-5.color-3,
section.section-container.background-2 span.button.type-5.color-3,
section.section-container.background-3 span.button.type-5.color-3,
section.section-container.background-5 span.button.type-5.color-3,
section.section-container.background-1 button.type-2.color-4,
section.section-container.background-2 button.type-2.color-4,
section.section-container.background-3 button.type-2.color-4,
section.section-container.background-5 button.type-2.color-4,
section.section-container.background-1 input[type=submit].type-2.color-4,
section.section-container.background-2 input[type=submit].type-2.color-4,
section.section-container.background-3 input[type=submit].type-2.color-4,
section.section-container.background-5 input[type=submit].type-2.color-4,
section.section-container.background-1 a.button.type-2.color-4,
section.section-container.background-2 a.button.type-2.color-4,
section.section-container.background-3 a.button.type-2.color-4,
section.section-container.background-5 a.button.type-2.color-4,
section.section-container.background-1 span.button.type-2.color-4,
section.section-container.background-2 span.button.type-2.color-4,
section.section-container.background-3 span.button.type-2.color-4,
section.section-container.background-5 span.button.type-2.color-4,
section.section-container.background-1 button.type-3.color-4,
section.section-container.background-2 button.type-3.color-4,
section.section-container.background-3 button.type-3.color-4,
section.section-container.background-5 button.type-3.color-4,
section.section-container.background-1 input[type=submit].type-3.color-4,
section.section-container.background-2 input[type=submit].type-3.color-4,
section.section-container.background-3 input[type=submit].type-3.color-4,
section.section-container.background-5 input[type=submit].type-3.color-4,
section.section-container.background-1 a.button.type-3.color-4,
section.section-container.background-2 a.button.type-3.color-4,
section.section-container.background-3 a.button.type-3.color-4,
section.section-container.background-5 a.button.type-3.color-4,
section.section-container.background-1 span.button.type-3.color-4,
section.section-container.background-2 span.button.type-3.color-4,
section.section-container.background-3 span.button.type-3.color-4,
section.section-container.background-5 span.button.type-3.color-4,
section.section-container.background-1 button.type-4.color-4,
section.section-container.background-2 button.type-4.color-4,
section.section-container.background-3 button.type-4.color-4,
section.section-container.background-5 button.type-4.color-4,
section.section-container.background-1 input[type=submit].type-4.color-4,
section.section-container.background-2 input[type=submit].type-4.color-4,
section.section-container.background-3 input[type=submit].type-4.color-4,
section.section-container.background-5 input[type=submit].type-4.color-4,
section.section-container.background-1 a.button.type-4.color-4,
section.section-container.background-2 a.button.type-4.color-4,
section.section-container.background-3 a.button.type-4.color-4,
section.section-container.background-5 a.button.type-4.color-4,
section.section-container.background-1 span.button.type-4.color-4,
section.section-container.background-2 span.button.type-4.color-4,
section.section-container.background-3 span.button.type-4.color-4,
section.section-container.background-5 span.button.type-4.color-4,
section.section-container.background-1 button.type-5.color-4,
section.section-container.background-2 button.type-5.color-4,
section.section-container.background-3 button.type-5.color-4,
section.section-container.background-5 button.type-5.color-4,
section.section-container.background-1 input[type=submit].type-5.color-4,
section.section-container.background-2 input[type=submit].type-5.color-4,
section.section-container.background-3 input[type=submit].type-5.color-4,
section.section-container.background-5 input[type=submit].type-5.color-4,
section.section-container.background-1 a.button.type-5.color-4,
section.section-container.background-2 a.button.type-5.color-4,
section.section-container.background-3 a.button.type-5.color-4,
section.section-container.background-5 a.button.type-5.color-4,
section.section-container.background-1 span.button.type-5.color-4,
section.section-container.background-2 span.button.type-5.color-4,
section.section-container.background-3 span.button.type-5.color-4,
section.section-container.background-5 span.button.type-5.color-4,
section.section-container.background-1 button.type-2.color-5,
section.section-container.background-2 button.type-2.color-5,
section.section-container.background-3 button.type-2.color-5,
section.section-container.background-5 button.type-2.color-5,
section.section-container.background-1 input[type=submit].type-2.color-5,
section.section-container.background-2 input[type=submit].type-2.color-5,
section.section-container.background-3 input[type=submit].type-2.color-5,
section.section-container.background-5 input[type=submit].type-2.color-5,
section.section-container.background-1 a.button.type-2.color-5,
section.section-container.background-2 a.button.type-2.color-5,
section.section-container.background-3 a.button.type-2.color-5,
section.section-container.background-5 a.button.type-2.color-5,
section.section-container.background-1 span.button.type-2.color-5,
section.section-container.background-2 span.button.type-2.color-5,
section.section-container.background-3 span.button.type-2.color-5,
section.section-container.background-5 span.button.type-2.color-5,
section.section-container.background-1 button.type-3.color-5,
section.section-container.background-2 button.type-3.color-5,
section.section-container.background-3 button.type-3.color-5,
section.section-container.background-5 button.type-3.color-5,
section.section-container.background-1 input[type=submit].type-3.color-5,
section.section-container.background-2 input[type=submit].type-3.color-5,
section.section-container.background-3 input[type=submit].type-3.color-5,
section.section-container.background-5 input[type=submit].type-3.color-5,
section.section-container.background-1 a.button.type-3.color-5,
section.section-container.background-2 a.button.type-3.color-5,
section.section-container.background-3 a.button.type-3.color-5,
section.section-container.background-5 a.button.type-3.color-5,
section.section-container.background-1 span.button.type-3.color-5,
section.section-container.background-2 span.button.type-3.color-5,
section.section-container.background-3 span.button.type-3.color-5,
section.section-container.background-5 span.button.type-3.color-5,
section.section-container.background-1 button.type-4.color-5,
section.section-container.background-2 button.type-4.color-5,
section.section-container.background-3 button.type-4.color-5,
section.section-container.background-5 button.type-4.color-5,
section.section-container.background-1 input[type=submit].type-4.color-5,
section.section-container.background-2 input[type=submit].type-4.color-5,
section.section-container.background-3 input[type=submit].type-4.color-5,
section.section-container.background-5 input[type=submit].type-4.color-5,
section.section-container.background-1 a.button.type-4.color-5,
section.section-container.background-2 a.button.type-4.color-5,
section.section-container.background-3 a.button.type-4.color-5,
section.section-container.background-5 a.button.type-4.color-5,
section.section-container.background-1 span.button.type-4.color-5,
section.section-container.background-2 span.button.type-4.color-5,
section.section-container.background-3 span.button.type-4.color-5,
section.section-container.background-5 span.button.type-4.color-5,
section.section-container.background-1 button.type-5.color-5,
section.section-container.background-2 button.type-5.color-5,
section.section-container.background-3 button.type-5.color-5,
section.section-container.background-5 button.type-5.color-5,
section.section-container.background-1 input[type=submit].type-5.color-5,
section.section-container.background-2 input[type=submit].type-5.color-5,
section.section-container.background-3 input[type=submit].type-5.color-5,
section.section-container.background-5 input[type=submit].type-5.color-5,
section.section-container.background-1 a.button.type-5.color-5,
section.section-container.background-2 a.button.type-5.color-5,
section.section-container.background-3 a.button.type-5.color-5,
section.section-container.background-5 a.button.type-5.color-5,
section.section-container.background-1 span.button.type-5.color-5,
section.section-container.background-2 span.button.type-5.color-5,
section.section-container.background-3 span.button.type-5.color-5,
section.section-container.background-5 span.button.type-5.color-5,
section.section-container.background-1 button.type-2.color-6,
section.section-container.background-2 button.type-2.color-6,
section.section-container.background-3 button.type-2.color-6,
section.section-container.background-5 button.type-2.color-6,
section.section-container.background-1 input[type=submit].type-2.color-6,
section.section-container.background-2 input[type=submit].type-2.color-6,
section.section-container.background-3 input[type=submit].type-2.color-6,
section.section-container.background-5 input[type=submit].type-2.color-6,
section.section-container.background-1 a.button.type-2.color-6,
section.section-container.background-2 a.button.type-2.color-6,
section.section-container.background-3 a.button.type-2.color-6,
section.section-container.background-5 a.button.type-2.color-6,
section.section-container.background-1 span.button.type-2.color-6,
section.section-container.background-2 span.button.type-2.color-6,
section.section-container.background-3 span.button.type-2.color-6,
section.section-container.background-5 span.button.type-2.color-6,
section.section-container.background-1 button.type-3.color-6,
section.section-container.background-2 button.type-3.color-6,
section.section-container.background-3 button.type-3.color-6,
section.section-container.background-5 button.type-3.color-6,
section.section-container.background-1 input[type=submit].type-3.color-6,
section.section-container.background-2 input[type=submit].type-3.color-6,
section.section-container.background-3 input[type=submit].type-3.color-6,
section.section-container.background-5 input[type=submit].type-3.color-6,
section.section-container.background-1 a.button.type-3.color-6,
section.section-container.background-2 a.button.type-3.color-6,
section.section-container.background-3 a.button.type-3.color-6,
section.section-container.background-5 a.button.type-3.color-6,
section.section-container.background-1 span.button.type-3.color-6,
section.section-container.background-2 span.button.type-3.color-6,
section.section-container.background-3 span.button.type-3.color-6,
section.section-container.background-5 span.button.type-3.color-6,
section.section-container.background-1 button.type-4.color-6,
section.section-container.background-2 button.type-4.color-6,
section.section-container.background-3 button.type-4.color-6,
section.section-container.background-5 button.type-4.color-6,
section.section-container.background-1 input[type=submit].type-4.color-6,
section.section-container.background-2 input[type=submit].type-4.color-6,
section.section-container.background-3 input[type=submit].type-4.color-6,
section.section-container.background-5 input[type=submit].type-4.color-6,
section.section-container.background-1 a.button.type-4.color-6,
section.section-container.background-2 a.button.type-4.color-6,
section.section-container.background-3 a.button.type-4.color-6,
section.section-container.background-5 a.button.type-4.color-6,
section.section-container.background-1 span.button.type-4.color-6,
section.section-container.background-2 span.button.type-4.color-6,
section.section-container.background-3 span.button.type-4.color-6,
section.section-container.background-5 span.button.type-4.color-6,
section.section-container.background-1 button.type-5.color-6,
section.section-container.background-2 button.type-5.color-6,
section.section-container.background-3 button.type-5.color-6,
section.section-container.background-5 button.type-5.color-6,
section.section-container.background-1 input[type=submit].type-5.color-6,
section.section-container.background-2 input[type=submit].type-5.color-6,
section.section-container.background-3 input[type=submit].type-5.color-6,
section.section-container.background-5 input[type=submit].type-5.color-6,
section.section-container.background-1 a.button.type-5.color-6,
section.section-container.background-2 a.button.type-5.color-6,
section.section-container.background-3 a.button.type-5.color-6,
section.section-container.background-5 a.button.type-5.color-6,
section.section-container.background-1 span.button.type-5.color-6,
section.section-container.background-2 span.button.type-5.color-6,
section.section-container.background-3 span.button.type-5.color-6,
section.section-container.background-5 span.button.type-5.color-6,
section.section-container.background-1 button.type-2.color-7,
section.section-container.background-2 button.type-2.color-7,
section.section-container.background-3 button.type-2.color-7,
section.section-container.background-5 button.type-2.color-7,
section.section-container.background-1 input[type=submit].type-2.color-7,
section.section-container.background-2 input[type=submit].type-2.color-7,
section.section-container.background-3 input[type=submit].type-2.color-7,
section.section-container.background-5 input[type=submit].type-2.color-7,
section.section-container.background-1 a.button.type-2.color-7,
section.section-container.background-2 a.button.type-2.color-7,
section.section-container.background-3 a.button.type-2.color-7,
section.section-container.background-5 a.button.type-2.color-7,
section.section-container.background-1 span.button.type-2.color-7,
section.section-container.background-2 span.button.type-2.color-7,
section.section-container.background-3 span.button.type-2.color-7,
section.section-container.background-5 span.button.type-2.color-7,
section.section-container.background-1 button.type-3.color-7,
section.section-container.background-2 button.type-3.color-7,
section.section-container.background-3 button.type-3.color-7,
section.section-container.background-5 button.type-3.color-7,
section.section-container.background-1 input[type=submit].type-3.color-7,
section.section-container.background-2 input[type=submit].type-3.color-7,
section.section-container.background-3 input[type=submit].type-3.color-7,
section.section-container.background-5 input[type=submit].type-3.color-7,
section.section-container.background-1 a.button.type-3.color-7,
section.section-container.background-2 a.button.type-3.color-7,
section.section-container.background-3 a.button.type-3.color-7,
section.section-container.background-5 a.button.type-3.color-7,
section.section-container.background-1 span.button.type-3.color-7,
section.section-container.background-2 span.button.type-3.color-7,
section.section-container.background-3 span.button.type-3.color-7,
section.section-container.background-5 span.button.type-3.color-7,
section.section-container.background-1 button.type-4.color-7,
section.section-container.background-2 button.type-4.color-7,
section.section-container.background-3 button.type-4.color-7,
section.section-container.background-5 button.type-4.color-7,
section.section-container.background-1 input[type=submit].type-4.color-7,
section.section-container.background-2 input[type=submit].type-4.color-7,
section.section-container.background-3 input[type=submit].type-4.color-7,
section.section-container.background-5 input[type=submit].type-4.color-7,
section.section-container.background-1 a.button.type-4.color-7,
section.section-container.background-2 a.button.type-4.color-7,
section.section-container.background-3 a.button.type-4.color-7,
section.section-container.background-5 a.button.type-4.color-7,
section.section-container.background-1 span.button.type-4.color-7,
section.section-container.background-2 span.button.type-4.color-7,
section.section-container.background-3 span.button.type-4.color-7,
section.section-container.background-5 span.button.type-4.color-7,
section.section-container.background-1 button.type-5.color-7,
section.section-container.background-2 button.type-5.color-7,
section.section-container.background-3 button.type-5.color-7,
section.section-container.background-5 button.type-5.color-7,
section.section-container.background-1 input[type=submit].type-5.color-7,
section.section-container.background-2 input[type=submit].type-5.color-7,
section.section-container.background-3 input[type=submit].type-5.color-7,
section.section-container.background-5 input[type=submit].type-5.color-7,
section.section-container.background-1 a.button.type-5.color-7,
section.section-container.background-2 a.button.type-5.color-7,
section.section-container.background-3 a.button.type-5.color-7,
section.section-container.background-5 a.button.type-5.color-7,
section.section-container.background-1 span.button.type-5.color-7,
section.section-container.background-2 span.button.type-5.color-7,
section.section-container.background-3 span.button.type-5.color-7,
section.section-container.background-5 span.button.type-5.color-7,
section.section-container.background-1 button.type-2.color-8,
section.section-container.background-2 button.type-2.color-8,
section.section-container.background-3 button.type-2.color-8,
section.section-container.background-5 button.type-2.color-8,
section.section-container.background-1 input[type=submit].type-2.color-8,
section.section-container.background-2 input[type=submit].type-2.color-8,
section.section-container.background-3 input[type=submit].type-2.color-8,
section.section-container.background-5 input[type=submit].type-2.color-8,
section.section-container.background-1 a.button.type-2.color-8,
section.section-container.background-2 a.button.type-2.color-8,
section.section-container.background-3 a.button.type-2.color-8,
section.section-container.background-5 a.button.type-2.color-8,
section.section-container.background-1 span.button.type-2.color-8,
section.section-container.background-2 span.button.type-2.color-8,
section.section-container.background-3 span.button.type-2.color-8,
section.section-container.background-5 span.button.type-2.color-8,
section.section-container.background-1 button.type-3.color-8,
section.section-container.background-2 button.type-3.color-8,
section.section-container.background-3 button.type-3.color-8,
section.section-container.background-5 button.type-3.color-8,
section.section-container.background-1 input[type=submit].type-3.color-8,
section.section-container.background-2 input[type=submit].type-3.color-8,
section.section-container.background-3 input[type=submit].type-3.color-8,
section.section-container.background-5 input[type=submit].type-3.color-8,
section.section-container.background-1 a.button.type-3.color-8,
section.section-container.background-2 a.button.type-3.color-8,
section.section-container.background-3 a.button.type-3.color-8,
section.section-container.background-5 a.button.type-3.color-8,
section.section-container.background-1 span.button.type-3.color-8,
section.section-container.background-2 span.button.type-3.color-8,
section.section-container.background-3 span.button.type-3.color-8,
section.section-container.background-5 span.button.type-3.color-8,
section.section-container.background-1 button.type-4.color-8,
section.section-container.background-2 button.type-4.color-8,
section.section-container.background-3 button.type-4.color-8,
section.section-container.background-5 button.type-4.color-8,
section.section-container.background-1 input[type=submit].type-4.color-8,
section.section-container.background-2 input[type=submit].type-4.color-8,
section.section-container.background-3 input[type=submit].type-4.color-8,
section.section-container.background-5 input[type=submit].type-4.color-8,
section.section-container.background-1 a.button.type-4.color-8,
section.section-container.background-2 a.button.type-4.color-8,
section.section-container.background-3 a.button.type-4.color-8,
section.section-container.background-5 a.button.type-4.color-8,
section.section-container.background-1 span.button.type-4.color-8,
section.section-container.background-2 span.button.type-4.color-8,
section.section-container.background-3 span.button.type-4.color-8,
section.section-container.background-5 span.button.type-4.color-8,
section.section-container.background-1 button.type-5.color-8,
section.section-container.background-2 button.type-5.color-8,
section.section-container.background-3 button.type-5.color-8,
section.section-container.background-5 button.type-5.color-8,
section.section-container.background-1 input[type=submit].type-5.color-8,
section.section-container.background-2 input[type=submit].type-5.color-8,
section.section-container.background-3 input[type=submit].type-5.color-8,
section.section-container.background-5 input[type=submit].type-5.color-8,
section.section-container.background-1 a.button.type-5.color-8,
section.section-container.background-2 a.button.type-5.color-8,
section.section-container.background-3 a.button.type-5.color-8,
section.section-container.background-5 a.button.type-5.color-8,
section.section-container.background-1 span.button.type-5.color-8,
section.section-container.background-2 span.button.type-5.color-8,
section.section-container.background-3 span.button.type-5.color-8,
section.section-container.background-5 span.button.type-5.color-8,
section.section-container.background-1 button.type-2.color-9,
section.section-container.background-2 button.type-2.color-9,
section.section-container.background-3 button.type-2.color-9,
section.section-container.background-5 button.type-2.color-9,
section.section-container.background-1 input[type=submit].type-2.color-9,
section.section-container.background-2 input[type=submit].type-2.color-9,
section.section-container.background-3 input[type=submit].type-2.color-9,
section.section-container.background-5 input[type=submit].type-2.color-9,
section.section-container.background-1 a.button.type-2.color-9,
section.section-container.background-2 a.button.type-2.color-9,
section.section-container.background-3 a.button.type-2.color-9,
section.section-container.background-5 a.button.type-2.color-9,
section.section-container.background-1 span.button.type-2.color-9,
section.section-container.background-2 span.button.type-2.color-9,
section.section-container.background-3 span.button.type-2.color-9,
section.section-container.background-5 span.button.type-2.color-9,
section.section-container.background-1 button.type-3.color-9,
section.section-container.background-2 button.type-3.color-9,
section.section-container.background-3 button.type-3.color-9,
section.section-container.background-5 button.type-3.color-9,
section.section-container.background-1 input[type=submit].type-3.color-9,
section.section-container.background-2 input[type=submit].type-3.color-9,
section.section-container.background-3 input[type=submit].type-3.color-9,
section.section-container.background-5 input[type=submit].type-3.color-9,
section.section-container.background-1 a.button.type-3.color-9,
section.section-container.background-2 a.button.type-3.color-9,
section.section-container.background-3 a.button.type-3.color-9,
section.section-container.background-5 a.button.type-3.color-9,
section.section-container.background-1 span.button.type-3.color-9,
section.section-container.background-2 span.button.type-3.color-9,
section.section-container.background-3 span.button.type-3.color-9,
section.section-container.background-5 span.button.type-3.color-9,
section.section-container.background-1 button.type-4.color-9,
section.section-container.background-2 button.type-4.color-9,
section.section-container.background-3 button.type-4.color-9,
section.section-container.background-5 button.type-4.color-9,
section.section-container.background-1 input[type=submit].type-4.color-9,
section.section-container.background-2 input[type=submit].type-4.color-9,
section.section-container.background-3 input[type=submit].type-4.color-9,
section.section-container.background-5 input[type=submit].type-4.color-9,
section.section-container.background-1 a.button.type-4.color-9,
section.section-container.background-2 a.button.type-4.color-9,
section.section-container.background-3 a.button.type-4.color-9,
section.section-container.background-5 a.button.type-4.color-9,
section.section-container.background-1 span.button.type-4.color-9,
section.section-container.background-2 span.button.type-4.color-9,
section.section-container.background-3 span.button.type-4.color-9,
section.section-container.background-5 span.button.type-4.color-9,
section.section-container.background-1 button.type-5.color-9,
section.section-container.background-2 button.type-5.color-9,
section.section-container.background-3 button.type-5.color-9,
section.section-container.background-5 button.type-5.color-9,
section.section-container.background-1 input[type=submit].type-5.color-9,
section.section-container.background-2 input[type=submit].type-5.color-9,
section.section-container.background-3 input[type=submit].type-5.color-9,
section.section-container.background-5 input[type=submit].type-5.color-9,
section.section-container.background-1 a.button.type-5.color-9,
section.section-container.background-2 a.button.type-5.color-9,
section.section-container.background-3 a.button.type-5.color-9,
section.section-container.background-5 a.button.type-5.color-9,
section.section-container.background-1 span.button.type-5.color-9,
section.section-container.background-2 span.button.type-5.color-9,
section.section-container.background-3 span.button.type-5.color-9,
section.section-container.background-5 span.button.type-5.color-9,
section.section-container.background-1 button.type-2.color-10,
section.section-container.background-2 button.type-2.color-10,
section.section-container.background-3 button.type-2.color-10,
section.section-container.background-5 button.type-2.color-10,
section.section-container.background-1 input[type=submit].type-2.color-10,
section.section-container.background-2 input[type=submit].type-2.color-10,
section.section-container.background-3 input[type=submit].type-2.color-10,
section.section-container.background-5 input[type=submit].type-2.color-10,
section.section-container.background-1 a.button.type-2.color-10,
section.section-container.background-2 a.button.type-2.color-10,
section.section-container.background-3 a.button.type-2.color-10,
section.section-container.background-5 a.button.type-2.color-10,
section.section-container.background-1 span.button.type-2.color-10,
section.section-container.background-2 span.button.type-2.color-10,
section.section-container.background-3 span.button.type-2.color-10,
section.section-container.background-5 span.button.type-2.color-10,
section.section-container.background-1 button.type-3.color-10,
section.section-container.background-2 button.type-3.color-10,
section.section-container.background-3 button.type-3.color-10,
section.section-container.background-5 button.type-3.color-10,
section.section-container.background-1 input[type=submit].type-3.color-10,
section.section-container.background-2 input[type=submit].type-3.color-10,
section.section-container.background-3 input[type=submit].type-3.color-10,
section.section-container.background-5 input[type=submit].type-3.color-10,
section.section-container.background-1 a.button.type-3.color-10,
section.section-container.background-2 a.button.type-3.color-10,
section.section-container.background-3 a.button.type-3.color-10,
section.section-container.background-5 a.button.type-3.color-10,
section.section-container.background-1 span.button.type-3.color-10,
section.section-container.background-2 span.button.type-3.color-10,
section.section-container.background-3 span.button.type-3.color-10,
section.section-container.background-5 span.button.type-3.color-10,
section.section-container.background-1 button.type-4.color-10,
section.section-container.background-2 button.type-4.color-10,
section.section-container.background-3 button.type-4.color-10,
section.section-container.background-5 button.type-4.color-10,
section.section-container.background-1 input[type=submit].type-4.color-10,
section.section-container.background-2 input[type=submit].type-4.color-10,
section.section-container.background-3 input[type=submit].type-4.color-10,
section.section-container.background-5 input[type=submit].type-4.color-10,
section.section-container.background-1 a.button.type-4.color-10,
section.section-container.background-2 a.button.type-4.color-10,
section.section-container.background-3 a.button.type-4.color-10,
section.section-container.background-5 a.button.type-4.color-10,
section.section-container.background-1 span.button.type-4.color-10,
section.section-container.background-2 span.button.type-4.color-10,
section.section-container.background-3 span.button.type-4.color-10,
section.section-container.background-5 span.button.type-4.color-10,
section.section-container.background-1 button.type-5.color-10,
section.section-container.background-2 button.type-5.color-10,
section.section-container.background-3 button.type-5.color-10,
section.section-container.background-5 button.type-5.color-10,
section.section-container.background-1 input[type=submit].type-5.color-10,
section.section-container.background-2 input[type=submit].type-5.color-10,
section.section-container.background-3 input[type=submit].type-5.color-10,
section.section-container.background-5 input[type=submit].type-5.color-10,
section.section-container.background-1 a.button.type-5.color-10,
section.section-container.background-2 a.button.type-5.color-10,
section.section-container.background-3 a.button.type-5.color-10,
section.section-container.background-5 a.button.type-5.color-10,
section.section-container.background-1 span.button.type-5.color-10,
section.section-container.background-2 span.button.type-5.color-10,
section.section-container.background-3 span.button.type-5.color-10,
section.section-container.background-5 span.button.type-5.color-10 {
    background: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
}

section.section-container.background-4 {
    --base-icon-color: #ea9100;
}

section.section-container.background-4 .headline-container h1,
section.section-container.background-4 .headline-container h2,
section.section-container.background-4 .headline-container h3,
section.section-container.background-4 .headline-container h4,
section.section-container.background-4 .headline-container h5,
section.section-container.background-4 .headline-container h6,
section.section-container.background-4 .headline-container p,
section.section-container.background-4 .headline-container b,
section.section-container.background-4 .headline-container strong,
section.section-container.background-4 .headline-container i,
section.section-container.background-4 .headline-container em,
section.section-container.background-4 .headline-container blockquote,
section.section-container.background-4 .headline-container span {
    color: #ffffff;
}

section.section-container.background-4 .headline-container h1.title-line::before,
section.section-container.background-4 .headline-container h2.title-line::before,
section.section-container.background-4 .headline-container h3.title-line::before,
section.section-container.background-4 .headline-container h4.title-line::before,
section.section-container.background-4 .headline-container h5.title-line::before,
section.section-container.background-4 .headline-container h6.title-line::before,
section.section-container.background-4 .headline-container p.title-line::before,
section.section-container.background-4 .headline-container b.title-line::before,
section.section-container.background-4 .headline-container strong.title-line::before,
section.section-container.background-4 .headline-container i.title-line::before,
section.section-container.background-4 .headline-container em.title-line::before,
section.section-container.background-4 .headline-container blockquote.title-line::before,
section.section-container.background-4 .headline-container span.title-line::before {
    background-color: #ea9100;
}

section.section-container.background-4 .icon-text-container h1,
section.section-container.background-4 .icon-text-container h2,
section.section-container.background-4 .icon-text-container h3,
section.section-container.background-4 .icon-text-container h4,
section.section-container.background-4 .icon-text-container h5,
section.section-container.background-4 .icon-text-container h6,
section.section-container.background-4 .icon-text-container p,
section.section-container.background-4 .icon-text-container b,
section.section-container.background-4 .icon-text-container strong,
section.section-container.background-4 .icon-text-container i,
section.section-container.background-4 .icon-text-container em,
section.section-container.background-4 .icon-text-container blockquote,
section.section-container.background-4 .icon-text-container span {
    color: #ffffff;
}

section.section-container.background-4 .text-container-wrapper h1,
section.section-container.background-4 .text-image-wrapper h1,
section.section-container.background-4 .text-container h1,
section.section-container.background-4 .text-container-wrapper h2,
section.section-container.background-4 .text-image-wrapper h2,
section.section-container.background-4 .text-container h2,
section.section-container.background-4 .text-container-wrapper h3,
section.section-container.background-4 .text-image-wrapper h3,
section.section-container.background-4 .text-container h3,
section.section-container.background-4 .text-container-wrapper h4,
section.section-container.background-4 .text-image-wrapper h4,
section.section-container.background-4 .text-container h4,
section.section-container.background-4 .text-container-wrapper h5,
section.section-container.background-4 .text-image-wrapper h5,
section.section-container.background-4 .text-container h5,
section.section-container.background-4 .text-container-wrapper h6,
section.section-container.background-4 .text-image-wrapper h6,
section.section-container.background-4 .text-container h6,
section.section-container.background-4 .text-container-wrapper p,
section.section-container.background-4 .text-image-wrapper p,
section.section-container.background-4 .text-container p,
section.section-container.background-4 .text-container-wrapper b,
section.section-container.background-4 .text-image-wrapper b,
section.section-container.background-4 .text-container b,
section.section-container.background-4 .text-container-wrapper strong,
section.section-container.background-4 .text-image-wrapper strong,
section.section-container.background-4 .text-container strong,
section.section-container.background-4 .text-container-wrapper i,
section.section-container.background-4 .text-image-wrapper i,
section.section-container.background-4 .text-container i,
section.section-container.background-4 .text-container-wrapper em,
section.section-container.background-4 .text-image-wrapper em,
section.section-container.background-4 .text-container em,
section.section-container.background-4 .text-container-wrapper blockquote,
section.section-container.background-4 .text-image-wrapper blockquote,
section.section-container.background-4 .text-container blockquote,
section.section-container.background-4 .text-container-wrapper span,
section.section-container.background-4 .text-image-wrapper span,
section.section-container.background-4 .text-container span {
    color: #ffffff;
}

section.section-container.background-4 .text-container-wrapper ul li,
section.section-container.background-4 .text-image-wrapper ul li,
section.section-container.background-4 .text-container ul li {
    color: #ffffff;
}

section.section-container.background-4 .text-container-wrapper ul li p,
section.section-container.background-4 .text-image-wrapper ul li p,
section.section-container.background-4 .text-container ul li p,
section.section-container.background-4 .text-container-wrapper ul li b,
section.section-container.background-4 .text-image-wrapper ul li b,
section.section-container.background-4 .text-container ul li b,
section.section-container.background-4 .text-container-wrapper ul li strong,
section.section-container.background-4 .text-image-wrapper ul li strong,
section.section-container.background-4 .text-container ul li strong,
section.section-container.background-4 .text-container-wrapper ul li i,
section.section-container.background-4 .text-image-wrapper ul li i,
section.section-container.background-4 .text-container ul li i,
section.section-container.background-4 .text-container-wrapper ul li em,
section.section-container.background-4 .text-image-wrapper ul li em,
section.section-container.background-4 .text-container ul li em,
section.section-container.background-4 .text-container-wrapper ul li span,
section.section-container.background-4 .text-image-wrapper ul li span,
section.section-container.background-4 .text-container ul li span {
    color: #ffffff;
}

section.section-container.background-4 .text-container-wrapper ul li a,
section.section-container.background-4 .text-image-wrapper ul li a,
section.section-container.background-4 .text-container ul li a {
    color: #ffffff;
}

section.section-container.background-4 .text-container-wrapper ul li::before,
section.section-container.background-4 .text-image-wrapper ul li::before,
section.section-container.background-4 .text-container ul li::before {
    background-color: #ea9100;
}

section.section-container.background-4 .text-container-wrapper ol li,
section.section-container.background-4 .text-image-wrapper ol li,
section.section-container.background-4 .text-container ol li {
    color: #ffffff;
}

section.section-container.background-4 .text-container-wrapper ol li::before,
section.section-container.background-4 .text-image-wrapper ol li::before,
section.section-container.background-4 .text-container ol li::before {
    background-color: #ea9100;
    color: #ffffff;
}

section.section-container.background-4 .text-container-wrapper ol li a,
section.section-container.background-4 .text-image-wrapper ol li a,
section.section-container.background-4 .text-container ol li a {
    color: #ffffff;
}

section.section-container.background-4 a.link.type-2.color-1,
section.section-container.background-4 span.link.type-2.color-1,
section.section-container.background-4 a.link.type-1.color-1,
section.section-container.background-4 span.link.type-1.color-1,
section.section-container.background-4 a.link.type-3.color-1,
section.section-container.background-4 span.link.type-3.color-1,
section.section-container.background-4 a.link.type-2.color-2,
section.section-container.background-4 span.link.type-2.color-2,
section.section-container.background-4 a.link.type-1.color-2,
section.section-container.background-4 span.link.type-1.color-2,
section.section-container.background-4 a.link.type-3.color-2,
section.section-container.background-4 span.link.type-3.color-2,
section.section-container.background-4 a.link.type-2.color-3,
section.section-container.background-4 span.link.type-2.color-3,
section.section-container.background-4 a.link.type-1.color-3,
section.section-container.background-4 span.link.type-1.color-3,
section.section-container.background-4 a.link.type-3.color-3,
section.section-container.background-4 span.link.type-3.color-3,
section.section-container.background-4 a.link.type-2.color-4,
section.section-container.background-4 span.link.type-2.color-4,
section.section-container.background-4 a.link.type-1.color-4,
section.section-container.background-4 span.link.type-1.color-4,
section.section-container.background-4 a.link.type-3.color-4,
section.section-container.background-4 span.link.type-3.color-4,
section.section-container.background-4 a.link.type-2.color-5,
section.section-container.background-4 span.link.type-2.color-5,
section.section-container.background-4 a.link.type-1.color-5,
section.section-container.background-4 span.link.type-1.color-5,
section.section-container.background-4 a.link.type-3.color-5,
section.section-container.background-4 span.link.type-3.color-5 {
    color: #ffffff;
}

section.section-container.background-4 a.link.type-2.color-1::after,
section.section-container.background-4 span.link.type-2.color-1::after,
section.section-container.background-4 a.link.type-1.color-1::after,
section.section-container.background-4 span.link.type-1.color-1::after,
section.section-container.background-4 a.link.type-3.color-1::after,
section.section-container.background-4 span.link.type-3.color-1::after,
section.section-container.background-4 a.link.type-2.color-2::after,
section.section-container.background-4 span.link.type-2.color-2::after,
section.section-container.background-4 a.link.type-1.color-2::after,
section.section-container.background-4 span.link.type-1.color-2::after,
section.section-container.background-4 a.link.type-3.color-2::after,
section.section-container.background-4 span.link.type-3.color-2::after,
section.section-container.background-4 a.link.type-2.color-3::after,
section.section-container.background-4 span.link.type-2.color-3::after,
section.section-container.background-4 a.link.type-1.color-3::after,
section.section-container.background-4 span.link.type-1.color-3::after,
section.section-container.background-4 a.link.type-3.color-3::after,
section.section-container.background-4 span.link.type-3.color-3::after,
section.section-container.background-4 a.link.type-2.color-4::after,
section.section-container.background-4 span.link.type-2.color-4::after,
section.section-container.background-4 a.link.type-1.color-4::after,
section.section-container.background-4 span.link.type-1.color-4::after,
section.section-container.background-4 a.link.type-3.color-4::after,
section.section-container.background-4 span.link.type-3.color-4::after,
section.section-container.background-4 a.link.type-2.color-5::after,
section.section-container.background-4 span.link.type-2.color-5::after,
section.section-container.background-4 a.link.type-1.color-5::after,
section.section-container.background-4 span.link.type-1.color-5::after,
section.section-container.background-4 a.link.type-3.color-5::after,
section.section-container.background-4 span.link.type-3.color-5::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ea9100' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-4 a.link.type-4.color-1,
section.section-container.background-4 span.link.type-4.color-1,
section.section-container.background-4 a.link.type-4.color-2,
section.section-container.background-4 span.link.type-4.color-2,
section.section-container.background-4 a.link.type-4.color-3,
section.section-container.background-4 span.link.type-4.color-3,
section.section-container.background-4 a.link.type-4.color-4,
section.section-container.background-4 span.link.type-4.color-4,
section.section-container.background-4 a.link.type-4.color-5,
section.section-container.background-4 span.link.type-4.color-5 {
    color: #ffffff;
}

section.section-container.background-4 a.link.type-4.color-1::before,
section.section-container.background-4 span.link.type-4.color-1::before,
section.section-container.background-4 a.link.type-4.color-2::before,
section.section-container.background-4 span.link.type-4.color-2::before,
section.section-container.background-4 a.link.type-4.color-3::before,
section.section-container.background-4 span.link.type-4.color-3::before,
section.section-container.background-4 a.link.type-4.color-4::before,
section.section-container.background-4 span.link.type-4.color-4::before,
section.section-container.background-4 a.link.type-4.color-5::before,
section.section-container.background-4 span.link.type-4.color-5::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ea9100' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-4 a.link.icon.icon-download,
section.section-container.background-4 span.link.icon.icon-download {
    color: #ffffff;
}

section.section-container.background-4 a.link.icon.icon-download::before,
section.section-container.background-4 span.link.icon.icon-download::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='19' viewBox='0 0 20 19'%3E %3Cg fill='none' fill-rule='evenodd' stroke='%23ea9100' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E %3Cpath d='M19 14v4H1v-4M10.418 1v12.065'/%3E %3Cpath d='M6 10l4 4 4-4'/%3E %3C/g%3E %3C/svg%3E ");
}

section.section-container.background-4 button.type-1.color-1,
section.section-container.background-4 input[type=submit].type-1.color-1,
section.section-container.background-4 a.button.type-1.color-1,
section.section-container.background-4 span.button.type-1.color-1,
section.section-container.background-4 button.type-1.color-2,
section.section-container.background-4 input[type=submit].type-1.color-2,
section.section-container.background-4 a.button.type-1.color-2,
section.section-container.background-4 span.button.type-1.color-2,
section.section-container.background-4 button.type-1.color-3,
section.section-container.background-4 input[type=submit].type-1.color-3,
section.section-container.background-4 a.button.type-1.color-3,
section.section-container.background-4 span.button.type-1.color-3,
section.section-container.background-4 button.type-1.color-4,
section.section-container.background-4 input[type=submit].type-1.color-4,
section.section-container.background-4 a.button.type-1.color-4,
section.section-container.background-4 span.button.type-1.color-4 {
    background: #ea9100;
    border: 2px solid #ea9100;
    color: #ffffff;
}

section.section-container.background-4 button.type-1::after,
section.section-container.background-4 input[type=submit].type-1::after,
section.section-container.background-4 a.button.type-1::after,
section.section-container.background-4 span.button.type-1::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ea9100' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-4 button.type-2.color-1,
section.section-container.background-4 input[type=submit].type-2.color-1,
section.section-container.background-4 a.button.type-2.color-1,
section.section-container.background-4 span.button.type-2.color-1,
section.section-container.background-4 button.type-3.color-1,
section.section-container.background-4 input[type=submit].type-3.color-1,
section.section-container.background-4 a.button.type-3.color-1,
section.section-container.background-4 span.button.type-3.color-1,
section.section-container.background-4 button.type-4.color-1,
section.section-container.background-4 input[type=submit].type-4.color-1,
section.section-container.background-4 a.button.type-4.color-1,
section.section-container.background-4 span.button.type-4.color-1,
section.section-container.background-4 button.type-5.color-1,
section.section-container.background-4 input[type=submit].type-5.color-1,
section.section-container.background-4 a.button.type-5.color-1,
section.section-container.background-4 span.button.type-5.color-1,
section.section-container.background-4 button.type-2.color-2,
section.section-container.background-4 input[type=submit].type-2.color-2,
section.section-container.background-4 a.button.type-2.color-2,
section.section-container.background-4 span.button.type-2.color-2,
section.section-container.background-4 button.type-3.color-2,
section.section-container.background-4 input[type=submit].type-3.color-2,
section.section-container.background-4 a.button.type-3.color-2,
section.section-container.background-4 span.button.type-3.color-2,
section.section-container.background-4 button.type-4.color-2,
section.section-container.background-4 input[type=submit].type-4.color-2,
section.section-container.background-4 a.button.type-4.color-2,
section.section-container.background-4 span.button.type-4.color-2,
section.section-container.background-4 button.type-5.color-2,
section.section-container.background-4 input[type=submit].type-5.color-2,
section.section-container.background-4 a.button.type-5.color-2,
section.section-container.background-4 span.button.type-5.color-2,
section.section-container.background-4 button.type-2.color-3,
section.section-container.background-4 input[type=submit].type-2.color-3,
section.section-container.background-4 a.button.type-2.color-3,
section.section-container.background-4 span.button.type-2.color-3,
section.section-container.background-4 button.type-3.color-3,
section.section-container.background-4 input[type=submit].type-3.color-3,
section.section-container.background-4 a.button.type-3.color-3,
section.section-container.background-4 span.button.type-3.color-3,
section.section-container.background-4 button.type-4.color-3,
section.section-container.background-4 input[type=submit].type-4.color-3,
section.section-container.background-4 a.button.type-4.color-3,
section.section-container.background-4 span.button.type-4.color-3,
section.section-container.background-4 button.type-5.color-3,
section.section-container.background-4 input[type=submit].type-5.color-3,
section.section-container.background-4 a.button.type-5.color-3,
section.section-container.background-4 span.button.type-5.color-3,
section.section-container.background-4 button.type-2.color-4,
section.section-container.background-4 input[type=submit].type-2.color-4,
section.section-container.background-4 a.button.type-2.color-4,
section.section-container.background-4 span.button.type-2.color-4,
section.section-container.background-4 button.type-3.color-4,
section.section-container.background-4 input[type=submit].type-3.color-4,
section.section-container.background-4 a.button.type-3.color-4,
section.section-container.background-4 span.button.type-3.color-4,
section.section-container.background-4 button.type-4.color-4,
section.section-container.background-4 input[type=submit].type-4.color-4,
section.section-container.background-4 a.button.type-4.color-4,
section.section-container.background-4 span.button.type-4.color-4,
section.section-container.background-4 button.type-5.color-4,
section.section-container.background-4 input[type=submit].type-5.color-4,
section.section-container.background-4 a.button.type-5.color-4,
section.section-container.background-4 span.button.type-5.color-4 {
    background: #ea9100;
    border: 2px solid #ea9100;
    color: #ffffff;
}

section.section-container.background-0,
section.section-container.background-4 {
    --base-icon-color: #ea9100;
}

section.section-container.background-0.color-1,
section.section-container.background-4.color-1 {
    --base-icon-color: #eb6a3e;
}

section.section-container.background-0.color-1 .headline-container h1.title-line::before,
section.section-container.background-4.color-1 .headline-container h1.title-line::before,
section.section-container.background-0.color-1 .headline-container h2.title-line::before,
section.section-container.background-4.color-1 .headline-container h2.title-line::before,
section.section-container.background-0.color-1 .headline-container h3.title-line::before,
section.section-container.background-4.color-1 .headline-container h3.title-line::before,
section.section-container.background-0.color-1 .headline-container h4.title-line::before,
section.section-container.background-4.color-1 .headline-container h4.title-line::before,
section.section-container.background-0.color-1 .headline-container h5.title-line::before,
section.section-container.background-4.color-1 .headline-container h5.title-line::before,
section.section-container.background-0.color-1 .headline-container h6.title-line::before,
section.section-container.background-4.color-1 .headline-container h6.title-line::before,
section.section-container.background-0.color-1 .headline-container p.title-line::before,
section.section-container.background-4.color-1 .headline-container p.title-line::before,
section.section-container.background-0.color-1 .headline-container b.title-line::before,
section.section-container.background-4.color-1 .headline-container b.title-line::before,
section.section-container.background-0.color-1 .headline-container strong.title-line::before,
section.section-container.background-4.color-1 .headline-container strong.title-line::before,
section.section-container.background-0.color-1 .headline-container i.title-line::before,
section.section-container.background-4.color-1 .headline-container i.title-line::before,
section.section-container.background-0.color-1 .headline-container em.title-line::before,
section.section-container.background-4.color-1 .headline-container em.title-line::before,
section.section-container.background-0.color-1 .headline-container blockquote.title-line::before,
section.section-container.background-4.color-1 .headline-container blockquote.title-line::before,
section.section-container.background-0.color-1 .headline-container span.title-line::before,
section.section-container.background-4.color-1 .headline-container span.title-line::before {
    background-color: #eb6a3e;
}

section.section-container.background-0.color-1 a.link.type-1::after,
section.section-container.background-4.color-1 a.link.type-1::after,
section.section-container.background-0.color-1 span.link.type-1::after,
section.section-container.background-4.color-1 span.link.type-1::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23eb6a3e' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-0.color-1 a.link.type-2::after,
section.section-container.background-4.color-1 a.link.type-2::after,
section.section-container.background-0.color-1 span.link.type-2::after,
section.section-container.background-4.color-1 span.link.type-2::after,
section.section-container.background-0.color-1 a.link.type-3::after,
section.section-container.background-4.color-1 a.link.type-3::after,
section.section-container.background-0.color-1 span.link.type-3::after,
section.section-container.background-4.color-1 span.link.type-3::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23eb6a3e' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-0.color-1 a.link.type-4::before,
section.section-container.background-4.color-1 a.link.type-4::before,
section.section-container.background-0.color-1 span.link.type-4::before,
section.section-container.background-4.color-1 span.link.type-4::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23eb6a3e' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-0.color-1 button.type-1.color-1,
section.section-container.background-4.color-1 button.type-1.color-1,
section.section-container.background-0.color-1 input[type=submit].type-1.color-1,
section.section-container.background-4.color-1 input[type=submit].type-1.color-1,
section.section-container.background-0.color-1 a.button.type-1.color-1,
section.section-container.background-4.color-1 a.button.type-1.color-1,
section.section-container.background-0.color-1 span.button.type-1.color-1,
section.section-container.background-4.color-1 span.button.type-1.color-1,
section.section-container.background-0.color-1 button.type-1.color-2,
section.section-container.background-4.color-1 button.type-1.color-2,
section.section-container.background-0.color-1 input[type=submit].type-1.color-2,
section.section-container.background-4.color-1 input[type=submit].type-1.color-2,
section.section-container.background-0.color-1 a.button.type-1.color-2,
section.section-container.background-4.color-1 a.button.type-1.color-2,
section.section-container.background-0.color-1 span.button.type-1.color-2,
section.section-container.background-4.color-1 span.button.type-1.color-2,
section.section-container.background-0.color-1 button.type-1.color-3,
section.section-container.background-4.color-1 button.type-1.color-3,
section.section-container.background-0.color-1 input[type=submit].type-1.color-3,
section.section-container.background-4.color-1 input[type=submit].type-1.color-3,
section.section-container.background-0.color-1 a.button.type-1.color-3,
section.section-container.background-4.color-1 a.button.type-1.color-3,
section.section-container.background-0.color-1 span.button.type-1.color-3,
section.section-container.background-4.color-1 span.button.type-1.color-3,
section.section-container.background-0.color-1 button.type-1.color-4,
section.section-container.background-4.color-1 button.type-1.color-4,
section.section-container.background-0.color-1 input[type=submit].type-1.color-4,
section.section-container.background-4.color-1 input[type=submit].type-1.color-4,
section.section-container.background-0.color-1 a.button.type-1.color-4,
section.section-container.background-4.color-1 a.button.type-1.color-4,
section.section-container.background-0.color-1 span.button.type-1.color-4,
section.section-container.background-4.color-1 span.button.type-1.color-4,
section.section-container.background-0.color-1 button.type-1.color-5,
section.section-container.background-4.color-1 button.type-1.color-5,
section.section-container.background-0.color-1 input[type=submit].type-1.color-5,
section.section-container.background-4.color-1 input[type=submit].type-1.color-5,
section.section-container.background-0.color-1 a.button.type-1.color-5,
section.section-container.background-4.color-1 a.button.type-1.color-5,
section.section-container.background-0.color-1 span.button.type-1.color-5,
section.section-container.background-4.color-1 span.button.type-1.color-5,
section.section-container.background-0.color-1 button.type-1.color-6,
section.section-container.background-4.color-1 button.type-1.color-6,
section.section-container.background-0.color-1 input[type=submit].type-1.color-6,
section.section-container.background-4.color-1 input[type=submit].type-1.color-6,
section.section-container.background-0.color-1 a.button.type-1.color-6,
section.section-container.background-4.color-1 a.button.type-1.color-6,
section.section-container.background-0.color-1 span.button.type-1.color-6,
section.section-container.background-4.color-1 span.button.type-1.color-6,
section.section-container.background-0.color-1 button.type-1.color-7,
section.section-container.background-4.color-1 button.type-1.color-7,
section.section-container.background-0.color-1 input[type=submit].type-1.color-7,
section.section-container.background-4.color-1 input[type=submit].type-1.color-7,
section.section-container.background-0.color-1 a.button.type-1.color-7,
section.section-container.background-4.color-1 a.button.type-1.color-7,
section.section-container.background-0.color-1 span.button.type-1.color-7,
section.section-container.background-4.color-1 span.button.type-1.color-7,
section.section-container.background-0.color-1 button.type-1.color-8,
section.section-container.background-4.color-1 button.type-1.color-8,
section.section-container.background-0.color-1 input[type=submit].type-1.color-8,
section.section-container.background-4.color-1 input[type=submit].type-1.color-8,
section.section-container.background-0.color-1 a.button.type-1.color-8,
section.section-container.background-4.color-1 a.button.type-1.color-8,
section.section-container.background-0.color-1 span.button.type-1.color-8,
section.section-container.background-4.color-1 span.button.type-1.color-8,
section.section-container.background-0.color-1 button.type-1.color-9,
section.section-container.background-4.color-1 button.type-1.color-9,
section.section-container.background-0.color-1 input[type=submit].type-1.color-9,
section.section-container.background-4.color-1 input[type=submit].type-1.color-9,
section.section-container.background-0.color-1 a.button.type-1.color-9,
section.section-container.background-4.color-1 a.button.type-1.color-9,
section.section-container.background-0.color-1 span.button.type-1.color-9,
section.section-container.background-4.color-1 span.button.type-1.color-9,
section.section-container.background-0.color-1 button.type-1.color-10,
section.section-container.background-4.color-1 button.type-1.color-10,
section.section-container.background-0.color-1 input[type=submit].type-1.color-10,
section.section-container.background-4.color-1 input[type=submit].type-1.color-10,
section.section-container.background-0.color-1 a.button.type-1.color-10,
section.section-container.background-4.color-1 a.button.type-1.color-10,
section.section-container.background-0.color-1 span.button.type-1.color-10,
section.section-container.background-4.color-1 span.button.type-1.color-10 {
    background: #eb6a3e;
    border: 2px solid #eb6a3e;
    color: #ffffff;
}

section.section-container.background-0.color-1 button.type-1::after,
section.section-container.background-4.color-1 button.type-1::after,
section.section-container.background-0.color-1 input[type=submit].type-1::after,
section.section-container.background-4.color-1 input[type=submit].type-1::after,
section.section-container.background-0.color-1 a.button.type-1::after,
section.section-container.background-4.color-1 a.button.type-1::after,
section.section-container.background-0.color-1 span.button.type-1::after,
section.section-container.background-4.color-1 span.button.type-1::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ffffff' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-0.color-1 button.type-2.color-1,
section.section-container.background-4.color-1 button.type-2.color-1,
section.section-container.background-0.color-1 input[type=submit].type-2.color-1,
section.section-container.background-4.color-1 input[type=submit].type-2.color-1,
section.section-container.background-0.color-1 a.button.type-2.color-1,
section.section-container.background-4.color-1 a.button.type-2.color-1,
section.section-container.background-0.color-1 span.button.type-2.color-1,
section.section-container.background-4.color-1 span.button.type-2.color-1,
section.section-container.background-0.color-1 button.type-3.color-1,
section.section-container.background-4.color-1 button.type-3.color-1,
section.section-container.background-0.color-1 input[type=submit].type-3.color-1,
section.section-container.background-4.color-1 input[type=submit].type-3.color-1,
section.section-container.background-0.color-1 a.button.type-3.color-1,
section.section-container.background-4.color-1 a.button.type-3.color-1,
section.section-container.background-0.color-1 span.button.type-3.color-1,
section.section-container.background-4.color-1 span.button.type-3.color-1,
section.section-container.background-0.color-1 button.type-4.color-1,
section.section-container.background-4.color-1 button.type-4.color-1,
section.section-container.background-0.color-1 input[type=submit].type-4.color-1,
section.section-container.background-4.color-1 input[type=submit].type-4.color-1,
section.section-container.background-0.color-1 a.button.type-4.color-1,
section.section-container.background-4.color-1 a.button.type-4.color-1,
section.section-container.background-0.color-1 span.button.type-4.color-1,
section.section-container.background-4.color-1 span.button.type-4.color-1,
section.section-container.background-0.color-1 button.type-5.color-1,
section.section-container.background-4.color-1 button.type-5.color-1,
section.section-container.background-0.color-1 input[type=submit].type-5.color-1,
section.section-container.background-4.color-1 input[type=submit].type-5.color-1,
section.section-container.background-0.color-1 a.button.type-5.color-1,
section.section-container.background-4.color-1 a.button.type-5.color-1,
section.section-container.background-0.color-1 span.button.type-5.color-1,
section.section-container.background-4.color-1 span.button.type-5.color-1,
section.section-container.background-0.color-1 button.type-2.color-2,
section.section-container.background-4.color-1 button.type-2.color-2,
section.section-container.background-0.color-1 input[type=submit].type-2.color-2,
section.section-container.background-4.color-1 input[type=submit].type-2.color-2,
section.section-container.background-0.color-1 a.button.type-2.color-2,
section.section-container.background-4.color-1 a.button.type-2.color-2,
section.section-container.background-0.color-1 span.button.type-2.color-2,
section.section-container.background-4.color-1 span.button.type-2.color-2,
section.section-container.background-0.color-1 button.type-3.color-2,
section.section-container.background-4.color-1 button.type-3.color-2,
section.section-container.background-0.color-1 input[type=submit].type-3.color-2,
section.section-container.background-4.color-1 input[type=submit].type-3.color-2,
section.section-container.background-0.color-1 a.button.type-3.color-2,
section.section-container.background-4.color-1 a.button.type-3.color-2,
section.section-container.background-0.color-1 span.button.type-3.color-2,
section.section-container.background-4.color-1 span.button.type-3.color-2,
section.section-container.background-0.color-1 button.type-4.color-2,
section.section-container.background-4.color-1 button.type-4.color-2,
section.section-container.background-0.color-1 input[type=submit].type-4.color-2,
section.section-container.background-4.color-1 input[type=submit].type-4.color-2,
section.section-container.background-0.color-1 a.button.type-4.color-2,
section.section-container.background-4.color-1 a.button.type-4.color-2,
section.section-container.background-0.color-1 span.button.type-4.color-2,
section.section-container.background-4.color-1 span.button.type-4.color-2,
section.section-container.background-0.color-1 button.type-5.color-2,
section.section-container.background-4.color-1 button.type-5.color-2,
section.section-container.background-0.color-1 input[type=submit].type-5.color-2,
section.section-container.background-4.color-1 input[type=submit].type-5.color-2,
section.section-container.background-0.color-1 a.button.type-5.color-2,
section.section-container.background-4.color-1 a.button.type-5.color-2,
section.section-container.background-0.color-1 span.button.type-5.color-2,
section.section-container.background-4.color-1 span.button.type-5.color-2,
section.section-container.background-0.color-1 button.type-2.color-3,
section.section-container.background-4.color-1 button.type-2.color-3,
section.section-container.background-0.color-1 input[type=submit].type-2.color-3,
section.section-container.background-4.color-1 input[type=submit].type-2.color-3,
section.section-container.background-0.color-1 a.button.type-2.color-3,
section.section-container.background-4.color-1 a.button.type-2.color-3,
section.section-container.background-0.color-1 span.button.type-2.color-3,
section.section-container.background-4.color-1 span.button.type-2.color-3,
section.section-container.background-0.color-1 button.type-3.color-3,
section.section-container.background-4.color-1 button.type-3.color-3,
section.section-container.background-0.color-1 input[type=submit].type-3.color-3,
section.section-container.background-4.color-1 input[type=submit].type-3.color-3,
section.section-container.background-0.color-1 a.button.type-3.color-3,
section.section-container.background-4.color-1 a.button.type-3.color-3,
section.section-container.background-0.color-1 span.button.type-3.color-3,
section.section-container.background-4.color-1 span.button.type-3.color-3,
section.section-container.background-0.color-1 button.type-4.color-3,
section.section-container.background-4.color-1 button.type-4.color-3,
section.section-container.background-0.color-1 input[type=submit].type-4.color-3,
section.section-container.background-4.color-1 input[type=submit].type-4.color-3,
section.section-container.background-0.color-1 a.button.type-4.color-3,
section.section-container.background-4.color-1 a.button.type-4.color-3,
section.section-container.background-0.color-1 span.button.type-4.color-3,
section.section-container.background-4.color-1 span.button.type-4.color-3,
section.section-container.background-0.color-1 button.type-5.color-3,
section.section-container.background-4.color-1 button.type-5.color-3,
section.section-container.background-0.color-1 input[type=submit].type-5.color-3,
section.section-container.background-4.color-1 input[type=submit].type-5.color-3,
section.section-container.background-0.color-1 a.button.type-5.color-3,
section.section-container.background-4.color-1 a.button.type-5.color-3,
section.section-container.background-0.color-1 span.button.type-5.color-3,
section.section-container.background-4.color-1 span.button.type-5.color-3,
section.section-container.background-0.color-1 button.type-2.color-4,
section.section-container.background-4.color-1 button.type-2.color-4,
section.section-container.background-0.color-1 input[type=submit].type-2.color-4,
section.section-container.background-4.color-1 input[type=submit].type-2.color-4,
section.section-container.background-0.color-1 a.button.type-2.color-4,
section.section-container.background-4.color-1 a.button.type-2.color-4,
section.section-container.background-0.color-1 span.button.type-2.color-4,
section.section-container.background-4.color-1 span.button.type-2.color-4,
section.section-container.background-0.color-1 button.type-3.color-4,
section.section-container.background-4.color-1 button.type-3.color-4,
section.section-container.background-0.color-1 input[type=submit].type-3.color-4,
section.section-container.background-4.color-1 input[type=submit].type-3.color-4,
section.section-container.background-0.color-1 a.button.type-3.color-4,
section.section-container.background-4.color-1 a.button.type-3.color-4,
section.section-container.background-0.color-1 span.button.type-3.color-4,
section.section-container.background-4.color-1 span.button.type-3.color-4,
section.section-container.background-0.color-1 button.type-4.color-4,
section.section-container.background-4.color-1 button.type-4.color-4,
section.section-container.background-0.color-1 input[type=submit].type-4.color-4,
section.section-container.background-4.color-1 input[type=submit].type-4.color-4,
section.section-container.background-0.color-1 a.button.type-4.color-4,
section.section-container.background-4.color-1 a.button.type-4.color-4,
section.section-container.background-0.color-1 span.button.type-4.color-4,
section.section-container.background-4.color-1 span.button.type-4.color-4,
section.section-container.background-0.color-1 button.type-5.color-4,
section.section-container.background-4.color-1 button.type-5.color-4,
section.section-container.background-0.color-1 input[type=submit].type-5.color-4,
section.section-container.background-4.color-1 input[type=submit].type-5.color-4,
section.section-container.background-0.color-1 a.button.type-5.color-4,
section.section-container.background-4.color-1 a.button.type-5.color-4,
section.section-container.background-0.color-1 span.button.type-5.color-4,
section.section-container.background-4.color-1 span.button.type-5.color-4,
section.section-container.background-0.color-1 button.type-2.color-5,
section.section-container.background-4.color-1 button.type-2.color-5,
section.section-container.background-0.color-1 input[type=submit].type-2.color-5,
section.section-container.background-4.color-1 input[type=submit].type-2.color-5,
section.section-container.background-0.color-1 a.button.type-2.color-5,
section.section-container.background-4.color-1 a.button.type-2.color-5,
section.section-container.background-0.color-1 span.button.type-2.color-5,
section.section-container.background-4.color-1 span.button.type-2.color-5,
section.section-container.background-0.color-1 button.type-3.color-5,
section.section-container.background-4.color-1 button.type-3.color-5,
section.section-container.background-0.color-1 input[type=submit].type-3.color-5,
section.section-container.background-4.color-1 input[type=submit].type-3.color-5,
section.section-container.background-0.color-1 a.button.type-3.color-5,
section.section-container.background-4.color-1 a.button.type-3.color-5,
section.section-container.background-0.color-1 span.button.type-3.color-5,
section.section-container.background-4.color-1 span.button.type-3.color-5,
section.section-container.background-0.color-1 button.type-4.color-5,
section.section-container.background-4.color-1 button.type-4.color-5,
section.section-container.background-0.color-1 input[type=submit].type-4.color-5,
section.section-container.background-4.color-1 input[type=submit].type-4.color-5,
section.section-container.background-0.color-1 a.button.type-4.color-5,
section.section-container.background-4.color-1 a.button.type-4.color-5,
section.section-container.background-0.color-1 span.button.type-4.color-5,
section.section-container.background-4.color-1 span.button.type-4.color-5,
section.section-container.background-0.color-1 button.type-5.color-5,
section.section-container.background-4.color-1 button.type-5.color-5,
section.section-container.background-0.color-1 input[type=submit].type-5.color-5,
section.section-container.background-4.color-1 input[type=submit].type-5.color-5,
section.section-container.background-0.color-1 a.button.type-5.color-5,
section.section-container.background-4.color-1 a.button.type-5.color-5,
section.section-container.background-0.color-1 span.button.type-5.color-5,
section.section-container.background-4.color-1 span.button.type-5.color-5,
section.section-container.background-0.color-1 button.type-2.color-6,
section.section-container.background-4.color-1 button.type-2.color-6,
section.section-container.background-0.color-1 input[type=submit].type-2.color-6,
section.section-container.background-4.color-1 input[type=submit].type-2.color-6,
section.section-container.background-0.color-1 a.button.type-2.color-6,
section.section-container.background-4.color-1 a.button.type-2.color-6,
section.section-container.background-0.color-1 span.button.type-2.color-6,
section.section-container.background-4.color-1 span.button.type-2.color-6,
section.section-container.background-0.color-1 button.type-3.color-6,
section.section-container.background-4.color-1 button.type-3.color-6,
section.section-container.background-0.color-1 input[type=submit].type-3.color-6,
section.section-container.background-4.color-1 input[type=submit].type-3.color-6,
section.section-container.background-0.color-1 a.button.type-3.color-6,
section.section-container.background-4.color-1 a.button.type-3.color-6,
section.section-container.background-0.color-1 span.button.type-3.color-6,
section.section-container.background-4.color-1 span.button.type-3.color-6,
section.section-container.background-0.color-1 button.type-4.color-6,
section.section-container.background-4.color-1 button.type-4.color-6,
section.section-container.background-0.color-1 input[type=submit].type-4.color-6,
section.section-container.background-4.color-1 input[type=submit].type-4.color-6,
section.section-container.background-0.color-1 a.button.type-4.color-6,
section.section-container.background-4.color-1 a.button.type-4.color-6,
section.section-container.background-0.color-1 span.button.type-4.color-6,
section.section-container.background-4.color-1 span.button.type-4.color-6,
section.section-container.background-0.color-1 button.type-5.color-6,
section.section-container.background-4.color-1 button.type-5.color-6,
section.section-container.background-0.color-1 input[type=submit].type-5.color-6,
section.section-container.background-4.color-1 input[type=submit].type-5.color-6,
section.section-container.background-0.color-1 a.button.type-5.color-6,
section.section-container.background-4.color-1 a.button.type-5.color-6,
section.section-container.background-0.color-1 span.button.type-5.color-6,
section.section-container.background-4.color-1 span.button.type-5.color-6,
section.section-container.background-0.color-1 button.type-2.color-7,
section.section-container.background-4.color-1 button.type-2.color-7,
section.section-container.background-0.color-1 input[type=submit].type-2.color-7,
section.section-container.background-4.color-1 input[type=submit].type-2.color-7,
section.section-container.background-0.color-1 a.button.type-2.color-7,
section.section-container.background-4.color-1 a.button.type-2.color-7,
section.section-container.background-0.color-1 span.button.type-2.color-7,
section.section-container.background-4.color-1 span.button.type-2.color-7,
section.section-container.background-0.color-1 button.type-3.color-7,
section.section-container.background-4.color-1 button.type-3.color-7,
section.section-container.background-0.color-1 input[type=submit].type-3.color-7,
section.section-container.background-4.color-1 input[type=submit].type-3.color-7,
section.section-container.background-0.color-1 a.button.type-3.color-7,
section.section-container.background-4.color-1 a.button.type-3.color-7,
section.section-container.background-0.color-1 span.button.type-3.color-7,
section.section-container.background-4.color-1 span.button.type-3.color-7,
section.section-container.background-0.color-1 button.type-4.color-7,
section.section-container.background-4.color-1 button.type-4.color-7,
section.section-container.background-0.color-1 input[type=submit].type-4.color-7,
section.section-container.background-4.color-1 input[type=submit].type-4.color-7,
section.section-container.background-0.color-1 a.button.type-4.color-7,
section.section-container.background-4.color-1 a.button.type-4.color-7,
section.section-container.background-0.color-1 span.button.type-4.color-7,
section.section-container.background-4.color-1 span.button.type-4.color-7,
section.section-container.background-0.color-1 button.type-5.color-7,
section.section-container.background-4.color-1 button.type-5.color-7,
section.section-container.background-0.color-1 input[type=submit].type-5.color-7,
section.section-container.background-4.color-1 input[type=submit].type-5.color-7,
section.section-container.background-0.color-1 a.button.type-5.color-7,
section.section-container.background-4.color-1 a.button.type-5.color-7,
section.section-container.background-0.color-1 span.button.type-5.color-7,
section.section-container.background-4.color-1 span.button.type-5.color-7,
section.section-container.background-0.color-1 button.type-2.color-8,
section.section-container.background-4.color-1 button.type-2.color-8,
section.section-container.background-0.color-1 input[type=submit].type-2.color-8,
section.section-container.background-4.color-1 input[type=submit].type-2.color-8,
section.section-container.background-0.color-1 a.button.type-2.color-8,
section.section-container.background-4.color-1 a.button.type-2.color-8,
section.section-container.background-0.color-1 span.button.type-2.color-8,
section.section-container.background-4.color-1 span.button.type-2.color-8,
section.section-container.background-0.color-1 button.type-3.color-8,
section.section-container.background-4.color-1 button.type-3.color-8,
section.section-container.background-0.color-1 input[type=submit].type-3.color-8,
section.section-container.background-4.color-1 input[type=submit].type-3.color-8,
section.section-container.background-0.color-1 a.button.type-3.color-8,
section.section-container.background-4.color-1 a.button.type-3.color-8,
section.section-container.background-0.color-1 span.button.type-3.color-8,
section.section-container.background-4.color-1 span.button.type-3.color-8,
section.section-container.background-0.color-1 button.type-4.color-8,
section.section-container.background-4.color-1 button.type-4.color-8,
section.section-container.background-0.color-1 input[type=submit].type-4.color-8,
section.section-container.background-4.color-1 input[type=submit].type-4.color-8,
section.section-container.background-0.color-1 a.button.type-4.color-8,
section.section-container.background-4.color-1 a.button.type-4.color-8,
section.section-container.background-0.color-1 span.button.type-4.color-8,
section.section-container.background-4.color-1 span.button.type-4.color-8,
section.section-container.background-0.color-1 button.type-5.color-8,
section.section-container.background-4.color-1 button.type-5.color-8,
section.section-container.background-0.color-1 input[type=submit].type-5.color-8,
section.section-container.background-4.color-1 input[type=submit].type-5.color-8,
section.section-container.background-0.color-1 a.button.type-5.color-8,
section.section-container.background-4.color-1 a.button.type-5.color-8,
section.section-container.background-0.color-1 span.button.type-5.color-8,
section.section-container.background-4.color-1 span.button.type-5.color-8,
section.section-container.background-0.color-1 button.type-2.color-9,
section.section-container.background-4.color-1 button.type-2.color-9,
section.section-container.background-0.color-1 input[type=submit].type-2.color-9,
section.section-container.background-4.color-1 input[type=submit].type-2.color-9,
section.section-container.background-0.color-1 a.button.type-2.color-9,
section.section-container.background-4.color-1 a.button.type-2.color-9,
section.section-container.background-0.color-1 span.button.type-2.color-9,
section.section-container.background-4.color-1 span.button.type-2.color-9,
section.section-container.background-0.color-1 button.type-3.color-9,
section.section-container.background-4.color-1 button.type-3.color-9,
section.section-container.background-0.color-1 input[type=submit].type-3.color-9,
section.section-container.background-4.color-1 input[type=submit].type-3.color-9,
section.section-container.background-0.color-1 a.button.type-3.color-9,
section.section-container.background-4.color-1 a.button.type-3.color-9,
section.section-container.background-0.color-1 span.button.type-3.color-9,
section.section-container.background-4.color-1 span.button.type-3.color-9,
section.section-container.background-0.color-1 button.type-4.color-9,
section.section-container.background-4.color-1 button.type-4.color-9,
section.section-container.background-0.color-1 input[type=submit].type-4.color-9,
section.section-container.background-4.color-1 input[type=submit].type-4.color-9,
section.section-container.background-0.color-1 a.button.type-4.color-9,
section.section-container.background-4.color-1 a.button.type-4.color-9,
section.section-container.background-0.color-1 span.button.type-4.color-9,
section.section-container.background-4.color-1 span.button.type-4.color-9,
section.section-container.background-0.color-1 button.type-5.color-9,
section.section-container.background-4.color-1 button.type-5.color-9,
section.section-container.background-0.color-1 input[type=submit].type-5.color-9,
section.section-container.background-4.color-1 input[type=submit].type-5.color-9,
section.section-container.background-0.color-1 a.button.type-5.color-9,
section.section-container.background-4.color-1 a.button.type-5.color-9,
section.section-container.background-0.color-1 span.button.type-5.color-9,
section.section-container.background-4.color-1 span.button.type-5.color-9,
section.section-container.background-0.color-1 button.type-2.color-10,
section.section-container.background-4.color-1 button.type-2.color-10,
section.section-container.background-0.color-1 input[type=submit].type-2.color-10,
section.section-container.background-4.color-1 input[type=submit].type-2.color-10,
section.section-container.background-0.color-1 a.button.type-2.color-10,
section.section-container.background-4.color-1 a.button.type-2.color-10,
section.section-container.background-0.color-1 span.button.type-2.color-10,
section.section-container.background-4.color-1 span.button.type-2.color-10,
section.section-container.background-0.color-1 button.type-3.color-10,
section.section-container.background-4.color-1 button.type-3.color-10,
section.section-container.background-0.color-1 input[type=submit].type-3.color-10,
section.section-container.background-4.color-1 input[type=submit].type-3.color-10,
section.section-container.background-0.color-1 a.button.type-3.color-10,
section.section-container.background-4.color-1 a.button.type-3.color-10,
section.section-container.background-0.color-1 span.button.type-3.color-10,
section.section-container.background-4.color-1 span.button.type-3.color-10,
section.section-container.background-0.color-1 button.type-4.color-10,
section.section-container.background-4.color-1 button.type-4.color-10,
section.section-container.background-0.color-1 input[type=submit].type-4.color-10,
section.section-container.background-4.color-1 input[type=submit].type-4.color-10,
section.section-container.background-0.color-1 a.button.type-4.color-10,
section.section-container.background-4.color-1 a.button.type-4.color-10,
section.section-container.background-0.color-1 span.button.type-4.color-10,
section.section-container.background-4.color-1 span.button.type-4.color-10,
section.section-container.background-0.color-1 button.type-5.color-10,
section.section-container.background-4.color-1 button.type-5.color-10,
section.section-container.background-0.color-1 input[type=submit].type-5.color-10,
section.section-container.background-4.color-1 input[type=submit].type-5.color-10,
section.section-container.background-0.color-1 a.button.type-5.color-10,
section.section-container.background-4.color-1 a.button.type-5.color-10,
section.section-container.background-0.color-1 span.button.type-5.color-10,
section.section-container.background-4.color-1 span.button.type-5.color-10 {
    background: #eb6a3e;
    border: 2px solid #eb6a3e;
    color: #ffffff;
}

section.section-container.background-0.color-1 .text-container-wrapper ul li::before,
section.section-container.background-4.color-1 .text-container-wrapper ul li::before,
section.section-container.background-0.color-1 .text-image-wrapper ul li::before,
section.section-container.background-4.color-1 .text-image-wrapper ul li::before,
section.section-container.background-0.color-1 .text-container ul li::before,
section.section-container.background-4.color-1 .text-container ul li::before {
    background-color: #eb6a3e;
}

section.section-container.background-0.color-1 .text-container-wrapper ul li a,
section.section-container.background-4.color-1 .text-container-wrapper ul li a,
section.section-container.background-0.color-1 .text-image-wrapper ul li a,
section.section-container.background-4.color-1 .text-image-wrapper ul li a,
section.section-container.background-0.color-1 .text-container ul li a,
section.section-container.background-4.color-1 .text-container ul li a {
    color: #eb6a3e;
}

section.section-container.background-0.color-1 .text-container-wrapper ol li::before,
section.section-container.background-4.color-1 .text-container-wrapper ol li::before,
section.section-container.background-0.color-1 .text-image-wrapper ol li::before,
section.section-container.background-4.color-1 .text-image-wrapper ol li::before,
section.section-container.background-0.color-1 .text-container ol li::before,
section.section-container.background-4.color-1 .text-container ol li::before {
    background-color: #eb6a3e;
    color: #ffffff;
}

section.section-container.background-0.color-1 .text-container-wrapper ol li a,
section.section-container.background-4.color-1 .text-container-wrapper ol li a,
section.section-container.background-0.color-1 .text-image-wrapper ol li a,
section.section-container.background-4.color-1 .text-image-wrapper ol li a,
section.section-container.background-0.color-1 .text-container ol li a,
section.section-container.background-4.color-1 .text-container ol li a {
    color: #eb6a3e;
}

section.section-container.background-0.color-2,
section.section-container.background-4.color-2 {
    --base-icon-color: #aebd23;
}

section.section-container.background-0.color-2 .headline-container h1.title-line::before,
section.section-container.background-4.color-2 .headline-container h1.title-line::before,
section.section-container.background-0.color-2 .headline-container h2.title-line::before,
section.section-container.background-4.color-2 .headline-container h2.title-line::before,
section.section-container.background-0.color-2 .headline-container h3.title-line::before,
section.section-container.background-4.color-2 .headline-container h3.title-line::before,
section.section-container.background-0.color-2 .headline-container h4.title-line::before,
section.section-container.background-4.color-2 .headline-container h4.title-line::before,
section.section-container.background-0.color-2 .headline-container h5.title-line::before,
section.section-container.background-4.color-2 .headline-container h5.title-line::before,
section.section-container.background-0.color-2 .headline-container h6.title-line::before,
section.section-container.background-4.color-2 .headline-container h6.title-line::before,
section.section-container.background-0.color-2 .headline-container p.title-line::before,
section.section-container.background-4.color-2 .headline-container p.title-line::before,
section.section-container.background-0.color-2 .headline-container b.title-line::before,
section.section-container.background-4.color-2 .headline-container b.title-line::before,
section.section-container.background-0.color-2 .headline-container strong.title-line::before,
section.section-container.background-4.color-2 .headline-container strong.title-line::before,
section.section-container.background-0.color-2 .headline-container i.title-line::before,
section.section-container.background-4.color-2 .headline-container i.title-line::before,
section.section-container.background-0.color-2 .headline-container em.title-line::before,
section.section-container.background-4.color-2 .headline-container em.title-line::before,
section.section-container.background-0.color-2 .headline-container blockquote.title-line::before,
section.section-container.background-4.color-2 .headline-container blockquote.title-line::before,
section.section-container.background-0.color-2 .headline-container span.title-line::before,
section.section-container.background-4.color-2 .headline-container span.title-line::before {
    background-color: #aebd23;
}

section.section-container.background-0.color-2 a.link.type-1::after,
section.section-container.background-4.color-2 a.link.type-1::after,
section.section-container.background-0.color-2 span.link.type-1::after,
section.section-container.background-4.color-2 span.link.type-1::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23aebd23' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-0.color-2 a.link.type-2::after,
section.section-container.background-4.color-2 a.link.type-2::after,
section.section-container.background-0.color-2 span.link.type-2::after,
section.section-container.background-4.color-2 span.link.type-2::after,
section.section-container.background-0.color-2 a.link.type-3::after,
section.section-container.background-4.color-2 a.link.type-3::after,
section.section-container.background-0.color-2 span.link.type-3::after,
section.section-container.background-4.color-2 span.link.type-3::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23aebd23' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-0.color-2 a.link.type-4::before,
section.section-container.background-4.color-2 a.link.type-4::before,
section.section-container.background-0.color-2 span.link.type-4::before,
section.section-container.background-4.color-2 span.link.type-4::before {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23aebd23' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-0.color-2 button.type-1.color-1,
section.section-container.background-4.color-2 button.type-1.color-1,
section.section-container.background-0.color-2 input[type=submit].type-1.color-1,
section.section-container.background-4.color-2 input[type=submit].type-1.color-1,
section.section-container.background-0.color-2 a.button.type-1.color-1,
section.section-container.background-4.color-2 a.button.type-1.color-1,
section.section-container.background-0.color-2 span.button.type-1.color-1,
section.section-container.background-4.color-2 span.button.type-1.color-1,
section.section-container.background-0.color-2 button.type-1.color-2,
section.section-container.background-4.color-2 button.type-1.color-2,
section.section-container.background-0.color-2 input[type=submit].type-1.color-2,
section.section-container.background-4.color-2 input[type=submit].type-1.color-2,
section.section-container.background-0.color-2 a.button.type-1.color-2,
section.section-container.background-4.color-2 a.button.type-1.color-2,
section.section-container.background-0.color-2 span.button.type-1.color-2,
section.section-container.background-4.color-2 span.button.type-1.color-2,
section.section-container.background-0.color-2 button.type-1.color-3,
section.section-container.background-4.color-2 button.type-1.color-3,
section.section-container.background-0.color-2 input[type=submit].type-1.color-3,
section.section-container.background-4.color-2 input[type=submit].type-1.color-3,
section.section-container.background-0.color-2 a.button.type-1.color-3,
section.section-container.background-4.color-2 a.button.type-1.color-3,
section.section-container.background-0.color-2 span.button.type-1.color-3,
section.section-container.background-4.color-2 span.button.type-1.color-3,
section.section-container.background-0.color-2 button.type-1.color-4,
section.section-container.background-4.color-2 button.type-1.color-4,
section.section-container.background-0.color-2 input[type=submit].type-1.color-4,
section.section-container.background-4.color-2 input[type=submit].type-1.color-4,
section.section-container.background-0.color-2 a.button.type-1.color-4,
section.section-container.background-4.color-2 a.button.type-1.color-4,
section.section-container.background-0.color-2 span.button.type-1.color-4,
section.section-container.background-4.color-2 span.button.type-1.color-4,
section.section-container.background-0.color-2 button.type-1.color-5,
section.section-container.background-4.color-2 button.type-1.color-5,
section.section-container.background-0.color-2 input[type=submit].type-1.color-5,
section.section-container.background-4.color-2 input[type=submit].type-1.color-5,
section.section-container.background-0.color-2 a.button.type-1.color-5,
section.section-container.background-4.color-2 a.button.type-1.color-5,
section.section-container.background-0.color-2 span.button.type-1.color-5,
section.section-container.background-4.color-2 span.button.type-1.color-5,
section.section-container.background-0.color-2 button.type-1.color-6,
section.section-container.background-4.color-2 button.type-1.color-6,
section.section-container.background-0.color-2 input[type=submit].type-1.color-6,
section.section-container.background-4.color-2 input[type=submit].type-1.color-6,
section.section-container.background-0.color-2 a.button.type-1.color-6,
section.section-container.background-4.color-2 a.button.type-1.color-6,
section.section-container.background-0.color-2 span.button.type-1.color-6,
section.section-container.background-4.color-2 span.button.type-1.color-6,
section.section-container.background-0.color-2 button.type-1.color-7,
section.section-container.background-4.color-2 button.type-1.color-7,
section.section-container.background-0.color-2 input[type=submit].type-1.color-7,
section.section-container.background-4.color-2 input[type=submit].type-1.color-7,
section.section-container.background-0.color-2 a.button.type-1.color-7,
section.section-container.background-4.color-2 a.button.type-1.color-7,
section.section-container.background-0.color-2 span.button.type-1.color-7,
section.section-container.background-4.color-2 span.button.type-1.color-7,
section.section-container.background-0.color-2 button.type-1.color-8,
section.section-container.background-4.color-2 button.type-1.color-8,
section.section-container.background-0.color-2 input[type=submit].type-1.color-8,
section.section-container.background-4.color-2 input[type=submit].type-1.color-8,
section.section-container.background-0.color-2 a.button.type-1.color-8,
section.section-container.background-4.color-2 a.button.type-1.color-8,
section.section-container.background-0.color-2 span.button.type-1.color-8,
section.section-container.background-4.color-2 span.button.type-1.color-8,
section.section-container.background-0.color-2 button.type-1.color-9,
section.section-container.background-4.color-2 button.type-1.color-9,
section.section-container.background-0.color-2 input[type=submit].type-1.color-9,
section.section-container.background-4.color-2 input[type=submit].type-1.color-9,
section.section-container.background-0.color-2 a.button.type-1.color-9,
section.section-container.background-4.color-2 a.button.type-1.color-9,
section.section-container.background-0.color-2 span.button.type-1.color-9,
section.section-container.background-4.color-2 span.button.type-1.color-9,
section.section-container.background-0.color-2 button.type-1.color-10,
section.section-container.background-4.color-2 button.type-1.color-10,
section.section-container.background-0.color-2 input[type=submit].type-1.color-10,
section.section-container.background-4.color-2 input[type=submit].type-1.color-10,
section.section-container.background-0.color-2 a.button.type-1.color-10,
section.section-container.background-4.color-2 a.button.type-1.color-10,
section.section-container.background-0.color-2 span.button.type-1.color-10,
section.section-container.background-4.color-2 span.button.type-1.color-10 {
    background: #aebd23;
    border: 2px solid #aebd23;
    color: #ffffff;
}

section.section-container.background-0.color-2 button.type-1::after,
section.section-container.background-4.color-2 button.type-1::after,
section.section-container.background-0.color-2 input[type=submit].type-1::after,
section.section-container.background-4.color-2 input[type=submit].type-1::after,
section.section-container.background-0.color-2 a.button.type-1::after,
section.section-container.background-4.color-2 a.button.type-1::after,
section.section-container.background-0.color-2 span.button.type-1::after,
section.section-container.background-4.color-2 span.button.type-1::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ffffff' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
}

section.section-container.background-0.color-2 button.type-2.color-1,
section.section-container.background-4.color-2 button.type-2.color-1,
section.section-container.background-0.color-2 input[type=submit].type-2.color-1,
section.section-container.background-4.color-2 input[type=submit].type-2.color-1,
section.section-container.background-0.color-2 a.button.type-2.color-1,
section.section-container.background-4.color-2 a.button.type-2.color-1,
section.section-container.background-0.color-2 span.button.type-2.color-1,
section.section-container.background-4.color-2 span.button.type-2.color-1,
section.section-container.background-0.color-2 button.type-3.color-1,
section.section-container.background-4.color-2 button.type-3.color-1,
section.section-container.background-0.color-2 input[type=submit].type-3.color-1,
section.section-container.background-4.color-2 input[type=submit].type-3.color-1,
section.section-container.background-0.color-2 a.button.type-3.color-1,
section.section-container.background-4.color-2 a.button.type-3.color-1,
section.section-container.background-0.color-2 span.button.type-3.color-1,
section.section-container.background-4.color-2 span.button.type-3.color-1,
section.section-container.background-0.color-2 button.type-4.color-1,
section.section-container.background-4.color-2 button.type-4.color-1,
section.section-container.background-0.color-2 input[type=submit].type-4.color-1,
section.section-container.background-4.color-2 input[type=submit].type-4.color-1,
section.section-container.background-0.color-2 a.button.type-4.color-1,
section.section-container.background-4.color-2 a.button.type-4.color-1,
section.section-container.background-0.color-2 span.button.type-4.color-1,
section.section-container.background-4.color-2 span.button.type-4.color-1,
section.section-container.background-0.color-2 button.type-5.color-1,
section.section-container.background-4.color-2 button.type-5.color-1,
section.section-container.background-0.color-2 input[type=submit].type-5.color-1,
section.section-container.background-4.color-2 input[type=submit].type-5.color-1,
section.section-container.background-0.color-2 a.button.type-5.color-1,
section.section-container.background-4.color-2 a.button.type-5.color-1,
section.section-container.background-0.color-2 span.button.type-5.color-1,
section.section-container.background-4.color-2 span.button.type-5.color-1,
section.section-container.background-0.color-2 button.type-2.color-2,
section.section-container.background-4.color-2 button.type-2.color-2,
section.section-container.background-0.color-2 input[type=submit].type-2.color-2,
section.section-container.background-4.color-2 input[type=submit].type-2.color-2,
section.section-container.background-0.color-2 a.button.type-2.color-2,
section.section-container.background-4.color-2 a.button.type-2.color-2,
section.section-container.background-0.color-2 span.button.type-2.color-2,
section.section-container.background-4.color-2 span.button.type-2.color-2,
section.section-container.background-0.color-2 button.type-3.color-2,
section.section-container.background-4.color-2 button.type-3.color-2,
section.section-container.background-0.color-2 input[type=submit].type-3.color-2,
section.section-container.background-4.color-2 input[type=submit].type-3.color-2,
section.section-container.background-0.color-2 a.button.type-3.color-2,
section.section-container.background-4.color-2 a.button.type-3.color-2,
section.section-container.background-0.color-2 span.button.type-3.color-2,
section.section-container.background-4.color-2 span.button.type-3.color-2,
section.section-container.background-0.color-2 button.type-4.color-2,
section.section-container.background-4.color-2 button.type-4.color-2,
section.section-container.background-0.color-2 input[type=submit].type-4.color-2,
section.section-container.background-4.color-2 input[type=submit].type-4.color-2,
section.section-container.background-0.color-2 a.button.type-4.color-2,
section.section-container.background-4.color-2 a.button.type-4.color-2,
section.section-container.background-0.color-2 span.button.type-4.color-2,
section.section-container.background-4.color-2 span.button.type-4.color-2,
section.section-container.background-0.color-2 button.type-5.color-2,
section.section-container.background-4.color-2 button.type-5.color-2,
section.section-container.background-0.color-2 input[type=submit].type-5.color-2,
section.section-container.background-4.color-2 input[type=submit].type-5.color-2,
section.section-container.background-0.color-2 a.button.type-5.color-2,
section.section-container.background-4.color-2 a.button.type-5.color-2,
section.section-container.background-0.color-2 span.button.type-5.color-2,
section.section-container.background-4.color-2 span.button.type-5.color-2,
section.section-container.background-0.color-2 button.type-2.color-3,
section.section-container.background-4.color-2 button.type-2.color-3,
section.section-container.background-0.color-2 input[type=submit].type-2.color-3,
section.section-container.background-4.color-2 input[type=submit].type-2.color-3,
section.section-container.background-0.color-2 a.button.type-2.color-3,
section.section-container.background-4.color-2 a.button.type-2.color-3,
section.section-container.background-0.color-2 span.button.type-2.color-3,
section.section-container.background-4.color-2 span.button.type-2.color-3,
section.section-container.background-0.color-2 button.type-3.color-3,
section.section-container.background-4.color-2 button.type-3.color-3,
section.section-container.background-0.color-2 input[type=submit].type-3.color-3,
section.section-container.background-4.color-2 input[type=submit].type-3.color-3,
section.section-container.background-0.color-2 a.button.type-3.color-3,
section.section-container.background-4.color-2 a.button.type-3.color-3,
section.section-container.background-0.color-2 span.button.type-3.color-3,
section.section-container.background-4.color-2 span.button.type-3.color-3,
section.section-container.background-0.color-2 button.type-4.color-3,
section.section-container.background-4.color-2 button.type-4.color-3,
section.section-container.background-0.color-2 input[type=submit].type-4.color-3,
section.section-container.background-4.color-2 input[type=submit].type-4.color-3,
section.section-container.background-0.color-2 a.button.type-4.color-3,
section.section-container.background-4.color-2 a.button.type-4.color-3,
section.section-container.background-0.color-2 span.button.type-4.color-3,
section.section-container.background-4.color-2 span.button.type-4.color-3,
section.section-container.background-0.color-2 button.type-5.color-3,
section.section-container.background-4.color-2 button.type-5.color-3,
section.section-container.background-0.color-2 input[type=submit].type-5.color-3,
section.section-container.background-4.color-2 input[type=submit].type-5.color-3,
section.section-container.background-0.color-2 a.button.type-5.color-3,
section.section-container.background-4.color-2 a.button.type-5.color-3,
section.section-container.background-0.color-2 span.button.type-5.color-3,
section.section-container.background-4.color-2 span.button.type-5.color-3,
section.section-container.background-0.color-2 button.type-2.color-4,
section.section-container.background-4.color-2 button.type-2.color-4,
section.section-container.background-0.color-2 input[type=submit].type-2.color-4,
section.section-container.background-4.color-2 input[type=submit].type-2.color-4,
section.section-container.background-0.color-2 a.button.type-2.color-4,
section.section-container.background-4.color-2 a.button.type-2.color-4,
section.section-container.background-0.color-2 span.button.type-2.color-4,
section.section-container.background-4.color-2 span.button.type-2.color-4,
section.section-container.background-0.color-2 button.type-3.color-4,
section.section-container.background-4.color-2 button.type-3.color-4,
section.section-container.background-0.color-2 input[type=submit].type-3.color-4,
section.section-container.background-4.color-2 input[type=submit].type-3.color-4,
section.section-container.background-0.color-2 a.button.type-3.color-4,
section.section-container.background-4.color-2 a.button.type-3.color-4,
section.section-container.background-0.color-2 span.button.type-3.color-4,
section.section-container.background-4.color-2 span.button.type-3.color-4,
section.section-container.background-0.color-2 button.type-4.color-4,
section.section-container.background-4.color-2 button.type-4.color-4,
section.section-container.background-0.color-2 input[type=submit].type-4.color-4,
section.section-container.background-4.color-2 input[type=submit].type-4.color-4,
section.section-container.background-0.color-2 a.button.type-4.color-4,
section.section-container.background-4.color-2 a.button.type-4.color-4,
section.section-container.background-0.color-2 span.button.type-4.color-4,
section.section-container.background-4.color-2 span.button.type-4.color-4,
section.section-container.background-0.color-2 button.type-5.color-4,
section.section-container.background-4.color-2 button.type-5.color-4,
section.section-container.background-0.color-2 input[type=submit].type-5.color-4,
section.section-container.background-4.color-2 input[type=submit].type-5.color-4,
section.section-container.background-0.color-2 a.button.type-5.color-4,
section.section-container.background-4.color-2 a.button.type-5.color-4,
section.section-container.background-0.color-2 span.button.type-5.color-4,
section.section-container.background-4.color-2 span.button.type-5.color-4,
section.section-container.background-0.color-2 button.type-2.color-5,
section.section-container.background-4.color-2 button.type-2.color-5,
section.section-container.background-0.color-2 input[type=submit].type-2.color-5,
section.section-container.background-4.color-2 input[type=submit].type-2.color-5,
section.section-container.background-0.color-2 a.button.type-2.color-5,
section.section-container.background-4.color-2 a.button.type-2.color-5,
section.section-container.background-0.color-2 span.button.type-2.color-5,
section.section-container.background-4.color-2 span.button.type-2.color-5,
section.section-container.background-0.color-2 button.type-3.color-5,
section.section-container.background-4.color-2 button.type-3.color-5,
section.section-container.background-0.color-2 input[type=submit].type-3.color-5,
section.section-container.background-4.color-2 input[type=submit].type-3.color-5,
section.section-container.background-0.color-2 a.button.type-3.color-5,
section.section-container.background-4.color-2 a.button.type-3.color-5,
section.section-container.background-0.color-2 span.button.type-3.color-5,
section.section-container.background-4.color-2 span.button.type-3.color-5,
section.section-container.background-0.color-2 button.type-4.color-5,
section.section-container.background-4.color-2 button.type-4.color-5,
section.section-container.background-0.color-2 input[type=submit].type-4.color-5,
section.section-container.background-4.color-2 input[type=submit].type-4.color-5,
section.section-container.background-0.color-2 a.button.type-4.color-5,
section.section-container.background-4.color-2 a.button.type-4.color-5,
section.section-container.background-0.color-2 span.button.type-4.color-5,
section.section-container.background-4.color-2 span.button.type-4.color-5,
section.section-container.background-0.color-2 button.type-5.color-5,
section.section-container.background-4.color-2 button.type-5.color-5,
section.section-container.background-0.color-2 input[type=submit].type-5.color-5,
section.section-container.background-4.color-2 input[type=submit].type-5.color-5,
section.section-container.background-0.color-2 a.button.type-5.color-5,
section.section-container.background-4.color-2 a.button.type-5.color-5,
section.section-container.background-0.color-2 span.button.type-5.color-5,
section.section-container.background-4.color-2 span.button.type-5.color-5,
section.section-container.background-0.color-2 button.type-2.color-6,
section.section-container.background-4.color-2 button.type-2.color-6,
section.section-container.background-0.color-2 input[type=submit].type-2.color-6,
section.section-container.background-4.color-2 input[type=submit].type-2.color-6,
section.section-container.background-0.color-2 a.button.type-2.color-6,
section.section-container.background-4.color-2 a.button.type-2.color-6,
section.section-container.background-0.color-2 span.button.type-2.color-6,
section.section-container.background-4.color-2 span.button.type-2.color-6,
section.section-container.background-0.color-2 button.type-3.color-6,
section.section-container.background-4.color-2 button.type-3.color-6,
section.section-container.background-0.color-2 input[type=submit].type-3.color-6,
section.section-container.background-4.color-2 input[type=submit].type-3.color-6,
section.section-container.background-0.color-2 a.button.type-3.color-6,
section.section-container.background-4.color-2 a.button.type-3.color-6,
section.section-container.background-0.color-2 span.button.type-3.color-6,
section.section-container.background-4.color-2 span.button.type-3.color-6,
section.section-container.background-0.color-2 button.type-4.color-6,
section.section-container.background-4.color-2 button.type-4.color-6,
section.section-container.background-0.color-2 input[type=submit].type-4.color-6,
section.section-container.background-4.color-2 input[type=submit].type-4.color-6,
section.section-container.background-0.color-2 a.button.type-4.color-6,
section.section-container.background-4.color-2 a.button.type-4.color-6,
section.section-container.background-0.color-2 span.button.type-4.color-6,
section.section-container.background-4.color-2 span.button.type-4.color-6,
section.section-container.background-0.color-2 button.type-5.color-6,
section.section-container.background-4.color-2 button.type-5.color-6,
section.section-container.background-0.color-2 input[type=submit].type-5.color-6,
section.section-container.background-4.color-2 input[type=submit].type-5.color-6,
section.section-container.background-0.color-2 a.button.type-5.color-6,
section.section-container.background-4.color-2 a.button.type-5.color-6,
section.section-container.background-0.color-2 span.button.type-5.color-6,
section.section-container.background-4.color-2 span.button.type-5.color-6,
section.section-container.background-0.color-2 button.type-2.color-7,
section.section-container.background-4.color-2 button.type-2.color-7,
section.section-container.background-0.color-2 input[type=submit].type-2.color-7,
section.section-container.background-4.color-2 input[type=submit].type-2.color-7,
section.section-container.background-0.color-2 a.button.type-2.color-7,
section.section-container.background-4.color-2 a.button.type-2.color-7,
section.section-container.background-0.color-2 span.button.type-2.color-7,
section.section-container.background-4.color-2 span.button.type-2.color-7,
section.section-container.background-0.color-2 button.type-3.color-7,
section.section-container.background-4.color-2 button.type-3.color-7,
section.section-container.background-0.color-2 input[type=submit].type-3.color-7,
section.section-container.background-4.color-2 input[type=submit].type-3.color-7,
section.section-container.background-0.color-2 a.button.type-3.color-7,
section.section-container.background-4.color-2 a.button.type-3.color-7,
section.section-container.background-0.color-2 span.button.type-3.color-7,
section.section-container.background-4.color-2 span.button.type-3.color-7,
section.section-container.background-0.color-2 button.type-4.color-7,
section.section-container.background-4.color-2 button.type-4.color-7,
section.section-container.background-0.color-2 input[type=submit].type-4.color-7,
section.section-container.background-4.color-2 input[type=submit].type-4.color-7,
section.section-container.background-0.color-2 a.button.type-4.color-7,
section.section-container.background-4.color-2 a.button.type-4.color-7,
section.section-container.background-0.color-2 span.button.type-4.color-7,
section.section-container.background-4.color-2 span.button.type-4.color-7,
section.section-container.background-0.color-2 button.type-5.color-7,
section.section-container.background-4.color-2 button.type-5.color-7,
section.section-container.background-0.color-2 input[type=submit].type-5.color-7,
section.section-container.background-4.color-2 input[type=submit].type-5.color-7,
section.section-container.background-0.color-2 a.button.type-5.color-7,
section.section-container.background-4.color-2 a.button.type-5.color-7,
section.section-container.background-0.color-2 span.button.type-5.color-7,
section.section-container.background-4.color-2 span.button.type-5.color-7,
section.section-container.background-0.color-2 button.type-2.color-8,
section.section-container.background-4.color-2 button.type-2.color-8,
section.section-container.background-0.color-2 input[type=submit].type-2.color-8,
section.section-container.background-4.color-2 input[type=submit].type-2.color-8,
section.section-container.background-0.color-2 a.button.type-2.color-8,
section.section-container.background-4.color-2 a.button.type-2.color-8,
section.section-container.background-0.color-2 span.button.type-2.color-8,
section.section-container.background-4.color-2 span.button.type-2.color-8,
section.section-container.background-0.color-2 button.type-3.color-8,
section.section-container.background-4.color-2 button.type-3.color-8,
section.section-container.background-0.color-2 input[type=submit].type-3.color-8,
section.section-container.background-4.color-2 input[type=submit].type-3.color-8,
section.section-container.background-0.color-2 a.button.type-3.color-8,
section.section-container.background-4.color-2 a.button.type-3.color-8,
section.section-container.background-0.color-2 span.button.type-3.color-8,
section.section-container.background-4.color-2 span.button.type-3.color-8,
section.section-container.background-0.color-2 button.type-4.color-8,
section.section-container.background-4.color-2 button.type-4.color-8,
section.section-container.background-0.color-2 input[type=submit].type-4.color-8,
section.section-container.background-4.color-2 input[type=submit].type-4.color-8,
section.section-container.background-0.color-2 a.button.type-4.color-8,
section.section-container.background-4.color-2 a.button.type-4.color-8,
section.section-container.background-0.color-2 span.button.type-4.color-8,
section.section-container.background-4.color-2 span.button.type-4.color-8,
section.section-container.background-0.color-2 button.type-5.color-8,
section.section-container.background-4.color-2 button.type-5.color-8,
section.section-container.background-0.color-2 input[type=submit].type-5.color-8,
section.section-container.background-4.color-2 input[type=submit].type-5.color-8,
section.section-container.background-0.color-2 a.button.type-5.color-8,
section.section-container.background-4.color-2 a.button.type-5.color-8,
section.section-container.background-0.color-2 span.button.type-5.color-8,
section.section-container.background-4.color-2 span.button.type-5.color-8,
section.section-container.background-0.color-2 button.type-2.color-9,
section.section-container.background-4.color-2 button.type-2.color-9,
section.section-container.background-0.color-2 input[type=submit].type-2.color-9,
section.section-container.background-4.color-2 input[type=submit].type-2.color-9,
section.section-container.background-0.color-2 a.button.type-2.color-9,
section.section-container.background-4.color-2 a.button.type-2.color-9,
section.section-container.background-0.color-2 span.button.type-2.color-9,
section.section-container.background-4.color-2 span.button.type-2.color-9,
section.section-container.background-0.color-2 button.type-3.color-9,
section.section-container.background-4.color-2 button.type-3.color-9,
section.section-container.background-0.color-2 input[type=submit].type-3.color-9,
section.section-container.background-4.color-2 input[type=submit].type-3.color-9,
section.section-container.background-0.color-2 a.button.type-3.color-9,
section.section-container.background-4.color-2 a.button.type-3.color-9,
section.section-container.background-0.color-2 span.button.type-3.color-9,
section.section-container.background-4.color-2 span.button.type-3.color-9,
section.section-container.background-0.color-2 button.type-4.color-9,
section.section-container.background-4.color-2 button.type-4.color-9,
section.section-container.background-0.color-2 input[type=submit].type-4.color-9,
section.section-container.background-4.color-2 input[type=submit].type-4.color-9,
section.section-container.background-0.color-2 a.button.type-4.color-9,
section.section-container.background-4.color-2 a.button.type-4.color-9,
section.section-container.background-0.color-2 span.button.type-4.color-9,
section.section-container.background-4.color-2 span.button.type-4.color-9,
section.section-container.background-0.color-2 button.type-5.color-9,
section.section-container.background-4.color-2 button.type-5.color-9,
section.section-container.background-0.color-2 input[type=submit].type-5.color-9,
section.section-container.background-4.color-2 input[type=submit].type-5.color-9,
section.section-container.background-0.color-2 a.button.type-5.color-9,
section.section-container.background-4.color-2 a.button.type-5.color-9,
section.section-container.background-0.color-2 span.button.type-5.color-9,
section.section-container.background-4.color-2 span.button.type-5.color-9,
section.section-container.background-0.color-2 button.type-2.color-10,
section.section-container.background-4.color-2 button.type-2.color-10,
section.section-container.background-0.color-2 input[type=submit].type-2.color-10,
section.section-container.background-4.color-2 input[type=submit].type-2.color-10,
section.section-container.background-0.color-2 a.button.type-2.color-10,
section.section-container.background-4.color-2 a.button.type-2.color-10,
section.section-container.background-0.color-2 span.button.type-2.color-10,
section.section-container.background-4.color-2 span.button.type-2.color-10,
section.section-container.background-0.color-2 button.type-3.color-10,
section.section-container.background-4.color-2 button.type-3.color-10,
section.section-container.background-0.color-2 input[type=submit].type-3.color-10,
section.section-container.background-4.color-2 input[type=submit].type-3.color-10,
section.section-container.background-0.color-2 a.button.type-3.color-10,
section.section-container.background-4.color-2 a.button.type-3.color-10,
section.section-container.background-0.color-2 span.button.type-3.color-10,
section.section-container.background-4.color-2 span.button.type-3.color-10,
section.section-container.background-0.color-2 button.type-4.color-10,
section.section-container.background-4.color-2 button.type-4.color-10,
section.section-container.background-0.color-2 input[type=submit].type-4.color-10,
section.section-container.background-4.color-2 input[type=submit].type-4.color-10,
section.section-container.background-0.color-2 a.button.type-4.color-10,
section.section-container.background-4.color-2 a.button.type-4.color-10,
section.section-container.background-0.color-2 span.button.type-4.color-10,
section.section-container.background-4.color-2 span.button.type-4.color-10,
section.section-container.background-0.color-2 button.type-5.color-10,
section.section-container.background-4.color-2 button.type-5.color-10,
section.section-container.background-0.color-2 input[type=submit].type-5.color-10,
section.section-container.background-4.color-2 input[type=submit].type-5.color-10,
section.section-container.background-0.color-2 a.button.type-5.color-10,
section.section-container.background-4.color-2 a.button.type-5.color-10,
section.section-container.background-0.color-2 span.button.type-5.color-10,
section.section-container.background-4.color-2 span.button.type-5.color-10 {
    background: #aebd23;
    border: 2px solid #aebd23;
    color: #ffffff;
}

section.section-container.background-0.color-2 .text-container-wrapper ul li::before,
section.section-container.background-4.color-2 .text-container-wrapper ul li::before,
section.section-container.background-0.color-2 .text-image-wrapper ul li::before,
section.section-container.background-4.color-2 .text-image-wrapper ul li::before,
section.section-container.background-0.color-2 .text-container ul li::before,
section.section-container.background-4.color-2 .text-container ul li::before {
    background-color: #aebd23;
}

section.section-container.background-0.color-2 .text-container-wrapper ul li a,
section.section-container.background-4.color-2 .text-container-wrapper ul li a,
section.section-container.background-0.color-2 .text-image-wrapper ul li a,
section.section-container.background-4.color-2 .text-image-wrapper ul li a,
section.section-container.background-0.color-2 .text-container ul li a,
section.section-container.background-4.color-2 .text-container ul li a {
    color: #aebd23;
}

section.section-container.background-0.color-2 .text-container-wrapper ol li::before,
section.section-container.background-4.color-2 .text-container-wrapper ol li::before,
section.section-container.background-0.color-2 .text-image-wrapper ol li::before,
section.section-container.background-4.color-2 .text-image-wrapper ol li::before,
section.section-container.background-0.color-2 .text-container ol li::before,
section.section-container.background-4.color-2 .text-container ol li::before {
    background-color: #aebd23;
    color: #ffffff;
}

section.section-container.background-0.color-2 .text-container-wrapper ol li a,
section.section-container.background-4.color-2 .text-container-wrapper ol li a,
section.section-container.background-0.color-2 .text-image-wrapper ol li a,
section.section-container.background-4.color-2 .text-image-wrapper ol li a,
section.section-container.background-0.color-2 .text-container ol li a,
section.section-container.background-4.color-2 .text-container ol li a {
    color: #aebd23;
}

section.section-container .icon-text-container svg {
    max-width: 75px;
    min-height: 75px;
}

.section-container {
  scroll-margin-top: 3em;
}

.counter-container-items.green .count {
  color: #aebd23 !important;
}

.counter-container-items.dark-orange .count {
  color: #eb6a3e !important;
}


.tab-head-container.card-category-selection--tab-head-container {
  display: none;
}


.tab-card-option-container .card-stream-container {
    aspect-ratio: 570 / 365;
    &__element {
        width: 100%;
        max-width: 570px;
        aspect-ratio: 570 / 365;
        margin: 0 auto;
        display: block !important;
        .card-element {
            aspect-ratio: 570 / 365;
            position: relative;
            figure {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                img {
                    object-fit: contain;
                    object-position: center center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

#chatbase-bubble-button{
    margin: 20px !important;
}

#chatbase-message-bubbles{
    width: 200px !important;
    margin-right: 10px !important;
}

.main-content {
    table {
        th, td {
            padding: 0.5em;
        }

        th {
            background: #ea9100;
            color: white;
        }

        tr:not(:last-child) {
            border-bottom: 2px solid #ea9100;
        }
    }

    .text-container-wrapper:has(table) {
        overflow-x: auto;
    }
}

.frc-captcha {
    margin-top: 5px;
}