

footer {
    width: 100%;
    background: #1a2025;
    padding-bottom: 40px;
}

footer .footer-main-container {
    padding: 80px 0 15px;
}

footer .main-content {
    max-width: 1200px;
}

footer .footer-main-container .flex-col :first-child {
    z-index: 1;
}

footer .footer-main-container .footer-content-block {
    margin: 0 0 25px;
}

footer .footer-main-container .footer-content-block .headline {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #ffffff;
    margin-bottom: 15px;
}

footer .footer-main-container .footer-content-block .footer-text {
    font-size: 23px;
    line-height: 27px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 24px;
    display: block;
}

footer .company-logo {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

footer .footer-main-container .footer-content-block .link-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

footer .footer-main-container .footer-content-block .link-block li {
    margin-bottom: 10px;
}

footer .footer-main-container .footer-content-block .link-block li:last-child {
    margin: 0;
}

footer .footer-main-container .footer-content-block .link-block li > a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
}

footer .footer-main-container .footer-content-block .link-block li.image-link {
    margin-bottom: 25px;
}

footer .footer-main-container .footer-content-block .row-inner .link-block li {
    margin-bottom: 5px;
}

footer .footer-main-container .footer-content-block .row-inner .link-block li:last-child {
    margin: 0;
}

footer .footer-main-container .footer-content-block .newsletter-container {
    margin-top: 40px;
    width: 100%;
}

footer .footer-main-container .footer-content-block .newsletter-container form {
    width: 100%;
    max-width: 230px;
    position: relative;
}

footer .footer-main-container .footer-content-block .newsletter-container form input[type="email"],
footer .footer-main-container .footer-content-block .newsletter-container form input[type="text"] {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    width: 100%;
    border-radius: 120px;
    height: 40px;
    background-color: #ffffff;
    padding: 5px 45px 5px 16px;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    border: 2px solid #ffffff;
}

footer .footer-main-container .footer-content-block .newsletter-container form input[type="email"]:focus,
footer .footer-main-container .footer-content-block .newsletter-container form input[type="text"]:focus {
    border: 2px solid #ea9100;
    outline: 0 !important;
    border-radius: 250px;
}

footer .footer-main-container .footer-content-block .newsletter-container form input[type="submit"],
footer .footer-main-container .footer-content-block .newsletter-container form button[type="submit"] {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 2;
    font-size: 0;
}

footer .footer-main-container .footer-content-block .newsletter-container .section-element,
footer .footer-main-container .footer-content-block .newsletter-container .flex-col,
footer .footer-main-container .footer-content-block .newsletter-container .col {
    padding: 0;
}

footer .footer-main-container .footer-content-block .social-container {
    margin-bottom: 40px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
}

footer .footer-main-container .footer-content-block .social-container svg {
    width: 40px;
    height: 40px;
}

footer .footer-main-container .footer-content-block .social-container li {
    margin-right: 15px;
}

footer .footer-main-container .footer-content-block .social-container li:last-child {
    margin: 0;
}

footer .footer-main-container .footer-content-block .company-copyright-text {
    width: 100%;
    margin-top: 40px;
    display: block;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
}

footer .footer-main-container .footer-content__under .company-seal {
    margin-bottom: 40px;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;
    display: flex;
    gap: 40px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    a {
        max-width: 125px;
    }

    @media screen and (max-width: 768px) {
        a {
            max-width: 75px;
        }
    }

    a[href="/resources/build/images/template/footer/logos/kununu_TopCompanyBadge_2023.png"] {
        padding-top: 3px;
    }
}

footer .footer-logo-container {
    width: 100%;
    background-color: #0a0f14;
    padding: 30px 0;
}

footer .footer-link-container {
    width: 100%;
    padding: 10px 0 30px;
}

footer .footer-link-container .footer-logo-row,
footer .footer-logo-container .footer-logo-row,
footer .footer-link-container .footer-link-row,
footer .footer-logo-container .footer-link-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

footer .footer-link-container .footer-logo-row li,
footer .footer-logo-container .footer-logo-row li,
footer .footer-link-container .footer-link-row li,
footer .footer-logo-container .footer-link-row li {
    margin: 5px 0;
    padding: 0 30px;
}

footer .footer-link-container .footer-logo-row li > a,
footer .footer-logo-container .footer-logo-row li > a,
footer .footer-link-container .footer-link-row li > a,
footer .footer-logo-container .footer-link-row li > a {
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.8rem;
}

footer .footer-link-container .footer-logo-row li > a img,
footer .footer-logo-container .footer-logo-row li > a img,
footer .footer-link-container .footer-link-row li > a img,
footer .footer-logo-container .footer-link-row li > a img {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

footer .footer-main-container .footer-content-block .link-block {
     &.link-block--row {
         flex-direction: row;
         gap: 10px;
     }

    li {
        list-style-type: none;
    }
}

.footer-content {
    display: grid;
    grid-template-columns: 28% 42% 30%;
    grid-template-rows: 1fr max-content;
}

.footer-content__left {
    padding-right: 40px;
}

.footer-content__middle {
    padding-left: 70px;
    padding-right: 40px;
    border-left: 1px solid #4b4b4b;
    border-right: 1px solid #4b4b4b;
}

.footer-content__right {
    padding-left: 70px;
}

.footer-content__right .link-block {
    margin-bottom: 35px;
}

.footer-content__under {
    grid-column-start: 1;
    grid-column-end: 4;
    text-align: center;
    margin-top: 30px;
}

@media screen and (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content max-content max-content;
        padding: 0 30px;
    }

    footer .footer-main-container .footer-content-block {
        margin: 0;
    }

    .footer-content__left, .footer-content__middle, .footer-content__right {
        padding: 0;
        border: 0;
    }

    .footer-content__under {
        grid-column-start: 1;
        grid-column-end: 1;
    }

    .footer-content__middle {
        border-top: 1px solid #4b4b4b;
        border-bottom: 1px solid #4b4b4b;
        padding-top: 30px;
    }

    .footer-content__right {
        padding-top: 30px;
    }

    .footer-content__left {
        padding-bottom: 30px;
    }
}