@media only screen and (min-width: 768px) and (max-width: 1219px) {
    .main-content {
        padding: 0 20px;
    }
}

/* border-radius
default: 4px - overrides:
top: 4px 4px 0 0
bottom: 0 0 4px 4px
left: 4px 0 0 4px
right: 0 4px 4px 0
*/
.flex-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.box-start {
    justify-content: flex-start;
}

.box-end {
    justify-content: flex-end;
}

.box-center {
    justify-content: center;
}

.box-align-center {
    align-items: center;
}

.box-align-start {
    align-items: flex-start;
}

.box-align-end {
    align-items: flex-end;
}

@media only screen and (min-width: 1px) {
    .xs-1 {
        width: 8.33333333%;
    }

    .xs-2 {
        width: 16.66666667%;
    }

    .xs-3 {
        width: 25%;
    }

    .xs-4 {
        width: 33.33333333%;
    }

    .xs-5 {
        width: 41.66666667%;
    }

    .xs-6 {
        width: 50%;
    }

    .xs-7 {
        width: 58.33333333%;
    }

    .xs-8 {
        width: 66.66666667%;
    }

    .xs-9 {
        width: 75%;
    }

    .xs-10 {
        width: 83.33333333%;
    }

    .xs-11 {
        width: 91.66666667%;
    }

    .xs-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 400px) {
    .s-1 {
        width: 8.33333333%;
    }

    .s-2 {
        width: 16.66666667%;
    }

    .s-3 {
        width: 25%;
    }

    .s-4 {
        width: 33.33333333%;
    }

    .s-5 {
        width: 41.66666667%;
    }

    .s-6 {
        width: 50%;
    }

    .s-7 {
        width: 58.33333333%;
    }

    .s-8 {
        width: 66.66666667%;
    }

    .s-9 {
        width: 75%;
    }

    .s-10 {
        width: 83.33333333%;
    }

    .s-11 {
        width: 91.66666667%;
    }

    .s-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 640px) {
    .sm-1 {
        width: 8.33333333%;
    }

    .sm-2 {
        width: 16.66666667%;
    }

    .sm-3 {
        width: 25%;
    }

    .sm-4 {
        width: 33.33333333%;
    }

    .sm-5 {
        width: 41.66666667%;
    }

    .sm-6 {
        width: 50%;
    }

    .sm-7 {
        width: 58.33333333%;
    }

    .sm-8 {
        width: 66.66666667%;
    }

    .sm-9 {
        width: 75%;
    }

    .sm-10 {
        width: 83.33333333%;
    }

    .sm-11 {
        width: 91.66666667%;
    }

    .sm-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .m-1 {
        width: 8.33333333%;
    }

    .m-2 {
        width: 16.66666667%;
    }

    .m-3 {
        width: 25%;
    }

    .m-4 {
        width: 33.33333333%;
    }

    .m-5 {
        width: 41.66666667%;
    }

    .m-6 {
        width: 50%;
    }

    .m-7 {
        width: 58.33333333%;
    }

    .m-8 {
        width: 66.66666667%;
    }

    .m-9 {
        width: 75%;
    }

    .m-10 {
        width: 83.33333333%;
    }

    .m-11 {
        width: 91.66666667%;
    }

    .m-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 1024px) {
    .l-1 {
        width: 8.33333333%;
    }

    .l-2 {
        width: 16.66666667%;
    }

    .l-3 {
        width: 25%;
    }

    .l-4 {
        width: 33.33333333%;
    }

    .l-5 {
        width: 41.66666667%;
    }

    .l-6 {
        width: 50%;
    }

    .l-7 {
        width: 58.33333333%;
    }

    .l-8 {
        width: 66.66666667%;
    }

    .l-9 {
        width: 75%;
    }

    .l-10 {
        width: 83.33333333%;
    }

    .l-11 {
        width: 91.66666667%;
    }

    .l-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 1220px) {
    .xl-1 {
        width: 8.33333333%;
    }

    .xl-2 {
        width: 16.66666667%;
    }

    .xl-3 {
        width: 25%;
    }

    .xl-4 {
        width: 33.33333333%;
    }

    .xl-5 {
        width: 41.66666667%;
    }

    .xl-6 {
        width: 50%;
    }

    .xl-7 {
        width: 58.33333333%;
    }

    .xl-8 {
        width: 66.66666667%;
    }

    .xl-9 {
        width: 75%;
    }

    .xl-10 {
        width: 83.33333333%;
    }

    .xl-11 {
        width: 91.66666667%;
    }

    .xl-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 1440px) {
    .xxl-1 {
        width: 8.33333333%;
    }

    .xxl-2 {
        width: 16.66666667%;
    }

    .xxl-3 {
        width: 25%;
    }

    .xxl-4 {
        width: 33.33333333%;
    }

    .xxl-5 {
        width: 41.66666667%;
    }

    .xxl-6 {
        width: 50%;
    }

    .xxl-7 {
        width: 58.33333333%;
    }

    .xxl-8 {
        width: 66.66666667%;
    }

    .xxl-9 {
        width: 75%;
    }

    .xxl-10 {
        width: 83.33333333%;
    }

    .xxl-11 {
        width: 91.66666667%;
    }

    .xxl-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 1660px) {
    .l-xs-1 {
        width: 8.33333333%;
    }

    .l-xs-2 {
        width: 16.66666667%;
    }

    .l-xs-3 {
        width: 25%;
    }

    .l-xs-4 {
        width: 33.33333333%;
    }

    .l-xs-5 {
        width: 41.66666667%;
    }

    .l-xs-6 {
        width: 50%;
    }

    .l-xs-7 {
        width: 58.33333333%;
    }

    .l-xs-8 {
        width: 66.66666667%;
    }

    .l-xs-9 {
        width: 75%;
    }

    .l-xs-10 {
        width: 83.33333333%;
    }

    .l-xs-11 {
        width: 91.66666667%;
    }

    .l-xs-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 1900px) {
    .l-s-1 {
        width: 8.33333333%;
    }

    .l-s-2 {
        width: 16.66666667%;
    }

    .l-s-3 {
        width: 25%;
    }

    .l-s-4 {
        width: 33.33333333%;
    }

    .l-s-5 {
        width: 41.66666667%;
    }

    .l-s-6 {
        width: 50%;
    }

    .l-s-7 {
        width: 58.33333333%;
    }

    .l-s-8 {
        width: 66.66666667%;
    }

    .l-s-9 {
        width: 75%;
    }

    .l-s-10 {
        width: 83.33333333%;
    }

    .l-s-11 {
        width: 91.66666667%;
    }

    .l-s-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 2500px) {
    .l-sm-1 {
        width: 8.33333333%;
    }

    .l-sm-2 {
        width: 16.66666667%;
    }

    .l-sm-3 {
        width: 25%;
    }

    .l-sm-4 {
        width: 33.33333333%;
    }

    .l-sm-5 {
        width: 41.66666667%;
    }

    .l-sm-6 {
        width: 50%;
    }

    .l-sm-7 {
        width: 58.33333333%;
    }

    .l-sm-8 {
        width: 66.66666667%;
    }

    .l-sm-9 {
        width: 75%;
    }

    .l-sm-10 {
        width: 83.33333333%;
    }

    .l-sm-11 {
        width: 91.66666667%;
    }

    .l-sm-12 {
        width: 100%;
    }
}

@media only screen and (min-width: 3500px) {
    .l-m-1 {
        width: 8.33333333%;
    }

    .l-m-2 {
        width: 16.66666667%;
    }

    .l-m-3 {
        width: 25%;
    }

    .l-m-4 {
        width: 33.33333333%;
    }

    .l-m-5 {
        width: 41.66666667%;
    }

    .l-m-6 {
        width: 50%;
    }

    .l-m-7 {
        width: 58.33333333%;
    }

    .l-m-8 {
        width: 66.66666667%;
    }

    .l-m-9 {
        width: 75%;
    }

    .l-m-10 {
        width: 83.33333333%;
    }

    .l-m-11 {
        width: 91.66666667%;
    }

    .l-m-12 {
        width: 100%;
    }
}

