@mixin loginWidget {
  .login-container-wrapper {
    position: relative;
    .login-button {
      font-size: 1.4rem;
        padding: 7px 12px;
    }
    .nav-login-container {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      will-change: transform;
      background: #ffffff;
      border-radius: 6px;
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      &.active {
        opacity: 1;
        z-index: 10;
        pointer-events: auto;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }
      ul {
        width: 100%;
        li {
          width: 100%;
          margin-bottom: 10px;
          position: relative;
          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            background: rgba(26, 32, 37, 0.15);
            height: 1.5px;
            border-radius: 5px;
            -webkit-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
          }
          &:last-child {
            margin: 0;
            &::after {
              content: none;
            }
          }
          a {
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2rem;
            padding: 10px 14px;
            border-radius: 3px;
            width: 100%;
            display: block;
            text-align: center;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.navigation-elements {
  @include loginWidget;
  @media (max-width: 1023px) {
    .login-container-wrapper {
      display: block;
    }
  }
  @media (min-width: 1024px) {
    .login-container-wrapper {
      display: none;
    }
  }
}
.additional-navigation-login {
  @include loginWidget;
  padding-right: 12px;
  @media (max-width: 1023px) {
    display: none;
  }
}


.login-container {
  width: 100%;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: #f1f1f1;
  * {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  .login-logo {
    padding: 15px;
    text-align: center;
    margin-bottom: 45px;
    border-radius: 10px;
  
    @media (min-width: 768px) {
      background-color: #f1f1f1;
    }
  }
  .login-mask {
    background-color: hsla(0,0%,100%,.95);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  
    @media (min-width: 768px) {
      .login-page & {
        background-color: transparent;
      }
    }
  }
  .login-mask .login-outer {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
  
    @media (min-width: 768px) {
      .login-page & {
        background-color: #fff;
        box-shadow: 0 0 13px -2px #48484840;
        border-radius: 10px;
      }
    }
  }
  .login-mask .login-content {
    width: 100%;
    text-align: center;
  }
  .login-mask .login-content--heading {
    font-weight: 600;
    padding: 0 50px;
    line-height: 28px;
    font-size: 20px;
    font-family: Titillium Web,sans-serif;
  }
  .login-mask .login-content--text {
    font-size: 14px;
    margin-top: 12px;
    text-align: center;
    font-family: Source Sans Pro,sans-serif;
  }
  .login-mask .login-content img {
    display: inline-block;
    margin-bottom: 15px;
  }
  .login-mask .login-form {
    width: 100%;
    margin-top: 26px;
  }
  .login-mask .login-form form {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .login-mask .login-form form.logout p {
    text-align: center;
  }
  .login-mask .login-form form.logout a {
    width: 100%;
    text-align: center;
  }
  .login-mask .login-form form.logout input[type=submit] {
    border: none;
    background: transparent;
    color: #eb6a3e;
  }
  .login-mask input[type=submit] {
    background-color: #f2941c;
    border-color: #f2941c;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    letter-spacing: 1px;
  
    &:focus,
    &:hover {
      background-color: #1a2025;
      border-color: #1a2025;
    }
  }
  .login-mask .form-field input.form-control {
    border-radius: 0;
    border: 0;
    border-bottom: 3px solid #d8d7d7;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 7px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
  }
  .login-mask .form-field {
    width: 100%;
    margin: 0 0 25px 0;
  }
  .login-mask .form-field label {
    display: none;
    width: 100%;
    padding: 0 0 6px 0;
    margin: 0;
    font-weight: 700;
  }
  .login-mask .form-field input {
    width: 100%;
    margin-bottom: 8px;
    border-radius: 6px;
    background: transparent;
    border: 1px solid #dde5f0;
  }
  .login-mask p.login-error {
    width: 100%;
    padding: 0;
    margin: 0;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #d53e27;
  }
  .login-mask input[type="submit"] {
    width: 100%;
    margin: 20px 0 0 0;
  }
  .login-mask.logout .login-form {
    border-radius: 10px;
    margin-top: 0;
  
    @media (min-width: 768px) {
      background-color: #fff;
      box-shadow: 0 0 13px -2px rgb(72 72 72 / 25%);
      padding: 30px 45px;
    }
  }
  .login-mask.logout .login-form form.logout a {
    background-color: #f2941c;
    border-color: #f2941c;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    letter-spacing: 1px;
  
    &:focus,
    &:hover {
      background-color: #1a2025;
      border-color: #1a2025;
    }
  }
  .login-mask.logout .login-form form.logout input[type=submit] {
    color: #f2941c;
    background-color: transparent;
    border-color: transparent;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    letter-spacing: 1px;
  
    &:focus,
    &:hover {
      color: #1a2025;
    }
  }
  .login-mask.logout .login-form form.logout p {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
  }
  .login-mask.logout .login-form form.logout .form-group--value {
    font-weight: 600;
    margin-top: 5px;
    display: inline-block;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
  }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .login-container {
    display: none;
  }

  .login-container.active {
    display: block;
  }
}

body.login-page {
  padding: 0;
}

body.login-page .section-container {
  padding: 0;
}