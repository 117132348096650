.footer {
    direction: ltr !important;
}

.navigation-container {
    direction: ltr !important;
}



@media only screen and (max-width: 639px) {
    .form-row .form-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }

    .form-row .input-field-s {
        width: 100%;
    }

    .form-row .input-field-m {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .flex-col.row-inner {
        margin-left: -30px;
        margin-right: -30px;
    }

    .flex-col.content {
        padding-right: 30px;
        padding-left: 30px;
    }

    .flex-col .col {
        padding-right: 30px;
        padding-left: 30px;
    }

    .pagination-container .count {
        display: none;
    }

    .headline-container {
        padding-bottom: 30px;
    }

    #cookie-hint-container.cookie-dialog .cookie-text {
        margin: 20px 0;
    }

    #cookie-hint-container.cookie-dialog .cookie-button {
        margin: 0 0 20px 0;
    }

    .counter-container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .counter-container .counter-container-items {
        align-items: center;
    }

    .counter-container.layout-1 {
        padding: 25px 0;
    }

    .counter-container.layout-1::before {
        -webkit-transform: translate(20%, 10%) rotate(-25deg);
        -moz-transform: translate(20%, 10%) rotate(-25deg);
        -o-transform: translate(20%, 10%) rotate(-25deg);
        -ms-transform: translate(20%, 10%) rotate(-25deg);
        transform: translate(20%, 10%) rotate(-25deg);
        width: 65%;
        height: 300px;
    }

    .counter-container.layout-1 .counter-content {
        padding: 16px 12px;
        margin: 15px 0 0;
        width: 100%;
    }

    .counter-container.layout-1 .counter-content.counter-position-2 {
        justify-content: center;
    }

    .counter-container.layout-2 .counter-container-items {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .counter-container.layout-2 .counter-content {
        padding: 16px 12px;
        margin: 15px 0 0;
        width: 100%;
    }

    .counter-container.layout-2 .counter-content.counter-position-2 {
        justify-content: center;
    }

    .counter-container.layout-1 .counter-content,
    .counter-container.layout-2 .counter-content {
        text-align: center;
    }

    .counter-container.layout-1 .counter-content .count,
    .counter-container.layout-2 .counter-content .count {
        font-size: 3rem;
        line-height: 3.6rem;
    }

    .counter-container.layout-1 .counter-content .count-description,
    .counter-container.layout-2 .counter-content .count-description {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    .slider-element-container .slider-quote-wrapper {
        flex-direction: column;
    }

    .slider-element-container .slider-quote-wrapper .slider-quote-image {
        align-items: flex-start;
        padding-bottom: 20px;
        color: #ea9100;
    }

    .slider-element-container .slider-quote-wrapper .slider-quote-content blockquote,
    .slider-element-container .slider-quote-wrapper .slider-quote-content p,
    .slider-element-container .slider-quote-wrapper .slider-quote-content ul {
        font-size: 2rem;
        line-height: 2.6rem;
    }

    .slider-element-container .slider-quote-wrapper .slider-quote-content .quote-author::before {
        content: '';
        position: absolute;
        top: 0;
        width: 51px;
        height: 4px;
        background-color: #ea9100;
    }

    .stage-element-container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
    }

    .stage-element-container::after {
        display: none;
    }

    .stage-element-container.layout-1 {
        height: auto;
        min-height: auto;
    }

    .stage-element-container.layout-1 .stage-image-block {
        order: 1;
    }

    .stage-element-container.layout-1 .stage-content-wrapper {
        order: 2;
    }

    .stage-element-container.layout-2 {
        height: auto;
        min-height: auto;
        padding-top: 45px;
    }

    .stage-element-container.layout-2 .stage-content-wrapper {
        flex-direction: column;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .stage-content-block {
        padding: 15px 30px 35px;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .stage-content-block .main-content {
        height: 100%;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .stage-image-block {
        max-width: 60%;
        padding: 55px 0 0 35px;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .col:nth-child(1) {
        order: 2;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .col:nth-child(2) {
        order: 1;
    }

    .teaser.layout-1 {
        padding: 30px 20px;
    }

    .teaser.layout-3 {
        flex-direction: column;
    }

    .teaser.layout-3 .teaser-image-block {
        width: 100%;
    }

    .teaser.layout-3.type-1 .teaser-image-block {
        width: 100%;
    }

    .teaser.layout-3.type-1 .teaser-content {
        width: 100%;
    }

    .teaser.layout-3.type-2 .teaser-image-block {
        order: 1;
        width: 100%;
    }

    .teaser.layout-3.type-2 .teaser-content {
        order: 2;
        width: 100%;
    }

    .tab-element-container .tab-head-container ul,
    .tab-card-option-container .tab-head-container ul {
        width: 100%;
        flex-flow: column;
        align-items: flex-start;
    }

    .tab-element-container .tab-head-container ul li,
    .tab-card-option-container .tab-head-container ul li {
        width: 100%;
    }

    .tab-element-container .tab-head-container ul li > a,
    .tab-card-option-container .tab-head-container ul li > a {
        padding: 12px 20px 15px;
        background: rgba(26, 32, 37, 0.04);
        border-bottom: 1px solid rgba(26, 32, 37, 0.1);
        width: 100%;
        flex-direction: row;
        align-items: center;
    }

    .tab-element-container .tab-head-container ul li > a img,
    .tab-card-option-container .tab-head-container ul li > a img,
    .tab-element-container .tab-head-container ul li > a svg,
    .tab-card-option-container .tab-head-container ul li > a svg {
        margin-right: 15px;
    }

    .tab-element-container .tab-head-container ul li > a.active,
    .tab-card-option-container .tab-head-container ul li > a.active {
        opacity: 1;
        background: #eb6a3e;
    }

    .tab-element-container .tab-head-container ul li > a.active > span,
    .tab-card-option-container .tab-head-container ul li > a.active > span {
        color: #ffffff;
    }

    .tab-element-container .tab-head-container ul li:last-child > a,
    .tab-card-option-container .tab-head-container ul li:last-child > a {
        border: 0 none;
    }

    .tab-element-container .tab-detail-container,
    .tab-card-option-container .tab-detail-container {
        padding: 30px 0;
    }

    .tab-card-option-container .tab-detail-container .card-stream-container {
        padding-bottom: 35%;
    }

    .tab-card-option-container .tab-detail-container .card-stream-container ul .card-element {
        width: 50%;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container {
        margin-top: 20px;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul {
        flex-direction: column;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a {
        padding: 15px 10px;
        width: 100%;
        position: relative;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a::after {
        content: '';
        display: block;
        position: absolute;
        right: 30px;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 2;
        background: #eb6a3e;
        width: 10px;
        height: 10px;
        border-radius: 100px;
        opacity: 0;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a.active {
        background: rgba(26, 32, 37, 0.05);
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a.active::after {
        opacity: 1;
    }

    .tab-card-option-container .tab-detail-container .card-stream-text-container {
        margin-top: 25px;
        padding: 0 15px;
    }

    .icon-text-container.layout-1 {
        margin: 0;
    }

    .icon-text-container.layout-1 > a {
        margin-bottom: 20px;
        display: block;
    }

    .icon-text-container.layout-2 {
        flex-direction: column;
    }

    .icon-text-container.layout-2 .icon-container {
        width: 20%;
    }

    .icon-text-container.layout-2 .text-content {
        width: 100%;
    }

    .text-container.layout-1 .link-block {
        flex-direction: column;
    }

    .text-container.layout-1 .link-block .link {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .text-image.layout-1.text-image-block,
    .text-image.layout-2.text-image-block {
        margin: 0 0 30px 0;
    }

    .text-image.layout-1 .text-content,
    .text-image.layout-2 .text-content {
        margin: 0 0 30px 0;
    }

    .image-element-container .image {
        margin-bottom: 15px;
    }

    .image-content-element {
        order: 1;
    }

    .text-content-element {
        order: 2;
        margin-bottom: 25px;
    }

    .section-anchor-navigation {
        margin-bottom: 50px;
    }

    .section-content-element {
        padding-bottom: 35px;
    }

    .section-content-element:last-child {
        padding: 0;
    }

    .section-content-element .headline-container {
        padding-bottom: 25px;
    }

    .faq-detail .flex-col .flex-col {
        margin-left: -30px;
        margin-right: -30px;
    }

    .tab-element-container .tab-head-container ul li > a.active {
        background: #ea9100;
    }

    .login-container .login-mask {
        padding: 30px;
        min-height: 100vh;
        width: 100%;
        height: 100%;
    }

    .content-scroll-container .text-container-wrapper .text-container-element h2,
    .content-scroll-container .text-container-wrapper .text-container-element h3,
    .content-scroll-container .text-container-wrapper .text-container-element h4,
    .content-scroll-container .text-container-wrapper .text-container-element h5,
    .content-scroll-container .text-container-wrapper .text-container-element h6 {
        margin-bottom: 15px;
    }

    .content-scroll-container .text-container-wrapper .text-container-element::before {
        background: #ea9100;
        height: calc(100% - 100px);
    }

    .content-scroll-container .image-container-wrapper {
        display: none;
    }

    .content-scroll-container .image-container-wrapper .image {
        display: none;
    }
}

@media only screen and (max-width: 1023px) {
    body {
        padding-top: 66px;
    }

    .section-container {
        padding: 35px 0;
    }

    .section-container.background-1,
    .section-container.background-2,
    .section-container.background-3,
    .section-container.background-4,
    .section-container.background-5 {
        margin: 20px 0;
    }

    .section-container.background-1.only-margin-top,
    .section-container.background-2.only-margin-top,
    .section-container.background-3.only-margin-top,
    .section-container.background-4.only-margin-top,
    .section-container.background-5.only-margin-top {
        padding: 20px 0;
        margin-bottom: 0;
    }

    .section-container.background-1.only-margin-bottom,
    .section-container.background-2.only-margin-bottom,
    .section-container.background-3.only-margin-bottom,
    .section-container.background-4.only-margin-bottom,
    .section-container.background-5.only-margin-bottom {
        padding: 20px 0;
        margin-top: 0;
    }

    .section-container.background-1.no-margin,
    .section-container.background-2.no-margin,
    .section-container.background-3.no-margin,
    .section-container.background-4.no-margin,
    .section-container.background-5.no-margin {
        padding: 20px 0;
        margin: 0;
    }

    .section-container.background-0.only-margin-top {
        margin-bottom: 0;
    }

    .section-container.background-0.only-margin-bottom {
        margin-top: 0;
    }

    .section-container.background-0.no-margin {
        margin: 0;
    }

    .section-container.no-space-bottom {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .section-container.no-space-top {
        margin-top: 0;
        padding-top: 0;
    }

    .section-element {
        padding: 20px 0;
    }

    .section-element.no-space-bottom {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .section-element.no-space-top {
        margin-top: 0;
        padding-top: 0;
    }

    a.button.skip-menu-tab {
        display: none;
    }

    .navigation-container {
        padding: 14px 0;
    }

    .navigation-container .menu-orientation-block {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
    }

    .navigation-container .menu-orientation-block .logo-container {
        max-width: 120px;
    }

    .navigation-container .menu-orientation-block .logo-container .logo {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .navigation-container .menu-orientation-block .logo-container .logo svg {
        width: 100%;
    }

    .navigation-container .menu-orientation-block .logo-container .logo:focus-within,
    .navigation-container .menu-orientation-block .logo-container .logo:focus {
        outline: 0;
    }

    .navigation-container .menu-orientation-block .menu-container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
    }

    .navigation-container .menu-orientation-block .menu-container .additional-navigation {
        justify-content: flex-end;
        width: 100%;
    }

    .navigation-container .additional-navigation .language-switcher {
        margin-right: 30px;
    }

    .navigation-container .additional-navigation .language-switcher > a:focus-within,
    .navigation-container .additional-navigation .language-switcher > a:focus {
        outline: 0;
    }

    .navigation-container .additional-navigation .language-switcher > a:active {
        outline: none;
    }

    .navigation-elements {
        opacity: 0;
        will-change: transform;
        pointer-events: none;
        z-index: -1;
        position: absolute;
        height: calc(100vh - 55px);
        width: 100%;
        left: 0;
        top: 55px;
        background: #ffffff;
        overflow: scroll;
        padding-top: 15px;
    }

    .navigation-elements.active {
        -moz-animation-name: navigation-animation;
        -webkit-animation-name: navigation-animation;
        animation-name: navigation-animation;
        -moz-animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        -moz-animation-iteration-count: 1;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -moz-animation-direction: normal;
        -webkit-animation-direction: normal;
        animation-direction: normal;
        -moz-animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -moz-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
        -webkit-animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
        pointer-events: auto;
        z-index: 100;
        opacity: 1;
    }

    @-webkit-keyframes navigation-animation {
        from {
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translate(30px, 0);
            -moz-transform: translate(30px, 0);
            -o-transform: translate(30px, 0);
            -ms-transform: translate(30px, 0);
            transform: translate(30px, 0);
            width: 120%;
        }
        60% {
            opacity: 1;
            visibility: visible;
        }
        to {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 1;
            visibility: visible;
            width: 100%;
        }
    }
    @-moz-keyframes navigation-animation {
        from {
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translate(30px, 0);
            -moz-transform: translate(30px, 0);
            -o-transform: translate(30px, 0);
            -ms-transform: translate(30px, 0);
            transform: translate(30px, 0);
            width: 120%;
        }
        60% {
            opacity: 1;
            visibility: visible;
        }
        to {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 1;
            visibility: visible;
            width: 100%;
        }
    }
    @-o-keyframes navigation-animation {
        from {
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translate(30px, 0);
            -moz-transform: translate(30px, 0);
            -o-transform: translate(30px, 0);
            -ms-transform: translate(30px, 0);
            transform: translate(30px, 0);
            width: 120%;
        }
        60% {
            opacity: 1;
            visibility: visible;
        }
        to {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 1;
            visibility: visible;
            width: 100%;
        }
    }
    @keyframes navigation-animation {
        from {
            opacity: 0;
            visibility: hidden;
            -webkit-transform: translate(30px, 0);
            -moz-transform: translate(30px, 0);
            -o-transform: translate(30px, 0);
            -ms-transform: translate(30px, 0);
            transform: translate(30px, 0);
            width: 120%;
        }
        60% {
            opacity: 1;
            visibility: visible;
        }
        to {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 1;
            visibility: visible;
            width: 100%;
        }
    }
    .navigation-elements nav.main-navigation .navigation-toggle {
        position: absolute;
        right: 15px;
        top: 14px;
        width: 40px;
        height: 40px;
        border-radius: 80px;
        border: 2px solid #ea9100;
        background: transparent;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .navigation-elements nav.main-navigation .navigation-toggle::after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ea9100' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .navigation-elements nav.main-navigation .navigation-toggle.active {
        background: #ea9100;
    }

    .navigation-elements nav.main-navigation .navigation-toggle.active::after {
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17'%3e%3cpath fill='%23ffffff' fill-rule='evenodd' d='M7.012 2.19L8.648.555l7.794 7.794-7.794 7.794-1.636-1.635L12 9.521H.853V7.177H12z'/%3e%3c/svg%3e");
    }

    .navigation-elements nav.main-navigation a:focus-within,
    .navigation-elements nav.main-navigation a:focus {
        outline: 0;
    }

    .navigation-elements nav.main-navigation a:active {
        outline: none;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0 {
        background: #ffffff;
        border-bottom: 1px solid rgba(234, 145, 0, 0.3);
        padding: 0;
        width: 100%;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0:last-child {
        border: 0 none;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0 > a:not(.navigation-toggle),
    .navigation-elements nav.main-navigation ul.level-0-container li.level-0 .unlinked-child {
        display: block;
        font-weight: 600;
        padding: 20px 15px;
        font-size: 1.8rem;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0 > span.button:not(.navigation-toggle) {
        display: block;
        font-weight: 600;
        padding: 20px 15px;
        font-size: 1.8rem;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.button-item > a > span {
        width: 85%;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.button-item > a:not(.navigation-toggle) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        border-radius: 0;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.button-item > span.button:not(.navigation-toggle) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        border-radius: 0;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.button-item > a:not(.login-button) {
        color: #1a2025;
        background-color: rgba(234, 145, 0, 0.2);
        border: 0 none;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.button-item > a:not(.color-3),
    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.button-item span:not(.color-3) {
        justify-content: space-between;
        border: 0 none;
        background-color: rgba(234, 145, 0, 0.12);
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.has-children {
        position: relative;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.has-children > a:not(.navigation-toggle),
    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.has-children .unlinked-child {
        padding: 20px 0 20px 15px;
        margin-right: 55px;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.has-children .navigation-children-container {
        display: none;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.has-children .navigation-children-container.active {
        display: block;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.inactive {
        display: none;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.only-mobile-navigation {
        display: block;
        background: transparent;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.only-mobile-navigation > a,
    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.only-mobile-navigation .unlinked-child {
        color: #ea9100;
    }

    .navigation-elements nav.main-navigation ul.level-0-container li.level-0.only-mobile-navigation.inactive {
        display: none;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1 {
        background: #ea9100;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        padding: 0;
        width: 100%;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1:first-child {
        border-top: 1px solid rgba(234, 145, 0, 0.3);
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1:last-child {
        border: 0 none;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1 > a:not(.navigation-toggle),
    .navigation-elements nav.main-navigation ul.level-1-container li.level-1 .unlinked-child {
        display: block;
        font-weight: 600;
        padding: 15px;
        font-size: 1.8rem;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1 > p.submenu-text {
        padding: 0 15px 15px;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #ffffff;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1 > a:not(.button) {
        color: #ffffff;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.active,
    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.active-rootline {
        background: rgba(234, 145, 0, 0.2);
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.active > a:not(.button),
    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.active-rootline > a:not(.button),
    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.active > p.submenu-text,
    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.active-rootline > p.submenu-text {
        color: #1a2025;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.has-children {
        position: relative;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.has-children > a:not(.navigation-toggle),
    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.has-children .unlinked-child {
        margin-right: 55px;
        padding: 20px 0 20px 15px;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.has-children .navigation-children-container {
        display: none;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.has-children .navigation-children-container.active {
        display: block;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1.inactive {
        display: none;
    }

    .navigation-elements nav.main-navigation ul.level-1-container li.level-1 > span.menu-icon-svg {
        display: none;
    }

    .navigation-elements nav.main-navigation ul.level-2-container li.level-2 {
        background: #ffffff;
        border-bottom: 1px solid rgba(234, 145, 0, 0.3);
        padding: 0;
        width: 100%;
    }

    .navigation-elements nav.main-navigation ul.level-2-container li.level-2:first-child {
        border-top: 1px solid rgba(234, 145, 0, 0.3);
    }

    .navigation-elements nav.main-navigation ul.level-2-container li.level-2:last-child {
        border: 0 none;
    }

    .navigation-elements nav.main-navigation ul.level-2-container li.level-2 > a {
        display: block;
        font-weight: 600;
        padding: 20px 15px;
        font-size: 1.8rem;
    }

    .navigation-elements .nav-login-container {
        width: 100%;
        padding: 20px;
        -webkit-transform: translate(0, 30px);
        -moz-transform: translate(0, 30px);
        -o-transform: translate(0, 30px);
        -ms-transform: translate(0, 30px);
        transform: translate(0, 30px);
    }

    .burger-icon {
        width: 28px;
        height: 26px;
        cursor: pointer;
    }

    .burger-icon .hamburger-menu,
    .burger-icon .hamburger-menu::after,
    .burger-icon .hamburger-menu::before {
        width: 28px;
        height: 4px;
        border-radius: 20px;
    }

    .burger-icon .hamburger-menu {
        position: relative;
        background: #1a2025;
        -webkit-transform: translate(0, 12px);
        -moz-transform: translate(0, 12px);
        -o-transform: translate(0, 12px);
        -ms-transform: translate(0, 12px);
        transform: translate(0, 12px);
        -webkit-transition: all 0.15s ease;
        -moz-transition: all 0.15s ease;
        -o-transition: all 0.15s ease;
        transition: all 0.15s ease;
    }

    .burger-icon .hamburger-menu::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 8px;
        background: #1a2025;
        transition: bottom 0.15s 0.15s cubic-bezier(0.23, 1, 0.32, 1), transform 0.15s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .burger-icon .hamburger-menu::after {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        background: #1a2025;
        transition: top 0.15s 0.15s cubic-bezier(0.23, 1, 0.32, 1), transform 0.15s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .burger-icon.animate .hamburger-menu {
        background: rgba(26, 32, 37, 0);
    }

    .burger-icon.animate .hamburger-menu::before {
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        bottom: 0;
        transition: bottom 0.15s cubic-bezier(0.23, 1, 0.32, 1), transform 0.15s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .burger-icon.animate .hamburger-menu::after {
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0;
        transition: top 0.15s cubic-bezier(0.23, 1, 0.32, 1), transform 0.15s 0.15s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .burger-icon:focus-within,
    .burger-icon:focus {
        outline: 0;
    }
}

@media all and (min-width: 640px) {
    .form-row .form-wrapper {
        flex-direction: row;
        justify-content: center;
    }

    .form-row .input-low-field {
        width: 160px;
    }

    .form-row .input-middle-field {
        width: 350px;
    }
}

@media all and (min-width: 768px) {

    body[data-language="4"] , body[data-language="5"], body[data-language="14"], body[data-language="20"], body[data-language="21"] {
        .stage-element-container.layout-2 .stage-content-wrapper .stage-content-block {
            padding-top: 0px;
            margin-top: -30px;
        }
    }



    .flex-col.row-inner {
        margin-left: -10px;
        margin-right: -10px;
    }

    .flex-col.content {
        padding-right: 10px;
        padding-left: 10px;
    }

    .flex-col .col {
        padding-right: 10px;
        padding-left: 10px;
    }

    .headline-container {
        padding-bottom: 60px;
    }

    #cookie-hint-container.cookie-dialog {
        padding: 20px 0;
    }

    #cookie-hint-container.cookie-dialog .cookie-text {
        color: #ffffff;
    }

    #cookie-hint-container.cookie-dialog .cookie-text .link.type-1 {
        font-weight: 700;
        color: #ea9100;
    }

    .counter-text-wrapper {
        padding: 0 20px 0 0;
    }

    .counter-container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .counter-container.layout-1 {
        padding: 0;
        -webkit-transform: translate(0, 65px);
        -moz-transform: translate(0, 65px);
        -o-transform: translate(0, 65px);
        -ms-transform: translate(0, 65px);
        transform: translate(0, 65px);
    }

    .counter-container.layout-1::before {
        -webkit-transform: translate(5%, 0%) rotate(135deg);
        -moz-transform: translate(5%, 0%) rotate(135deg);
        -o-transform: translate(5%, 0%) rotate(135deg);
        -ms-transform: translate(5%, 0%) rotate(135deg);
        transform: translate(5%, 0%) rotate(135deg);
        width: 100%;
        height: 230px;
    }

    .counter-container.layout-1 .counter-container-items {
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .counter-container.layout-1 .counter-content {
        padding: 10px 25px;
        margin: 0 0 30px;
        width: 75%;
    }

    .counter-container.layout-1 .counter-content .count {
        font-size: 4rem;
        line-height: 4.6rem;
    }

    .counter-container.layout-1 .counter-content .count-description {
        font-size: 2.5rem;
        line-height: 3.1rem;
    }

    .counter-container.layout-1 .counter-content.counter-position-2 {
        align-self: flex-end;
    }

    .counter-container.layout-2 .counter-container-items {
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .counter-container.layout-2 .counter-content {
        padding: 16px 12px;
        width: 33.33%;
    }

    .counter-container.layout-2 .counter-content:nth-child(1) {
        margin: 0 12.5px 0 0;
    }

    .counter-container.layout-2 .counter-content:nth-child(2) {
        margin: 0 12.5px;
    }

    .counter-container.layout-2 .counter-content:nth-child(3) {
        margin: 0 0 0 12.5px;
    }

    .counter-container.layout-2 .counter-content .count {
        font-size: 4rem;
        line-height: 4.6rem;
    }

    .counter-container.layout-2 .counter-content .count-description {
        font-size: 2rem;
        line-height: 2.6rem;
    }

    .counter-container.layout-2 .counter-content.counter-position-2 {
        justify-content: center;
    }

    .slider-element-container .slider-quote-wrapper .slider-quote-image {
        width: 25%;
        justify-content: center;
        flex-direction: row;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .slider-element-container .slider-quote-wrapper .slider-quote-content {
        width: 75%;
    }

    .slider-element-container .slider-quote-wrapper .slider-quote-content blockquote,
    .slider-element-container .slider-quote-wrapper .slider-quote-content p,
    .slider-element-container .slider-quote-wrapper .slider-quote-content ul {
        font-size: 3rem;
        line-height: 3.6rem;
    }

    .slider-element-container .slider-quote-wrapper .quote-author {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    .slider-element-container .slider-text-wrapper {
        max-width: 85%;
    }

    .slider-element-container .slider-element-wrapper ul li {
        cursor: pointer;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        opacity: 0.8;
    }

    .slider-element-container .slider-element-wrapper ul li.slider-prev:hover {
        opacity: 1;
        -webkit-transform: translate(-3px, -50%) rotate(90deg);
        -moz-transform: translate(-3px, -50%) rotate(90deg);
        -o-transform: translate(-3px, -50%) rotate(90deg);
        -ms-transform: translate(-3px, -50%) rotate(90deg);
        transform: translate(-3px, -50%) rotate(90deg);
    }

    .slider-element-container .slider-element-wrapper ul li.slider-next:hover {
        opacity: 1;
        -webkit-transform: translate(3px, -50%) rotate(-90deg);
        -moz-transform: translate(3px, -50%) rotate(-90deg);
        -o-transform: translate(3px, -50%) rotate(-90deg);
        -ms-transform: translate(3px, -50%) rotate(-90deg);
        transform: translate(3px, -50%) rotate(-90deg);
    }

    .slider-element-container .slider-element-wrapper .slider-element:hover:not(.active) {
        opacity: 0.75;
        -webkit-transform: translate(0, -4px);
        -moz-transform: translate(0, -4px);
        -o-transform: translate(0, -4px);
        -ms-transform: translate(0, -4px);
        transform: translate(0, -4px);
    }

    .slider-element-container .slider-element-wrapper .slider-element:hover:not(.active) img {
        filter: none;
    }

    .slider-element-container .slider-inline-text-content {
        padding-left: 25%;
    }

    .stage-element-container::after {
        height: 215px;
    }

    .stage-element-container.layout-1 {
        min-height: 650px;
        height: calc(100vh - 66px);
    }

    .stage-element-container.layout-1 .stage-content-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .stage-element-container.layout-1 .stage-content-wrapper .main-content {
        height: 100%;
    }

    .stage-element-container.layout-1 .stage-content-wrapper .stage-content-block {
        height: 100%;
        width: 50%;
        padding-bottom: 125px;
    }

    .stage-element-container.layout-1 .stage-image-block {
        width: 100%;
        height: 100%;
    }

    .stage-element-container.layout-1 .stage-image-block .image,
    .stage-element-container.layout-1 .stage-image-block .image figure,
    .stage-element-container.layout-1 .stage-image-block .image figure img {
        width: 100%;
        height: 100%;
    }

    .stage-element-container.layout-1 .stage-image-block figure img {
        object-fit: cover;
    }

    .stage-element-container.layout-2 {
        height: 600px;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .flex-col {
        height: 100%;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .stage-content-block {
        height: 100%;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .stage-image-block {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .stage-image-block figure {
        flex: 0 0 auto;
    }

    .stage-element-container.layout-2 .stage-content-wrapper .stage-image-block figure img {
        position: relative;
        z-index: 6;
    }

    .stage-element-container.layout-3 .stage-content-wrapper {
        height: 1px;
        min-height: 350px;
    }

    .stage-element-container.layout-3 .stage-content-wrapper .flex-col {
        height: 100%;
    }

    .stage-element-container.layout-3 .stage-content-wrapper .stage-content-block {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .teaser.layout-1 {
        padding: 20px 25px;
    }

    .teaser.layout-3 {
        flex-direction: row;
    }

    .teaser.layout-3.type-1 {
        background-color: #ea9100;
    }

    .teaser.layout-3.type-1 .teaser-image-block {
        width: 60%;
    }

    .teaser.layout-3.type-1 .teaser-image-block .image {
        height: 100%;
    }

    .teaser.layout-3.type-1 .teaser-content {
        width: 40%;
    }

    .teaser.layout-3.type-1 .teaser-content .button-row {
        margin-top: auto;
    }

    .teaser.layout-3.type-2 {
        background-color: #eb6a3e;
    }

    .teaser.layout-3.type-2 .teaser-image-block {
        order: 2;
        width: 60%;
    }

    .teaser.layout-3.type-2 .teaser-image-block .image {
        height: 100%;
    }

    .teaser.layout-3.type-2 .teaser-content {
        order: 1;
        width: 40%;
    }

    .teaser.layout-3.type-2 .teaser-content .button-row {
        margin-top: auto;
    }

    .teaser.layout-4 .teaser-content {
        padding: 0 60px 0 0;
    }

    .teaser.layout-1,
    .teaser.layout-2,
    .teaser.layout-3,
    .teaser.layout-4 {
        height: calc(100% - 25px);
    }

    .tab-element-container .tab-head-container ul,
    .tab-card-option-container .tab-head-container ul {
        margin: 0 auto;
        width: calc(100% - 10px * 2);
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
    }

    .tab-element-container .tab-head-container ul li > a,
    .tab-card-option-container .tab-head-container ul li > a {
        padding: 15px 45px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .tab-element-container .tab-head-container ul li > a img,
    .tab-card-option-container .tab-head-container ul li > a img,
    .tab-element-container .tab-head-container ul li > a svg,
    .tab-card-option-container .tab-head-container ul li > a svg {
        margin-bottom: 15px;
    }

    .tab-element-container .tab-head-container ul li > a:not(.sub-tab)::after,
    .tab-card-option-container .tab-head-container ul li > a:not(.sub-tab)::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        background-color: #eb6a3e;
        opacity: 0;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .sub-tab-container .tab-head-container .main-content ul {
        position: relative;
        .sub-topic {
            position: absolute;
            left: 0;
        }
    }

    .tab-element-container .tab-head-container ul li > a.active,
    .tab-card-option-container .tab-head-container ul li > a.active {
        opacity: 1;
    }

    .tab-element-container .tab-head-container ul li > a.active::after,
    .tab-card-option-container .tab-head-container ul li > a.active::after {
        width: 100%;
        opacity: 1;
    }

    .tab-element-container .tab-detail-container,
    .tab-card-option-container .tab-detail-container {
        padding: 100px 0;
    }

    .tab-card-option-container .tab-detail-container .card-stream-container {
        height: 350px;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container {
        margin-top: 65px;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0 100px;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li:nth-child(1) {
        margin: 0 15px 0 0;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li:nth-child(2) {
        margin: 0 15px 0;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li:nth-child(3) {
        margin: 0 0 0 15px;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a {
        padding: 15px 0;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        background-color: #eb6a3e;
        opacity: 0;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .tab-card-option-container .tab-detail-container .card-stream-controls-container ul li > a.active::after {
        width: 100%;
        opacity: 1;
    }

    .tab-card-option-container .tab-detail-container .card-stream-text-container {
        margin-top: 65px;
    }

    .icon-text-container.layout-1 {
        margin: 0 0 40px 0;
        padding: 0 15px 0 0;
    }

    .icon-text-container.layout-2 {
        padding: 0 15px 0 0;
        flex-direction: row;
    }

    .icon-text-container.layout-2 .icon-container {
        width: 20%;
    }

    .icon-text-container.layout-2 .text-content {
        width: 80%;
    }

    .text-container.layout-1 .link-block {
        flex-direction: row;
    }

    .text-container.layout-1 .link-block .link {
        flex-direction: row;
    }

    .text-container.layout-1 ul {
        column-count: 2;
        column-gap: 28px;
    }

    .flex-col .col.m-6:first-child.text-content-element .text-image-wrapper {
        padding-right: 50px;
    }

    .flex-col .col.m-6:last-child.text-content-element .text-image-wrapper {
        padding-left: 50px;
    }

    .text-image.layout-1.text-image-wrapper .text-content .headline-container {
        padding-bottom: 40px;
    }

    .section-anchor-navigation.border-right {
        height: 100%;
        border-right: 2px solid rgba(26, 32, 37, 0.1);
    }

    .section-content-element {
        padding-bottom: 65px;
    }

    .section-content-element:last-child {
        padding: 0;
    }

    .section-content-element .headline-container {
        padding-bottom: 40px;
    }

    .faq-detail .flex-col .flex-col {
        margin-left: -10px;
        margin-right: -10px;
    }

    .tab-element-container .tab-head-container ul li > a:not(.sub-tab)::after {
        background-color: #ea9100;
    }



    .tab-element-container .tab-detail-container {
        padding: 100px 0;
    }

    .tab-element-container .tab-detail-container.sub-tab-container {
        padding: 0 0 100px 0;
        > .tab-head-container {
           padding-bottom: 100px;
        }
    }

    .sub-tab-container a.sub-tab {
        display: block !important;
    }

    @import "responsive-login";

    @-webkit-keyframes login-container-animation {
        from {
            opacity: 0;
            -webkit-transform: translate(0, -180px);
            -moz-transform: translate(0, -180px);
            -o-transform: translate(0, -180px);
            -ms-transform: translate(0, -180px);
            transform: translate(0, -180px);
        }
        60% {
            opacity: 1;
            visibility: visible;
        }
        to {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 1;
        }
    }
    @-moz-keyframes login-container-animation {
        from {
            opacity: 0;
            -webkit-transform: translate(0, -180px);
            -moz-transform: translate(0, -180px);
            -o-transform: translate(0, -180px);
            -ms-transform: translate(0, -180px);
            transform: translate(0, -180px);
        }
        60% {
            opacity: 1;
            visibility: visible;
        }
        to {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 1;
        }
    }
    @-o-keyframes login-container-animation {
        from {
            opacity: 0;
            -webkit-transform: translate(0, -180px);
            -moz-transform: translate(0, -180px);
            -o-transform: translate(0, -180px);
            -ms-transform: translate(0, -180px);
            transform: translate(0, -180px);
        }
        60% {
            opacity: 1;
            visibility: visible;
        }
        to {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 1;
        }
    }
    @keyframes login-container-animation {
        from {
            opacity: 0;
            -webkit-transform: translate(0, -180px);
            -moz-transform: translate(0, -180px);
            -o-transform: translate(0, -180px);
            -ms-transform: translate(0, -180px);
            transform: translate(0, -180px);
        }
        60% {
            opacity: 1;
            visibility: visible;
        }
        to {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 1;
        }
    }
    section.color-2 .content-scroll-container .text-container-wrapper .text-container-element.active::before {
        background: #aebd23;
    }

    section.color-2 .content-scroll-container .text-container-wrapper .text-container-element::before {
        background: rgba(174, 189, 35, 0.1);
    }

    section.color-1 .content-scroll-container .text-container-wrapper .text-container-element.active::before {
        background: #eb6a3e;
    }

    section.color-1 .content-scroll-container .text-container-wrapper .text-container-element::before {
        background: rgba(235, 106, 62, 0.1);
    }

    .content-scroll-container {
        margin-bottom: 100px;
    }

    .content-scroll-container .text-container-wrapper {
        width: 90%;
        padding: 15px 0 0 15px;
    }

    .content-scroll-container .text-container-wrapper .text-container-element {
        opacity: 0;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        -webkit-transform: translate(0, -40px);
        -moz-transform: translate(0, -40px);
        -o-transform: translate(0, -40px);
        -ms-transform: translate(0, -40px);
        transform: translate(0, -40px);
    }

    .content-scroll-container .text-container-wrapper .text-container-element h2,
    .content-scroll-container .text-container-wrapper .text-container-element h3,
    .content-scroll-container .text-container-wrapper .text-container-element h4,
    .content-scroll-container .text-container-wrapper .text-container-element h5,
    .content-scroll-container .text-container-wrapper .text-container-element h6 {
        margin-bottom: 25px;
    }

    .content-scroll-container .text-container-wrapper .text-container-element.active {
        opacity: 1;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .content-scroll-container .text-container-wrapper .text-container-element.active::before {
        height: calc(100% - 100px);
        background: #ea9100;
    }

    .content-scroll-container .text-container-wrapper .text-container-element.active.active-sibling {
        opacity: 1;
    }

    .content-scroll-container .text-container-wrapper .text-container-element.active.active-sibling p {
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .content-scroll-container .text-container-wrapper .text-container-element::before {
        background: rgba(234, 145, 0, 0.1);
        height: calc(100% - 160px);
    }

    .content-scroll-container .text-container-wrapper .text-container-element.active-sibling {
        opacity: 0.3;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .content-scroll-container .text-container-wrapper .text-container-element.active-sibling.next-sibling::after,
    .content-scroll-container .text-container-wrapper .text-container-element.active-sibling.previous-sibling::after {
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        content: '';
        display: block;
        position: absolute;
        background: -moz-linear-gradient(top, transparent 0%, #ffffff 100%);
        background: -webkit-linear-gradient(top, transparent 0%, #ffffff 100%);
        background: linear-gradient(to bottom, transparent 0%, #ffffff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=transparent, endColorstr=#ffffff, GradientType=0);
        z-index: 10;
    }

    .content-scroll-container .text-container-wrapper .text-container-element.active-sibling.next-sibling::after {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 95%;
    }

    .content-scroll-container .text-container-wrapper .text-container-element.active-sibling.previous-sibling::after {
        top: -15px;
        left: -15px;
        height: 100px;
        width: calc(100% + 15px);
        -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }

    .content-scroll-container .image-container-wrapper {
        height: 100%;
        width: 85%;
        margin: 0 auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
    }

    .content-scroll-container .image-container-wrapper .image {
        position: absolute;
        width: 100%;
        opacity: 0;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
    }

    .content-scroll-container .image-container-wrapper .image.active {
        opacity: 1;
    }

    .content-scroll-container .image-container-wrapper .image .link-row {
        margin-top: 15px;
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .content-scroll-container .image-container-wrapper .image .link-row a:nth-child(2) {
        margin-left: 15px;
    }

    .content-scroll-container .col.m-6:nth-child(2) {
        margin-bottom: -100px;
    }

    body section.section-container.background-1::before,
    body section.section-container.background-2::before,
    body section.section-container.background-3::before,
    body section.section-container.background-4::before,
    body section.section-container.background-5::before {
        bottom: -848px;
        left: -2150px;
        transform: rotate(-33deg);
    }

    body section.section-container.background-1::after,
    body section.section-container.background-2::after,
    body section.section-container.background-3::after,
    body section.section-container.background-4::after,
    body section.section-container.background-5::after {
        bottom: -600px;
        right: -1800px;
        transform: rotate(286deg);
    }
}
