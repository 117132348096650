.additional-navigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
    @media (min-width: 1024px) {
        span.button.type-1.login-button {
            width: 128px;
            justify-content: center;
        }
    }
    .language-switcher {
        position: relative;
        --ls-width: 62px;
        --ls-height: 30px;
        --ls-gap: 18px;
        width: var(--ls-width);
        height: var(--ls-height);
        &--inactive {
            .language-switcher__elements {
                height: var(--ls-height);
                overflow: hidden;
            }
            &:hover {
                .language-switcher__elements {
                    a:first-child {
                        span {
                            color: #ea9100;
                        }
                    }
                }
            }
        }
        &--active {
            .language-switcher__activate {
                width: 20px;
                left: 42px;
            }
            .language-switcher__elements {
                z-index: 90;
                max-height: 220px;
                overflow-x: hidden;
                overflow-y: auto;
                width: max-content;
                box-shadow: 0 5px 20px -4px rgba(26, 32, 37, 0.4);
                background: #ffffff;
                border-radius: 6px;
                padding-left: 15px;
                padding-right: 30px;
                padding-bottom: 10px;
                padding-top: 10px;
                left: -38px;
                top: -8px;
                &:after {
                    opacity: 1;
                }

                &-a {
                    &:hover {
                        span {
                            color: #eb6a3e;
                        }
                    }
                    &--inactive:hover {
                        background: rgba(234, 145, 0, 0.2);
                    }
                }
            }
        }
        &__icon {
            transition: all 0.25s ease;
            position: absolute;
            z-index: 110;
            pointer-events: none;
            width: 12px;
            height: 7px;
            right: 0;
            top: 9px;
            background-size: 12px 7px;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7'%3E %3Cpath fill='%23ea9100' fill-rule='nonzero' d='M1.48.52l-.96.96L6 6.96l5.48-5.48-.96-.96L6 5.04z'/%3E %3C/svg%3E ");
        }
        &__activate {
            z-index: 100;
            padding: 0;
            font-size: 0;
            border: 0;
            opacity: 0;
            background: cyan;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: var(--ls-height);
            &:hover {
                ~ .language-switcher__elements .language-switcher__elements-a--active span {
                    color: #eb6a3e;
                }
                ~ .language-switcher__icon {
                    transform: rotate(-90deg);
                }
            }
        }
        &__elements {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            gap: var(--ls-gap);
            background: white;
            /*&:after {
                content: '';
                position: absolute;
                left: -10px;
                top: -10px;
                right: -10px;
                bottom: -10px;
                box-shadow: 0 5px 20px -4px rgba(26, 32, 37, 0.4);
                background: #ffffff;
                border-radius: 6px;
                transition: all 0.25s ease;
                opacity: 0;
                z-index: -10;
            }*/
            &-a {
                height: var(--ls-height);
                width: var(--ls-width);
                color: #1a2025;
                text-decoration: none;
                cursor: pointer;
                display: block;
                border-radius: 5px;
                transition: all 0.25s ease;
                > span {
                    height: var(--ls-height);
                    width: var(--ls-width);
                    cursor: pointer;
                    pointer-events: initial;
                    color: rgba(26, 32, 37, 0.45);
                    display: flex;
                    align-items: center;
                }
                &-a--active > span {
                    color: #1a2025;
                }
                order: 2;
                &-a--active {
                    order: 1;
                }
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: calc( -0.5 * var(--ls-gap));
                    background:rgba(26, 32, 37, 0.25);
                    height: 1px;
                    transform: translateY(-50%);
                    pointer-events: none;
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
        span {
            font-size: 1.4rem;
            line-height: 1.2rem;
            vertical-align: middle;
            font-weight: 600;
            color: #1a2025;
            padding: 6px 10px;
            &.disabled, &:disabled {
                cursor: not-allowed;
                opacity: 0.7;
            }
        }
    }
}