// @todo convert into scss

.switcher-container {
  display: flex;
  flex-direction: column;
  margin: 25px 0;

}

#datamintsLanguageSwitcher {
  display: flex;
  justify-content: center
}


.datamintsLanguageSwitcherToggle_hidden {
  display: none !important;
}

.datamintsLanguageSwitcher-arrow {
  margin-left: auto;
  height: 21px;

}

.datamintsLanguageSwitcher-arrow > svg {
  margin-top: -8px;
}

.datamintsLanguageSwitcher-globe {
  margin-right: auto;
  height: 21px;
}

.datamintsLanguageSwitcher-globe > svg {
  margin-top: -8px;
}

.hidden {
  visibility: hidden;
  display: none;
}

#datamintsLanguageSwitcherList {
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  margin-top: 65px;
  background: white;
  box-shadow: 0 5px 20px -4px rgba(26, 32, 37, 0.4);
  z-index: 90;
  border-radius: 20px;
}

#datamintsLanguageSwitcherList > a {
  height: 50px;
  line-height: 50px;
  justify-content: center;
}

#datamintsLanguageSwitcherList > a:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(-0.5 * var(--ls-gap));
  background: rgba(26, 32, 37, 0.25);
  height: 1px;
  transform: translateY(-50%);
  pointer-events: none;
}